import { useEffect, useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { ShopsGrantedCredentials } from "../../@types/Auth";
import { useAuth } from "../../hooks/auth";
import { TenantType } from "../../@types/Stores";
import { wrapperRequests } from "../../services/api";
import { routesURL } from "../../services/routesUrl";
import StyledInput from "./components/Input/index"; 
import * as S from "./styles";

interface Filters {
  tenantName: string;
  shopName: string;
}

export function Dashboard() {
  const { user, shopSelected, selectStore } = useAuth();
  const [allTenants, setAllTenants] = useState<TenantType[]>([]);
  const [filters, setFilters] = useState<Filters>({
    tenantName: "",
    shopName: "",
  });
  const [isAscending, setIsAscending] = useState(true);

  const selectedShop = user.user.shopsGranted.filter(
    (shop) => shop.name === (shopSelected && shopSelected.name),
  );

  const filterStores = user.user.shopsGranted.filter((shop) => {
    const isSameSelectedShop =
      selectedShop[0] && shop.name === selectedShop[0].name;

    const nameFilter =
      !isSameSelectedShop &&
      shop.name
        .toLocaleLowerCase()
        .includes(filters.shopName.toLocaleLowerCase());

    const tenantNameFilter =
      shop.tenantName &&
      shop.tenantName
        .toLocaleLowerCase()
        .includes(filters.tenantName.toLocaleLowerCase());

    return nameFilter && tenantNameFilter;
  });

  const filteredStores = [...filterStores].sort((a, b) => 
    isAscending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
  );
  const shopsToShow = selectedShop.concat(filteredStores);

  const handleSelectStore = (shop: ShopsGrantedCredentials) => {
    selectStore(shop);
  };

  const fetchAllTenants = async () => {
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.stores.getAllTenants,
        "GET",
      );

      setAllTenants(data.content);
    } catch (error) {
      throw Error(String(error));
    }
  };

  const handleUpdateFilter = (filter: keyof Filters, value: string) => {
    setFilters((state) => ({
      ...state,
      [filter]: value,
    }));
  };

  const handleToggleOrder = () => {
    setIsAscending((prev) => !prev); 
  };

  useEffect(() => {
    fetchAllTenants();
  }, []);

  return (
    <S.StoreContainer>
      <S.StoreHeader>
        <S.StoreFilterContainer>
          <StyledInput
             onToggleOrder={handleToggleOrder}
             searchIcon
             placeholder="Search"
             onChange={(event) => handleUpdateFilter('shopName', event.target.value)}
          />
        </S.StoreFilterContainer>
      </S.StoreHeader>

      <S.StoresContainer>
        {shopsToShow.map((store) => {
          return (
            <S.StoresCardContainer key={store.id}>
              <S.StoreCardContent>
                <S.StoreCardInfoContent>
                  <S.StyledSpan>
                    {store.name.toLocaleLowerCase()}
                  </S.StyledSpan>
                </S.StoreCardInfoContent>

                <S.StoreCardInfoContent>
                  <HiLocationMarker size={16} />
                  <S.StyledSpan isSecond>
                    {store.name === "Warehouse"
                      ? "San Dimas"
                      : store.name.toLocaleLowerCase()}
                    , {store.state}
                  </S.StyledSpan>
                </S.StoreCardInfoContent>
                <button
                  disabled={store.name === shopSelected.name}
                  onClick={() => handleSelectStore(store)}
                >
                  {store.name === shopSelected.name ? "Selected" : "Access"}
                </button>
              </S.StoreCardContent>
            </S.StoresCardContainer>
          );
        })}
      </S.StoresContainer>
    </S.StoreContainer>
  );
}
