import React, { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";

import * as S from "./styles";

interface ActionsButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

export const ActionsButton = forwardRef<HTMLButtonElement, ActionsButtonProps>(
  function ActionsButton({ children, ...rest }, ref) {
    return (
      <S.ActionsButtonContainer ref={ref} {...rest}>
        {children}
      </S.ActionsButtonContainer>
    );
  },
);
