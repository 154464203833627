import { ReactNode, useCallback, useState } from "react";
import { createContext } from "use-context-selector";
import { api, wrapperRequests } from "../services/api";
import { routesURL } from "../services/routesUrl";
import { IOrders, OrderDetails, ProductList } from "../@types/Orders";
import { OrderItemProcessStatus, PickingItemStatus } from "../enums/Orders";
import { AxiosError } from "axios";
import { ToastPosition, toast } from "react-toastify";

interface OrdersContextContextType {
  cancelShipmentsItem: (itemId: string, saleId: string) => Promise<void>;
  cancelProductListLineItem: (
    orderID: string,
    orderLineID: string,
    localOrderDetails: OrderDetails,
  ) => Promise<void>;
  fetchOrders: (
    filterForSearchParam: FilterForSearchParam | undefined,
  ) => Promise<void>;
  fetchOrderDetails: (caOrderId: string) => Promise<void>;
  loading: {
    orderDetails: boolean;
    cancelItem: boolean;
    id: string;
  };
  ordersList: IOrders[];
  orderDetails: OrderDetails;
  ordersSyncErrorLoading: {
    loading: boolean;
    orderId: string;
  };
  ordersPagination: {
    rowsPerPage: number;
    page: number;
    totalDocuments: number;
    totalPages: number;
  };
  retryImport: (orderId: string, fromOrderDetails?: boolean) => Promise<void>;
  selectPage: (value: number) => void;
  selectRowsPerPage: (value: number) => void;
  toastify: (
    type: keyof Toastify,
    message: string,
    position: ToastPosition,
  ) => void;
}

interface FilterForSearchParam {
  filter: string;
  search: string;
  status: string;
}

interface Toastify {
  success: string;
  error: string;
}

interface OrdersProviderProps {
  children: ReactNode;
}

export const OrdersContext = createContext({} as OrdersContextContextType);

export function OrdersProvider({ children }: OrdersProviderProps) {
  const [ordersList, setOrdersList] = useState<IOrders[]>([]);
  const [ordersSyncErrorLoading, setOrdersSyncErrorLoading] = useState({
    loading: false,
    orderId: "",
  });
  const [ordersPagination, setOrdersPagination] = useState({
    rowsPerPage: 10,
    page: 1,
    totalDocuments: 0,
    totalPages: 1,
  });
  const [loading, setLoading] = useState({
    orderDetails: false,
    cancelItem: false,
    id: "",
  });
  const [orderDetails, setOrderDetails] = useState<OrderDetails>(
    {} as OrderDetails,
  );

  const fetchOrders = useCallback(
    async (filterForSearchParam: FilterForSearchParam | undefined) => {
      try {
        const response = await wrapperRequests(
          routesURL.report.orders.getOrders,
          "GET",
          {
            params: {
              page: String(ordersPagination.page || 1),
              limit: String(
                ordersPagination.rowsPerPage ? ordersPagination.rowsPerPage : 5,
              ),
              status: filterForSearchParam?.status
                ? filterForSearchParam?.status
                : undefined,
              channelAdvisorOrderID:
                filterForSearchParam?.filter === "channelAdvisorOrderID"
                  ? filterForSearchParam?.search
                  : undefined,
              siteOrderID:
                filterForSearchParam?.filter === "siteOrderID"
                  ? filterForSearchParam?.search
                  : undefined,
              billingName:
                filterForSearchParam?.filter === "billingName"
                  ? filterForSearchParam?.search
                  : undefined,
              buyerEmailAddress:
                filterForSearchParam?.filter === "buyerEmailAddress"
                  ? filterForSearchParam?.search
                  : undefined,
            },
          },
        );

        setOrdersList(response.data.content);
        setOrdersPagination((currentValue) => {
          return {
            ...currentValue,
            totalDocuments: response.data.totalDocuments,
            totalPages: response.data.totalPages,
          };
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          throw Error(error.response?.data.message);
        }
        throw Error(String(error));
      }
    },
    [ordersPagination.page, ordersPagination.rowsPerPage],
  );

  const toastify = (
    type: keyof Toastify,
    message: string,
    position: ToastPosition,
  ) => {
    toast[type](message, {
      position,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleLoading = (loadingType: keyof typeof loading, value: boolean) => {
    setLoading((state) => ({
      ...state,
      [loadingType]: value,
    }));
  };

  const fetchOrderDetails = async (caOrderId: string) => {
    handleLoading("orderDetails", true);

    try {
      const { data } = await wrapperRequests(
        routesURL.report.orders.getSales(caOrderId),
        "GET",
      );

      setOrderDetails(data.order);
    } catch (error) {
      throw Error(String(error));
    } finally {
      handleLoading("orderDetails", false);
    }
  };

  const retryImport = async (orderId: string, fromOrderDetails?: boolean) => {
    setOrdersSyncErrorLoading({
      loading: true,
      orderId,
    });
    try {
      await wrapperRequests(
        routesURL.report.orders.retryImport(orderId),
        "PUT",
      );

      setOrdersSyncErrorLoading({
        loading: false,
        orderId: "",
      });

      if (fromOrderDetails) {
        setOrderDetails((currentState) => {
          return {
            ...currentState,
            forceReprocess: true,
          };
        });
        toastify("success", "🚴🏽‍♂️ Order synced", "top-center");
      }
    } catch (error) {
      setOrdersSyncErrorLoading({
        loading: false,
        orderId: "",
      });
      if (fromOrderDetails) {
        toastify("error", "Error syncing item", "top-center");
      }
      throw new Error(String(error));
    }
  };

  const cancelShipmentsItem = async (itemId: string, saleId: string) => {
    setLoading((state) => {
      return {
        ...state,
        cancelItem: true,
        id: itemId,
      };
    });
    try {
      const { data } = await wrapperRequests(
        routesURL.report.orders.cancelItem(itemId),
        "PUT",
      );

      const changeItemStatus = orderDetails.sales?.map((sale) => {
        if (saleId === sale.id) {
          return {
            ...sale,
            items: sale.items?.map((item) => {
              if (item.id === itemId) {
                return {
                  ...item,
                  status: PickingItemStatus.CANCELLED,
                };
              }

              return item;
            }),
          };
        }

        return sale;
      });

      setOrderDetails((currentState) => {
        return {
          ...currentState,
          sales: changeItemStatus,
        };
      });

      toastify("success", "Item successfully canceled", "top-center");
    } catch (error) {
      toastify("error", "Error canceling item", "top-center");
      throw Error(String(error));
    } finally {
      handleLoading("cancelItem", false);
    }
  };

  const cancelProductListLineItem = async (
    orderID: string,
    orderLineID: string,
    localOrderDetails: OrderDetails,
  ) => {
    setLoading((state) => {
      return {
        ...state,
        cancelItem: true,
        id: orderLineID,
      };
    });
    try {
      const { data } = await wrapperRequests(
        routesURL.report.orders.cancelLineItem(orderID, orderLineID),
        "PUT",
        {
          data: {
            accessToken: api.defaults.headers.common["Authorization"],
            newStatus: OrderItemProcessStatus.CANCELLED,
          },
        },
      );

      const changeLineItemStatus = localOrderDetails.items?.map(
        (item: ProductList) => {
          if (orderLineID === item.id) {
            return {
              ...item,
              processStatus: OrderItemProcessStatus.CANCELLED,
            };
          }

          return item;
        },
      );

      setOrderDetails((currentState) => {
        return {
          ...currentState,
          processStatus: data.orderStatus,
          items: changeLineItemStatus,
        };
      });

      toastify("success", "Item successfully canceled", "top-center");
    } catch (error) {
      toastify("error", "Error canceling item", "top-center");
      throw Error(String(error));
    } finally {
      handleLoading("cancelItem", false);
    }
  };

  const selectPage = (value: number) => {
    setOrdersPagination((currentValue) => {
      return {
        ...currentValue,
        page: value,
      };
    });
  };

  const selectRowsPerPage = (value: number) => {
    setOrdersPagination((currentValue) => {
      return {
        ...currentValue,
        rowsPerPage: value,
      };
    });
  };

  return (
    <OrdersContext.Provider
      value={{
        cancelShipmentsItem,
        cancelProductListLineItem,
        fetchOrders,
        fetchOrderDetails,
        loading,
        ordersList,
        orderDetails,
        ordersSyncErrorLoading,
        ordersPagination,
        retryImport,
        selectPage,
        selectRowsPerPage,
        toastify,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
}
