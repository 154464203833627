import { MdOutlineDelete, MdOutlineModeEditOutline } from "react-icons/md";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { UserTypes } from "../../../@types/User";

import { ConfirmationModal } from "../../ConfirmationModal";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../TableElements";

import * as S from "./styles";

interface WebUserListProps {
  loading: boolean;
  data: UserTypes[];
  onHandleDeleteUser: (id: string) => Promise<void>;
  selectSortBy: (value: string) => void;
}

export function WebUserList({
  data,
  loading,
  onHandleDeleteUser,
  selectSortBy,
}: WebUserListProps) {
  const navigate = useNavigate();

  function handleConfirmDeleteUser(id: string, name: string) {
    onHandleDeleteUser(id);
  }

  function handleNavigateToEditUser(id: string) {
    navigate(`/settings/users/${id}`, { state: { id: id } });
  }

  function rolesNames(rolesQtd: number) {
    if (rolesQtd === 0) return "Custom";
    if (rolesQtd === 1) return;
    if (rolesQtd > 1) return "Roles +1";
  }

  return (
    <S.Container userListLoading={loading}>
      {loading ? (
        <ReactLoading height={35} width={35} type={"spin"} color="#000" />
      ) : (
        <TableComponent>
          <thead>
            <tr>
              <ThComponent
                arrowUpFunction={() => selectSortBy("name_desc")}
                arrowDownFunction={() => selectSortBy("name_asc")}
              >
                Name
              </ThComponent>
              <ThComponent
                arrowUpFunction={() => selectSortBy("email_desc")}
                arrowDownFunction={() => selectSortBy("email_asc")}
              >
                Email
              </ThComponent>
              <ThComponent
                arrowUpFunction={() => selectSortBy("roles_desc")}
                arrowDownFunction={() => selectSortBy("roles_asc")}
              >
                Roles
              </ThComponent>
              <ThComponent withArrows={false}>Action</ThComponent>
            </tr>
          </thead>

          <tbody>
            {!!data.length &&
              data.map((user) => (
                <TrComponent key={user.id}>
                  <TdComponent tdWidth={"50%"}>{user.name}</TdComponent>
                  <TdComponent tdWidth={"40%"}>{user.email}</TdComponent>
                  <TdComponent className="user_roles">
                    <S.UserRoleContainer
                      role={"Custom"}
                      title={
                        rolesNames(user.roles.length)
                          ? rolesNames(user.roles.length)
                          : String(user.roles.map((role) => role.name))
                      }
                    >
                      {rolesNames(user.roles.length)
                        ? rolesNames(user.roles.length)
                        : String(user.roles.map((role) => role.name))}
                    </S.UserRoleContainer>
                  </TdComponent>

                  <TdComponent>
                    <S.ActionsButton
                      title="Edit user"
                      onClick={() => handleNavigateToEditUser(user.id)}
                    >
                      <MdOutlineModeEditOutline size={24} />
                    </S.ActionsButton>
                    <ConfirmationModal
                      title={`Do you want to delete the user ${user.name} ?`}
                      variant="delete"
                      confirmFunction={() =>
                        handleConfirmDeleteUser(user.id, user.name)
                      }
                    >
                      <S.ActionsButton
                        className="delete_button"
                        title="Delete user"
                      >
                        <MdOutlineDelete size={24} />
                      </S.ActionsButton>
                    </ConfirmationModal>
                  </TdComponent>
                </TrComponent>
              ))}
          </tbody>
        </TableComponent>
      )}
    </S.Container>
  );
}
