import styled, { css } from "styled-components";

export const ItemDetailsCardContainer = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: ${(props) => props.theme.colors.white};

  border: 0.06rem solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: ${(props) => props.theme.borderRadius.MD};
  padding: 1.25rem;
  width: 17rem;
  height: 24.2rem;
`;

export const ItemDetailsCardTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize.XXS};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-weight: ${(props) => props.theme.fontWeight.Bold};

  padding-bottom: 1rem;
  border-bottom: 0.06rem solid ${(props) => props.theme.colors["gray-400"]};
`;

interface ItemDetailsContentProps {
  search?: boolean;
}

export const ItemDetailsContent = styled.div<ItemDetailsContentProps>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 0.75rem;

  padding-right: 1rem;

  height: 100%;

  ${(props) => props.search && css``};

  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
    padding: 1.25rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.white};
    border: 0.06rem solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors["gray-700"]};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding-right: 0.25rem;

  input {
    cursor: pointer;
  }
`;

export const FilterButton = styled.button`
  border: none;
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeight.Medium};

  width: 100%;

  color: ${(props) => props.theme.colors["gray-800"]};
  background: ${(props) => props.theme.colors.white};
`;

export const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ItemDetailsRow = styled.div`
  display: flex;
  //justify-content: center;
  //align-items: center; 
  text-align: center;
`;

export const ItemDetailsProperty = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  font-family: ${(props) => props.theme.fontFamily.Roboto};

  color: ${(props) => props.theme.colors["black-750"]};
  width: 8.5rem;
  height: 2rem;

  align-content: center;

  flex: 1;
  text-align: left;
`;

interface ItemDetailsValueContainerProps {
  isSpecial?: boolean;
  isPricingOrLocation?: boolean;
}

export const ItemDetailsValueContainer = styled.div<ItemDetailsValueContainerProps>`
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0.5rem;

  min-width: 3.75rem;
  min-height: 2rem;

  width: ${(props) =>
    props.isPricingOrLocation ? "6rem" : props.isSpecial ? "3.75rem" : "auto"};
  height: ${(props) =>
    props.isPricingOrLocation ? "2rem" : props.isSpecial ? "2rem" : "auto"};
  background-color: ${(props) => props.theme.colors["gray-200"]};

  border-radius: 0.25rem;
  height: 2rem;
`;

export const ItemDetailsValue = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Medium};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors["gray-800"]};
`;

export const Separator = styled.div`
  width: 100%;
  border-bottom: 0.06rem solid ${(props) => props.theme.colors["gray-400"]};
`;

export const SearchInputContainer = styled.div`
  padding-left: 0.1rem;
`;
