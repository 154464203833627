import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  height: 55vh;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 1rem;

  h3 {
    font-size: ${(props) => props.theme.fontSize.SM};
    color: ${(props) => props.theme.colors["black-925"]};
  }

  span {
    font-size: ${(props) => props.theme.fontSize.XXS};
    color: ${(props) => props.theme.colors["gray-600"]};
  }

  button {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors["gray-700"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};

    color: ${(props) => props.theme.colors["gray-950"]};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};

    height: 3rem;
    padding: 0 1rem;
    margin-top: 1rem;

    &:hover {
      background-color: ${(props) => props.theme.colors["gray-300"]};
      outline: 1px solid ${(props) => props.theme.colors["gray-750"]};
      transition: all 0.05s;
    }
  }
`;
