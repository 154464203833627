import styled from "styled-components";

export const ModalFormContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const ModalAlign = styled.div`
  display: grid;
  gap: 1.75rem;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
`;
