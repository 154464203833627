import { format, isAfter, isBefore, isValid, parse, parseISO } from "date-fns";
import { useRef, useState } from "react";
import {
  DateRange,
  SelectRangeEventHandler,
  SelectSingleEventHandler,
} from "react-day-picker";
import { IoClose } from "react-icons/io5";
import { useContextSelector } from "use-context-selector";
import { useOnClickOutside } from "usehooks-ts";
import {
  IReportExploreFiltersToSelect,
  IReportExploreSelectedFilterData,
} from "../../../../../../@types/ReportExplore";
import { DatePicker } from "../../../../../../components/DatePicker";
import { SelectInput } from "../../../../../../components/SelectInput";
import { ExploreItemsContext } from "../../../../../../contexts/ExploreItemsContext";
import * as S from "./styles";

interface FilterOperatorProps {
  filter: IReportExploreFiltersToSelect;
  collection: string;
  currentFilterValues?: IReportExploreSelectedFilterData;
}

interface InputData {
  content: string;
  currentMonthSelected?: Date;
}
interface InputTypes {
  inputStart: InputData;
  inputEnd: InputData;
  inputSingleDate: InputData;
}
interface DatePickerController {
  inputValue: InputTypes;
  selectedDate?: Date;
  selectRange?: DateRange;
}

export function FilterOperator({
  filter,
  collection,
  currentFilterValues,
}: FilterOperatorProps) {
  const [filterData, setFilterData] = useState({
    filter: filter.key,
    value: currentFilterValues?.value ?? "",
    operator: currentFilterValues?.operator ?? "",
  });

  const {
    insertFiltersValues,
    exploreItemsSelectFilters,
    handleDateFilterModal,
  } = useContextSelector(ExploreItemsContext, (context) => {
    return context;
  });

  const [datePickerValue, setDatePickerValue] = useState<DatePickerController>({
    inputValue: {
      inputStart: {
        content: convertToOriginalDate(currentFilterValues?.start ?? "") ?? "",
        currentMonthSelected: currentFilterValues?.start
          ? parseISO(currentFilterValues?.start)
          : undefined,
      },
      inputEnd: {
        content: convertToOriginalDate(currentFilterValues?.end ?? "") ?? "",
        currentMonthSelected: currentFilterValues?.end
          ? parseISO(currentFilterValues?.end)
          : undefined,
      },

      inputSingleDate: {
        content: convertToOriginalDate(currentFilterValues?.value ?? "") ?? "",
        currentMonthSelected: currentFilterValues?.value
          ? parseISO(currentFilterValues?.value)
          : undefined,
      },
    },
    selectedDate: currentFilterValues?.start
      ? parseISO(currentFilterValues?.start)
      : undefined,
    selectRange: {
      from: currentFilterValues?.start
        ? parseISO(currentFilterValues?.start)
        : undefined,
      to: currentFilterValues?.end
        ? parseISO(currentFilterValues?.end)
        : undefined,
    },
  });

  function handleSaveBetweenValues(filter: IReportExploreSelectedFilterData) {
    insertFiltersValues(collection, filter);
  }

  function convertToISODate(dateString: string) {
    const dateParts = dateString.split("/");
    if (dateParts.length === 3) {
      const [month, day, year] = dateParts;
      const isoDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0",
      )}`;
      return isoDate;
    } else {
      return dateString;
    }
  }

  function convertToOriginalDate(isoDateString: string) {
    const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})/;
    const matches = isoDateString.match(isoDateRegex);

    if (matches) {
      const year = matches[1];
      const month = matches[2];
      const day = matches[3];
      const originalDate = `${month}/${day}/${year}`;
      return originalDate;
    } else {
      return isoDateString;
    }
  }

  function convertOperatorValueToLabel(operator: string) {
    const formattedLabel = operator.replace(/([A-Z])/g, " $1");
    const capitalizedLabel = formattedLabel.replace(/^./, (str) =>
      str.toUpperCase(),
    );

    return capitalizedLabel;
  }

  function handleNavigationToMonth(inputType: keyof InputTypes, month: Date) {
    setDatePickerValue((state) => ({
      ...state,
      inputValue: {
        ...state.inputValue,
        [inputType]: {
          ...state.inputValue[inputType],
          currentMonthSelected: month,
        },
      },
    }));
  }

  function handleInputSingleDateChange(text: string) {
    setDatePickerValue((state) => ({
      ...state,
      inputValue: {
        ...state.inputValue,
        inputSingleDate: {
          ...state.inputValue.inputSingleDate,
          content: text,
        },
      },
    }));

    const date = parse(text, "MM/dd/yyyy", new Date());

    if (isValid(date)) {
      setDatePickerValue((state) => ({
        ...state,
        selectedDate: date,
      }));

      handleNavigationToMonth("inputSingleDate", date);
    } else {
      setDatePickerValue((state) => ({
        ...state,
        selectedDate: undefined,
      }));
    }
  }

  const handleSingleDaySelected: SelectSingleEventHandler = (date) => {
    setDatePickerValue((state) => ({ ...state, selectedDate: date }));
    if (date) {
      setDatePickerValue((state) => ({
        ...state,
        inputValue: {
          ...state.inputValue,
          inputSingleDate: {
            ...state.inputValue.inputSingleDate,
            content: format(date, "MM/dd/yyyy"),
          },
        },
      }));
    } else {
      setDatePickerValue((state) => ({
        ...state,
        inputValue: {
          ...state.inputValue,
          inputSingleDate: { ...state.inputValue.inputSingleDate, content: "" },
        },
      }));
    }
  };

  function handleInputFromChange(text: string) {
    setDatePickerValue((state) => ({
      ...state,
      inputValue: {
        ...state.inputValue,
        inputStart: {
          ...state.inputValue.inputStart,
          content: text,
        },
      },
    }));

    const date = parse(text, "MM/dd/yyyy", new Date());

    if (!isValid(date)) {
      return setDatePickerValue((state) => ({
        ...state,
        inputValue: {
          ...state.inputValue,
          inputEnd: {
            content: "",
            currentMonthSelected: undefined,
          },
        },
        selectRange: {
          from: undefined,
          to: undefined,
        },
      }));
    }
    if (
      datePickerValue.selectRange?.to &&
      isAfter(date, datePickerValue.selectRange.to)
    ) {
      handleNavigationToMonth("inputStart", date);

      setDatePickerValue((state) => ({
        ...state,
        selectRange: {
          from: state.selectRange?.to,
          to: date,
        },
      }));
    } else {
      handleNavigationToMonth("inputStart", date);

      setDatePickerValue((state) => ({
        ...state,
        selectRange: {
          from: date,
          to: state.inputValue.inputEnd.currentMonthSelected,
        },
      }));
    }
  }

  function handleInputToChange(text: string) {
    setDatePickerValue((state) => ({
      ...state,
      inputValue: {
        ...state.inputValue,
        inputEnd: {
          ...state.inputValue.inputEnd,
          content: text,
        },
      },
    }));

    const date = parse(text, "MM/dd/yyyy", new Date());

    if (!isValid(date)) {
      return setDatePickerValue((state) => ({
        ...state,
        selectRange: {
          from: state.selectRange?.from,
          to: undefined,
        },
      }));
    }

    if (
      datePickerValue.selectRange?.from &&
      isBefore(date, datePickerValue.selectRange.from)
    ) {
      handleNavigationToMonth("inputEnd", date);

      setDatePickerValue((state) => ({
        ...state,
        selectRange: {
          from: date,
          to: state.selectRange?.from,
        },
      }));
    } else {
      handleNavigationToMonth("inputEnd", date);

      setDatePickerValue((state) => ({
        ...state,
        selectRange: {
          from: state.inputValue.inputStart.currentMonthSelected,
          to: date,
        },
      }));
    }
  }
  const handleRangeSelect: SelectRangeEventHandler = (
    range: DateRange | undefined,
  ) => {
    setDatePickerValue((state) => ({
      ...state,
      selectRange: range,
    }));

    if (range?.from) {
      setDatePickerValue((state) => ({
        ...state,
        inputValue: {
          ...state.inputValue,
          inputStart: {
            ...state.inputValue.inputStart,
            content: format(Number(range.from), "MM/dd/yyyy"),
          },
        },
      }));

      handleSaveBetweenValues({
        ...filterData,
        start: format(range.from, "yyyy-MM-dd"),
      });
    } else {
      setDatePickerValue((state) => ({
        ...state,
        inputValue: {
          ...state.inputValue,
          inputStart: {
            ...state.inputValue.inputStart,
            content: "",
          },
        },
      }));
    }
    if (range?.to) {
      setDatePickerValue((state) => ({
        ...state,
        inputValue: {
          ...state.inputValue,
          inputEnd: {
            ...state.inputValue.inputEnd,
            content: format(Number(range.to), "MM/dd/yyyy"),
          },
        },
      }));

      // const endDateAddDay = addDays(range.to, 1);

      handleSaveBetweenValues({
        ...filterData,
        end: convertToISODate(format(range.to, "yyyy-MM-dd")),
      });

      handleNavigationToMonth("inputEnd", range.to);
    } else {
      setDatePickerValue((state) => ({
        ...state,
        inputValue: {
          ...state.inputValue,
          inputEnd: {
            ...state.inputValue.inputEnd,
            content: "",
          },
        },
      }));
    }
  };

  const inputStart = useRef<HTMLDivElement>(null);
  const inputEnd = useRef<HTMLDivElement>(null);
  const inputSingleDate = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    inputStart,
    () =>
      filter.modal?.inputStart &&
      handleDateFilterModal(collection, filter.key, "1"),
  );

  useOnClickOutside(
    inputEnd,
    () =>
      filter.modal?.inputEnd &&
      handleDateFilterModal(collection, filter.key, "2"),
  );

  useOnClickOutside(
    inputSingleDate,
    () =>
      filter.modal?.inputSingleDate &&
      handleDateFilterModal(collection, filter.key, "3"),
  );

  return (
    <S.FilterContainer key={filter.key}>
      <span>{filter.label}</span>
      <SelectInput
        placeholder="Select an operator"
        defaultValue={
          currentFilterValues?.operator
            ? `${currentFilterValues?.operator}`
            : "Select an operator"
        }
        options={filter.operators.map((operator, index) => {
          return {
            id: String(index),
            value: operator,
            label: convertOperatorValueToLabel(operator),
          };
        })}
        onChange={(e) => {
          setFilterData((currentState) => {
            if (!e.target.value) {
              return {
                ...currentState,
                value: "",
                operator: e.target.value,
              };
            }
            return {
              ...currentState,
              operator: e.target.value,
            };
          });
          insertFiltersValues(collection, {
            ...filterData,
            operator: e.target.value,
          });
        }}
        variant="exploreItems"
      />

      {filter.type === "date" ? (
        currentFilterValues?.operator === "between" ||
        filterData.operator === "between" ? (
          <>
            <S.InputDateContainer
              isDisabled={!currentFilterValues?.operator}
              ref={inputStart}
            >
              <DatePicker
                open={filter.modal?.inputStart}
                id="inputStart"
                mode="range"
                selected={datePickerValue.selectRange}
                month={
                  datePickerValue.inputValue.inputStart.currentMonthSelected
                }
                onMonthChange={(event) =>
                  handleNavigationToMonth("inputStart", event)
                }
                onSelect={handleRangeSelect}
              />
              <S.DateInput
                mask="99/99/9999"
                maskPlaceholder=""
                placeholder="mm/dd/yyyy"
                disabled={!currentFilterValues?.operator}
                value={datePickerValue.inputValue.inputStart.content ?? ""}
                onFocus={() =>
                  handleDateFilterModal(collection, filter.key, "1")
                }
                onChange={(e) => {
                  handleSaveBetweenValues({
                    ...filterData,
                    start: convertToISODate(e.target.value),
                  });
                  handleInputFromChange(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  handleDateFilterModal(collection, filter.key, "1");
                }}
                disabled={!currentFilterValues?.operator}
              >
                <S.CalendarIcon
                  style={{
                    cursor: !currentFilterValues?.operator
                      ? "not-allowed"
                      : "pointer",
                  }}
                />
              </button>
            </S.InputDateContainer>
            <S.InputDateContainer
              isDisabled={!currentFilterValues?.operator}
              ref={inputEnd}
            >
              <DatePicker
                open={filter.modal?.inputEnd}
                mode="range"
                selected={datePickerValue.selectRange}
                onSelect={handleRangeSelect}
                onMonthChange={(event) =>
                  handleNavigationToMonth("inputEnd", event)
                }
                month={datePickerValue.inputValue.inputEnd.currentMonthSelected}
              />

              <S.DateInput
                mask="99/99/9999"
                maskPlaceholder=""
                placeholder="mm/dd/yyyy"
                value={datePickerValue.inputValue.inputEnd.content ?? ""}
                disabled={!currentFilterValues?.operator}
                onFocus={() =>
                  handleDateFilterModal(collection, filter.key, "2")
                }
                onChange={(e) => {
                  handleSaveBetweenValues({
                    ...filterData,
                    end: convertToISODate(e.target.value),
                  });
                  handleInputToChange(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  handleDateFilterModal(collection, filter.key, "2");
                }}
                disabled={!currentFilterValues?.operator}
              >
                <S.CalendarIcon
                  style={{
                    cursor: !currentFilterValues?.operator
                      ? "not-allowed"
                      : "pointer",
                  }}
                />
              </button>
            </S.InputDateContainer>
          </>
        ) : (
          <>
            <S.InputDateContainer
              isDisabled={!currentFilterValues?.operator}
              ref={inputSingleDate}
            >
              <DatePicker
                mode="single"
                open={filter.modal?.inputSingleDate}
                selected={datePickerValue.selectedDate}
                onSelect={handleSingleDaySelected}
                onMonthChange={(event) =>
                  handleNavigationToMonth("inputSingleDate", event)
                }
                month={
                  datePickerValue.inputValue.inputSingleDate
                    .currentMonthSelected
                }
                onDayClick={(event) =>
                  handleSaveBetweenValues({
                    ...filterData,
                    value: format(event, "yyyy-MM-dd"),
                  })
                }
              />

              <S.DateInput
                mask="99/99/9999"
                maskPlaceholder=""
                placeholder="mm/dd/yyyy"
                value={datePickerValue.inputValue.inputSingleDate.content ?? ""}
                disabled={!currentFilterValues?.operator}
                onFocus={() =>
                  handleDateFilterModal(collection, filter.key, "3")
                }
                onChange={(e) => {
                  handleSaveBetweenValues({
                    ...filterData,
                    value: convertToISODate(e.target.value),
                  });
                  handleInputSingleDateChange(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  handleDateFilterModal(collection, filter.key, "3");
                }}
                disabled={!currentFilterValues?.operator}
              >
                <S.CalendarIcon
                  style={{
                    cursor: !currentFilterValues?.operator
                      ? "not-allowed"
                      : "pointer",
                  }}
                />
              </button>
            </S.InputDateContainer>
          </>
        )
      ) : currentFilterValues?.operator === "between" ||
        filterData.operator === "between" ? (
        <>
          <input
            type="text"
            placeholder={
              filter.type === "string" ? "Search word" : "Search number"
            }
            disabled={!currentFilterValues?.operator}
            defaultValue={currentFilterValues?.start ?? ""}
            onChange={(e) =>
              handleSaveBetweenValues({
                ...filterData,
                start: e.target.value,
              })
            }
          />
          <input
            type="text"
            placeholder={
              filter.type === "string" ? "Search word" : "Search number"
            }
            disabled={!currentFilterValues?.operator}
            defaultValue={currentFilterValues?.end ?? ""}
            onChange={(e) =>
              handleSaveBetweenValues({
                ...filterData,
                end: e.target.value,
              })
            }
          />
        </>
      ) : (
        <input
          type="text"
          placeholder={
            filter.type === "string" ? "Search word" : "Search number"
          }
          disabled={!currentFilterValues?.operator}
          defaultValue={currentFilterValues?.value ?? ""}
          onChange={(e) => {
            insertFiltersValues(collection, {
              ...filterData,
              value: e.target.value,
            });
          }}
        />
      )}
      <S.Wrapper>
        <IoClose
          size={24}
          onClick={() => {
            exploreItemsSelectFilters(collection, filter);
          }}
        />
      </S.Wrapper>
    </S.FilterContainer>
  );
}
