export const monthsOptions = [
  {
    id: "0",
    value: "1",
    label: "January",
  },
  {
    id: "1",
    value: "2",
    label: "February",
  },
  {
    id: "2",
    value: "3",
    label: "March",
  },
  {
    id: "3",
    value: "4",
    label: "April",
  },
  {
    id: "4",
    value: "5",
    label: "May",
  },
  {
    id: "5",
    value: "6",
    label: "June",
  },
  {
    id: "6",
    value: "7",
    label: "July",
  },
  {
    id: "7",
    value: "8",
    label: "August",
  },
  {
    id: "8",
    value: "9",
    label: "September",
  },
  {
    id: "9",
    value: "10",
    label: "October",
  },
  {
    id: "10",
    value: "11",
    label: "November",
  },
  {
    id: "11",
    value: "12",
    label: "December",
  },
];

export const yearsOptions = [
  {
    id: "1",
    label: "2023",
    value: "2023",
  },
  {
    id: "2",
    label: "2024",
    value: "2024",
  },
  {
    id: "3",
    label: "2025",
    value: "2025",
  },
  {
    id: "4",
    label: "2026",
    value: "2026",
  },
];
