import { format } from "date-fns";
import { MdEditNote } from "react-icons/md";
import ReactLoading from "react-loading";
import { OrderList } from "../../../../../../@types/Orders";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../../../../../components/TableElements";
import { ActionsButton } from "../../../../../../components/TableElements/components/ActionsButton";

import { useNavigate } from "react-router-dom";
import { CopyContainer } from "../../../../../../components/TableElements/components/CopyContainer";
import { OrderStatusConverter } from "../../../../../../enums/Orders";
import { ConvertStringWithoutValueToDefault } from "../../../../../../shared/ConvertStringWithoutValueToDefault";
import { ReduceString } from "../../../../../../shared/ReduceString";
import * as S from "./styles";

interface OrdersSyncErrorProps {
  loading: boolean;
  data: OrderList[];
}

export function OrdersList({ data, loading }: OrdersSyncErrorProps) {
  const navigate = useNavigate();

  return (
    <S.Container ordersSyncLoading={loading}>
      {loading ? (
        <ReactLoading height={35} width={35} type={"spin"} color="#000" />
      ) : (
        <TableComponent>
          <thead>
            <tr>
              <ThComponent withArrows={false}>Order ID</ThComponent>
              <ThComponent withArrows={false}>Site Order ID</ThComponent>
              <ThComponent withArrows={false}>Order Date</ThComponent>
              <ThComponent withArrows={false}>Customer Name</ThComponent>
              <ThComponent withArrows={false}>Error Message</ThComponent>
              <ThComponent withArrows={false}>Status</ThComponent>
              <ThComponent withArrows={false}>Actions</ThComponent>
            </tr>
          </thead>

          <tbody>
            {data.map((order) => (
              <TrComponent key={order.id}>
                <TdComponent tdWidth={"15%"}>
                  <CopyContainer stringValue={order?.orderReferenceNumber} />
                </TdComponent>
                <TdComponent tdWidth={"15%"}>
                  <CopyContainer stringValue={order?.storeOrderID} />
                </TdComponent>
                <TdComponent tdWidth={"15%"}>
                  {order.createdAt &&
                    format(
                      new Date(order.createdAt),
                      "MM-dd-yyyy 'at' hh:mmaaaaa'm'",
                    )}
                </TdComponent>
                <TdComponent tdWidth={"20%"}>
                  {order.billing?.firstName
                    ? `${order.billing?.lastName}, ${order.billing?.firstName}`
                    : `${order?.shipping?.lastName}, ${order?.shipping?.firstName}`}
                </TdComponent>
                <TdComponent tdWidth={"60%"}>
                  {ConvertStringWithoutValueToDefault(order?.processMessage)}
                </TdComponent>
                <TdComponent tdWidth={"20%"}>
                  <S.StatusTag
                    status={order.processStatus}
                    title={OrderStatusConverter[order.processStatus]}
                  >
                    {ReduceString(OrderStatusConverter[order.processStatus], 7)}
                  </S.StatusTag>
                </TdComponent>
                <TdComponent>
                  <ActionsButton
                    title="Order details"
                    onClick={() => navigate(`/report/orders/${order.id}`)}
                  >
                    <MdEditNote size={24} />
                  </ActionsButton>
                </TdComponent>
              </TrComponent>
            ))}
          </tbody>
        </TableComponent>
      )}
    </S.Container>
  );
}
