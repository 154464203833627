import styled, { css } from "styled-components";

export const ForgotPasswordContainer = styled.main`
  display: flex;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background: ${(props) => props.theme.colors["black-900"]};
`;

export const ForgotPasswordContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 10rem;
  width: 24rem;
`;

export const SignInLogo = styled.img`
  width: 7.8rem;
  height: 3rem;

  margin-bottom: 3rem;

  cursor: pointer;
`;
