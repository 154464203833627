import { useState } from "react";
import { wrapperRequests } from "../services/api";
import { routesURL } from "../services/routesUrl";

interface getAccountingSalesReportProps {
  startDate: string;
  endDate: string;
}

export function useAccountingSalesReport() {
  const [useAccountingSalesReportLoading, setUseAccountingSalesReportLoading] =
    useState(false);

  function padWithZero(num: number) {
    return num < 10 ? `0${num}` : `${num}`;
  }

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = padWithZero(date.getMonth() + 1);
    const day = padWithZero(date.getDate());
    const hours = padWithZero(date.getHours());
    const minutes = padWithZero(date.getMinutes());
    const seconds = padWithZero(date.getSeconds());
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  }

  async function getAccountingSalesReport({
    startDate,
    endDate,
  }: getAccountingSalesReportProps) {
    setUseAccountingSalesReportLoading(true);
    function generateReportFileName() {
      const currentDate = new Date();
      const formattedDate = formatDate(currentDate);
      return `report_${formattedDate}.csv`;
    }

    try {
      const response = await wrapperRequests(
        routesURL.report.explore.getAccountingSalesReport,
        "POST",
        {
          data: {
            startDate,
            endDate,
          },
          responseType: "blob",
          timeout: 900000,
        },
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", generateReportFileName());
      link.style.display = "none";
      document.body.appendChild(link);

      document.body.removeChild(link);

      link.click();

      URL.revokeObjectURL(url);
      setUseAccountingSalesReportLoading(false);
    } catch (error) {
      setUseAccountingSalesReportLoading(false);
      throw Error(String(error));
    }
  }
  return {
    getAccountingSalesReport,
    useAccountingSalesReportLoading,
  };
}
