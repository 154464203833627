import ReactLoading from "react-loading";
import { useContextSelector } from "use-context-selector";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../../../../../components/TableElements";
import { ExploreItemsContext } from "../../../../../../contexts/ExploreItemsContext";

import { useTheme } from "styled-components";
import { NotFound } from "../../../../../../components/NotFound";
import { ExploreFiltersLoadingContainer } from "../SideFilters/styles";
import * as S from "./styles";

interface ExploreTableProps {
  updateSelectedPage: (page: "filters" | "table") => void;
}

export function ExploreTable({ updateSelectedPage }: ExploreTableProps) {
  const { tableData, exploreItemsLoading, changeRowLimit } = useContextSelector(
    ExploreItemsContext,
    (context) => {
      return context;
    },
  );

  const theme = useTheme();

  function convertColumnNameValueToLabel(titleString: string) {
    const formattedLabel = titleString.replace(/([A-Z])/g, " $1");
    const capitalizedLabel = formattedLabel.replace(/^./, (str) =>
      str.toUpperCase(),
    );

    return capitalizedLabel;
  }

  function getLastValue(titleString: string) {
    const parts = titleString?.split(".");
    const partialName =
      parts.length > 1 ? parts[parts.length - 1] : titleString;

    const columnName = convertColumnNameValueToLabel(partialName);
    return columnName;
  }

  return (
    <S.ExploreTableContainer>
      <S.TableTitleContainer>
        <span>Table</span>
        <div>
          <span>Row limit</span>
          <input
            type="number"
            defaultValue={10}
            onChange={(e) => changeRowLimit(Number(e.target.value))}
          />
        </div>
      </S.TableTitleContainer>
      {exploreItemsLoading.type === "data" && exploreItemsLoading.isLoading ? (
        <ExploreFiltersLoadingContainer>
          <ReactLoading
            height={35}
            width={35}
            type={"spin"}
            color={theme.colors["blue-600"]}
          />
        </ExploreFiltersLoadingContainer>
      ) : tableData.length > 0 &&
        tableData?.some((obj) => Object?.keys(obj)?.length > 0) ? (
        <S.TableContainer>
          <TableComponent>
            <thead>
              <tr>
                {tableData.length > 0 &&
                  Array.from(
                    new Set(tableData.flatMap((obj) => Object.keys(obj))),
                  ).map((key) => (
                    <ThComponent key={key} withArrows={false}>
                      {getLastValue(key)}
                    </ThComponent>
                  ))}
              </tr>
            </thead>

            <tbody>
              {tableData?.map((data, index) => (
                <TrComponent key={index}>
                  {Object.keys(data).map((key) => (
                    <TdComponent key={key}>{data[key]}</TdComponent>
                  ))}
                </TrComponent>
              ))}
            </tbody>
          </TableComponent>
        </S.TableContainer>
      ) : (
        <NotFound
          title="Table data is empty, try adjusting your filters"
          buttonTitle="Filters"
          onClick={() => updateSelectedPage("filters")}
        />
      )}
    </S.ExploreTableContainer>
  );
}
