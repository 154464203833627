import React, { 
  createContext, 
  useCallback, 
  useState, 
  useContext, 
  Dispatch, 
  SetStateAction 
} from "react";

interface MenuContextData {
  menu: string;
  showMenu: boolean; 
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  updateMenu: (name: string) => void;
}

const MenuContext = createContext<MenuContextData>({} as MenuContextData);

const MenuProvider: React.FC = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);  
  const [menu, setMenu] = useState("Products");  

  const updateMenu = useCallback((name: string) => {
    setMenu(name);
  }, [setMenu]);

  return (
    <MenuContext.Provider
      value={{ updateMenu, showMenu, setShowMenu, menu }}
    >
      {children}
    </MenuContext.Provider>
  );
};

function useMenu(): MenuContextData {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error("useMenu must be used within an MenuProvider");
  }

  return context;
}

export { MenuProvider, useMenu };