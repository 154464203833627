import styled, { css } from "styled-components";

export const DuplicateItemsContainer = styled.main`
  flex: 1;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 3rem;
`;

export const DuplicateItemsHeaderButtonContainer = styled.div`
  display: flex;

  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;

interface DuplicateItemsHeaderButtonProps {
  active?: boolean;
}

export const DuplicateItemsHeaderButton = styled.button<DuplicateItemsHeaderButtonProps>`
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;

  padding: 1rem 2rem;

  font-family: ${(props) => props.theme.fontFamily.Sora};
  color: ${(props) => props.theme.colors["gray-800"]};

  ${(props) =>
    props.active &&
    css`
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      color: ${(props) => props.theme.colors["blue-600"]};
      border-bottom: 2px solid ${(props) => props.theme.colors["blue-600"]};
    `};

  &:hover {
    background: ${(props) => props.theme.colors["blue-200"]};
    color: ${(props) => props.theme.colors["blue-600"]};
    transition: all 0.2s;
  }
`;

export const DuplicateItemsFiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin: 2rem 0 2.75rem;
  gap: 1.5rem;

  input {
    width: 22rem;
  }

  div.filters_align {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    gap: 1.5rem;
  }
`;
