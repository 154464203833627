import { FormEvent, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoKey } from "react-icons/io5";
import ReactLoading from "react-loading";
import { ForgotPasswordUserDataProps } from "..";
import { SignInValidationMessage } from "../../components/SignInValidationMessage";

import { wrapperRequests } from "../../../../services/api";
import { Input, InputContainer, SignInButton, Title } from "../../styles";

import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";

export function CheckNewPassword({
  userData,
  setUserData,
}: ForgotPasswordUserDataProps) {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [error, setError] = useState("");

  async function handleUpdatePassword(event: FormEvent) {
    event.preventDefault();
    if (password !== passwordConfirmation) {
      return setError("The passwords did not match");
    }
    if (password.length < 6 || passwordConfirmation.length < 6) {
      return setError("Password must be 6 digits or more");
    }

    try {
      setIsLoading(true);
      await wrapperRequests(
        routesURL.auth.passwordRecoverUpdatePassword,
        "POST",
        {
          data: {
            email: userData?.email,
            recoverycode: userData?.code,
            password: password,
            passwordConfirmation: passwordConfirmation,
          },
        },
      );

      setUserData((currentValue) => {
        return {
          ...currentValue,
          password: password,
          passwordConfirmation: passwordConfirmation,
        };
      });
    } catch (error: any) {
      setError("");
      throw Error(error);
    }
  }

  return (
    <S.CheckNewPasswordContainer
      onSubmit={(event) => handleUpdatePassword(event)}
    >
      <Title>New password</Title>
      <InputContainer className="container" filled={!!password}>
        <IoKey />
        <Input
          type={showPassword ? "text" : "password"}
          name="password"
          onChange={(value) => {
            setPassword(value.target.value);
          }}
        />
        {showPassword ? (
          <FiEye
            style={{ cursor: "pointer" }}
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <FiEyeOff
            style={{ cursor: "pointer" }}
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </InputContainer>

      <Title>Password confirmation</Title>
      <InputContainer className="container" filled={!!passwordConfirmation}>
        <IoKey />
        <Input
          type={showPasswordConfirmation ? "text" : "password"}
          name="password"
          onChange={(value) => {
            setPasswordConfirmation(value.target.value);
          }}
        />
        {showPasswordConfirmation ? (
          <FiEye
            style={{ cursor: "pointer" }}
            onClick={() =>
              setShowPasswordConfirmation(!showPasswordConfirmation)
            }
          />
        ) : (
          <FiEyeOff
            style={{ cursor: "pointer" }}
            onClick={() =>
              setShowPasswordConfirmation(!showPasswordConfirmation)
            }
          />
        )}
      </InputContainer>

      <SignInButton
        type="submit"
        disabled={isLoading}
        onClick={(event) => handleUpdatePassword(event)}
      >
        {isLoading ? (
          <ReactLoading height={25} width={25} type={"spin"} color="#ffffff" />
        ) : (
          "Change Password"
        )}
      </SignInButton>

      {error && <SignInValidationMessage message={error} />}
    </S.CheckNewPasswordContainer>
  );
}
