import React from "react";

import { DuplicateItemsProvider } from "./DuplicateItemsContext";
import { ExploreItemsContextProvider } from "./ExploreItemsContext";
import { StoresProvider } from "./StoresContext";
import { UsersProvider } from "./UsersContext";
import { RolesProvider } from "./RolesContext";
import { UserPermissionsProvider } from "./UsersPermissions";
import { OrdersProvider } from "./OrdersContext";

const ContextsProvider: React.FC = ({ children }) => {
  return (
    <OrdersProvider>
      <DuplicateItemsProvider>
        <StoresProvider>
          <ExploreItemsContextProvider>
            <UsersProvider>
              <RolesProvider>
                <UserPermissionsProvider>{children}</UserPermissionsProvider>
              </RolesProvider>
            </UsersProvider>
          </ExploreItemsContextProvider>
        </StoresProvider>
      </DuplicateItemsProvider>
    </OrdersProvider>
  );
};

export default ContextsProvider;
