import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  padding: 3rem;
  padding-top: 1.5rem;
`;

export const UserRegisterHeader = styled.header`
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};

  h2 {
    font-size: ${(props) => props.theme.fontSize.MD};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.black};

    margin-bottom: 1.5rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    div.align_error {
      display: flex;
      flex-direction: column;
    }
  }

  div.error_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    color: ${(props) => props.theme.colors["red-500"]};

    svg {
      margin-right: 5px;
    }
  }
`;

export const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  gap: 1.5rem;

  div.input_align {
    display: flex;
    flex-direction: column;

    p {
      font-size: ${(props) => props.theme.fontSize.XXXS};
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      font-family: ${(props) => props.theme.fontFamily.Sora};
      color: ${(props) => props.theme.colors.black};
    }

    input {
      border: 1px solid ${(props) => props.theme.colors["gray-400"]};
      border-radius: ${(props) => props.theme.borderRadius.SM};
      color: ${(props) => props.theme.colors["gray-800"]};
      background-color: ${(props) => props.theme.colors.white};

      font-size: ${(props) => props.theme.fontSize.XXXS};
      font-family: ${(props) => props.theme.fontFamily.Sora};

      padding: 1rem;
      width: 22rem;
      max-height: 3rem;

      &:hover {
        outline: 1px solid ${(props) => props.theme.colors["gray-650"]};
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 0;
    width: 10.25rem;
    margin-top: 2rem;

    border-radius: ${(props) => props.theme.borderRadius.SM};
    border: none;

    background-color: ${(props) => props.theme.colors["blue-600"]};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.white};

    &:hover {
      background-color: ${(props) => props.theme.colors["blue-650"]};
      outline: 2px solid ${(props) => props.theme.colors["blue-500"]};
      transition: all 0.2s;
    }
  }
`;

export const PermissionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  border-top: 1px solid ${(props) => props.theme.colors["gray-400"]};

  margin: 2.5rem 0 0;
  padding: 2.5rem 0 0;

  h3 {
    font-size: ${(props) => props.theme.fontSize.SM};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors.black};

    margin-bottom: 1rem;
  }

  span {
    display: inline-block;

    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors["gray-800"]};

    max-width: 42rem;
  }

  button {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors["gray-700"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};

    color: ${(props) => props.theme.colors["gray-950"]};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};

    height: 3rem;
    padding: 0 1rem;

    &:hover {
      background-color: ${(props) => props.theme.colors["gray-300"]};
      outline: 1px solid ${(props) => props.theme.colors["gray-750"]};
      transition: all 0.05s;
    }
  }
`;

export const ErrorAlignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 1rem 0 0.5rem;
`;

export const SelectedStoresContainer = styled.div`
  div {
    display: flex;
  }

  p {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.black};

    margin: 1rem 0 0.25rem;
  }
`;

export const RolesAcess = styled.div`
  margin-top: 1rem;

  & > div:last-of-type {
    margin-top: 1.5rem;

    strong {
      font-size: ${(props) => props.theme.fontSize.SM};
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      color: ${(props) => props.theme.colors["black-750"]};
    }

    & > ul {
      list-style-type: none;
      margin-top: 1rem;
      max-width: 38rem;

      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;

      li {
        width: min(7rem, 100%);
        background-color: ${(props) => props.theme.colors["blue-200"]};
        padding: 0.5rem 1rem;
        border-radius: 24px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        span {
          flex: 1;
          font-size: ${(props) => props.theme.fontSize.XXXS};
          font-weight: ${(props) => props.theme.fontWeight.Regular};
          color: ${(props) => props.theme.colors["black-750"]};

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
`;
