import { useState } from "react";
import { useAuth } from "../../auth";

import { wrapperRequests } from "../../../services/api";
import { routesURL } from "../../../services/routesUrl";

export default function useItemSearch() {
  const [sortBy, setSortBy] = useState("");

  const { shopSelected } = useAuth();

  async function fetchItemDetails(itemId: string) {
    let webResponse, appResponse;

    try {
      webResponse = await wrapperRequests(
        routesURL.inventory.itemSearch.getItemDetails(
          shopSelected.tenantName,
          itemId,
        ),
        "GET",
      );
    } catch (webError) {
      webResponse = { data: {} };
      console.log(webError);
    }

    try {
      appResponse = await wrapperRequests(
        routesURL.inventory.itemSearch.getItemLocations(
          shopSelected.tenantName,
          shopSelected.code,
          webResponse?.data?.manufacturerSku ??
            webResponse?.data?.upc ??
            webResponse?.data?.ean,
        ),
        "GET",
      );
    } catch (appError) {
      appResponse = { data: {} };
      console.log(appError);
    }

    const fullItemData = {
      ...webResponse.data,
      ...appResponse.data,
    };

    return fullItemData;
  }

  const selectSortBy = (value: string) => {
    setSortBy(value);
  };

  return {
    fetchItemDetails,
    selectSortBy,
  };
}
