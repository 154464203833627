import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Button } from "../../../../components/Button";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { SelectInput } from "../../../../components/SelectInput";
import { useAccountingSalesReport } from "../../../../hooks/useAccountingSalesReport";
import { monthsOptions } from "./monthsOptions";
import * as S from "./styles";

export function AccountingSalesReport() {
  const currentYear = new Date().getFullYear();

  const [reportFilter, setReportFilter] = useState({
    month: "1",
    year: "2024",
  });
  const [yearsOptions, setYearsOptions] = useState<
    {
      id: string;
      label: string;
      value: string;
    }[]
  >([]);

  const { getAccountingSalesReport, useAccountingSalesReportLoading } =
    useAccountingSalesReport();

  function handleGetAccountingSalesReport() {
    const month = Number(reportFilter.month);
    const year = Number(reportFilter.year);

    const formattedMonth = month < 10 ? `0${month}` : month;

    const startDate = `${year}-${formattedMonth}-01`;

    const nextMonth = month === 12 ? 1 : month + 1;
    const nextYear = month === 12 ? year + 1 : year;
    const formattedNextMonth = nextMonth < 10 ? `0${nextMonth}` : nextMonth;

    const endDate = `${nextYear}-${formattedNextMonth}-01`;

    getAccountingSalesReport({ startDate, endDate });
  }

  function getYears() {
    for (let year = currentYear; year >= 2023; year--) {
      setYearsOptions((currentValue) => {
        return [
          ...currentValue,
          {
            id: year.toString(),
            label: year.toString(),
            value: year.toString(),
          },
        ];
      });
    }
  }

  useEffect(() => {
    getYears();
  }, []);

  return (
    <S.Container>
      <S.HeaderContainer>
        <PageHeader
          title="Accounting Sales Report"
          description="Download accounting sales report"
          hasButtons
          refreshFunction={() => {}}
          downloadFunction={() => handleGetAccountingSalesReport()}
          loading={{
            type: "csv",
            isLoading: useAccountingSalesReportLoading,
          }}
        />
      </S.HeaderContainer>

      <S.Content>
        <div>
          <S.FilterTitle>Select month</S.FilterTitle>
          <SelectInput
            options={monthsOptions}
            onChange={(e) =>
              setReportFilter((currentState) => {
                if (e.target.value) {
                  return {
                    ...currentState,
                    month: e.target.value,
                  };
                }
                return currentState;
              })
            }
          />
        </div>
        <div>
          <S.FilterTitle>Select year</S.FilterTitle>
          {yearsOptions && (
            <SelectInput
              options={yearsOptions}
              placeholder={reportFilter.year}
              onChange={(e) =>
                setReportFilter((currentState) => {
                  if (e.target.value) {
                    return {
                      ...currentState,
                      year: e.target.value,
                    };
                  }
                  return currentState;
                })
              }
            />
          )}
        </div>

        <Button
          disabled={useAccountingSalesReportLoading}
          onClick={handleGetAccountingSalesReport}
        >
          {useAccountingSalesReportLoading ? (
            <ReactLoading height={24} width={24} type={"spin"} color="#fff" />
          ) : (
            "Download"
          )}
        </Button>
      </S.Content>
    </S.Container>
  );
}
