import * as Dialog from "@radix-ui/react-dialog";
import styled, { css } from "styled-components";

export const ModalTrigger = styled(Dialog.Trigger)`
  border: none;
  background-color: transparent;
`;

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
`;

export const Content = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;

  border-radius: 1rem;
  background: ${(props) => props.theme.colors.white};

  width: 58rem;
  max-height: 90%;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 2rem;
  padding: 2rem 3rem;

  button {
    display: flex;
    align-items: center;

    background-color: ${(props) => props.theme.colors["gray-200"]};
    padding: 0.75rem;
    border: none;
    border-radius: ${(props) => props.theme.borderRadius.Circular};

    color: ${(props) => props.theme.colors["gray-800"]};
  }

  button,
  svg {
    &:hover {
      background-color: ${(props) => props.theme.colors["gray-300"]};

      color: ${(props) => props.theme.colors["gray-900"]};
      transition: all 0.2s;
    }
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
  margin-bottom: 1.5rem;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 2rem 0 1.5rem;
  padding: 0 3rem;

  font-size: ${(props) => props.theme.fontSize.XS};
  color: ${(props) => props.theme.colors["gray-950"]};

  button {
    display: flex;
    align-items: center;

    background-color: transparent;
    border: none;

    padding: 1rem;
    border-radius: ${(props) => props.theme.borderRadius.SM};
    border: 1px solid ${(props) => props.theme.colors["gray-600"]};

    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["gray-950"]};

    label {
      display: flex;
      align-items: center;
    }

    input {
      margin-right: 0.75rem;
      height: 16px;
      width: 16px;

      cursor: pointer;
    }
  }
`;

export const ModalTitle = styled(Dialog.Title)`
  font-size: ${(props) => props.theme.fontSize.MD};
  color: ${(props) => props.theme.colors["gray-950"]};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
`;

interface PermissionsContentProps {
  permissionsLoading: boolean;
}

export const PermissionsContent = styled.div<PermissionsContentProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  padding: 0 3rem 2rem 3rem;

  gap: 1rem;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors["gray-600"]};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors["gray-400"]};
    border-radius: 4px;
  }

  ${(props) =>
    props.permissionsLoading &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;

export const PermissionsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const PermissionType = styled.h4`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  color: ${(props) => props.theme.colors["gray-950"]};

  margin-bottom: 0.75rem;
`;

export const PermissionsViewControl = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;

  padding: 0 3rem;
  border-top: 1px solid ${(props) => props.theme.colors["gray-400"]};

  button {
    margin: 1.5rem 0;
  }
`;

interface MessageProps {
  error: boolean;
}

export const Message = styled.span<MessageProps>`
  text-align: center;
  margin-top: 4px;
  font-weight: ${(props) => props.theme.fontWeight.Semibold};

  color: ${(props) => props.theme.colors["green-800"]};

  ${(props) =>
    props.error &&
    css`
      color: ${props.theme.colors["red-500"]};
    `};
`;

export const PermissionPage = styled.h4`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  color: ${(props) => props.theme.colors["gray-950"]};

  margin-bottom: 0.75rem;
`;

interface ModalButtonProps {
  variant?: "transparent" | "delete";
}

export const ModalDescription = styled(Dialog.Description)<ModalButtonProps>`
  margin-top: 1rem;

  ${(props) =>
    props.variant === "delete" &&
    css`
      color: ${(props) => props.theme.colors["red-700"]};
    `};
`;

export const PermissionsList = styled.ul`
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding-bottom: 2.5rem;

  & > li {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const PermissionItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  input,
  label {
    cursor: pointer;
  }

  input:disabled {
    cursor: not-allowed;
  }
`;

export const ExpandGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-left: 2.125rem;

  & > div:nth-child(2) {
    margin-left: 1.25rem;
  }

  & > div:nth-child(3) {
    margin-left: 2.5rem;
  }
`;
