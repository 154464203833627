import styled from "styled-components";

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;
`;

export const RowsSelectContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;

  span {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors["black-700"]};
  }
`;

export const PaginationContent = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 7.5rem;
    height: 3rem;

    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
    background-color: ${(props) => props.theme.colors.white};

    span {
      font-size: ${(props) => props.theme.fontSize.XXXS};
      font-family: ${(props) => props.theme.fontFamily.Sora};
      color: ${(props) => props.theme.colors["black-700"]};
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3rem;
    height: 3rem;

    background-color: ${(props) => props.theme.colors.white};

    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};

    svg {
      color: ${(props) => props.theme.colors["black-700"]};
    }

    &:not(:disabled):hover {
      filter: brightness(0.9);
      transition: filter 0.2s;
    }
  }
`;
