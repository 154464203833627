import { Fragment, useEffect, useState } from "react";
import * as S from "./styles";

import { AccordionSingleProps } from "@radix-ui/react-accordion";
import { CgTrashEmpty } from "react-icons/cg";
import { GrAdd, GrFormSubtract } from "react-icons/gr";
import { Permission } from "../../../../../../@types/Role";
import { colors } from "../../../../../../styles/themes/default/foundations/colors";

interface AccordionWithSwitchProps extends Omit<AccordionSingleProps, "type"> {
  permission: Permission | null;
  handleExpandPermissions: (
    environment: string,
    menu: string,
    name: string,
  ) => void;
  handleCheckPermissions: (
    environment: string,
    menu: string,
    name: string,
    value: boolean,
  ) => void;
  handleCheckOrRemoveAllPermissions: (
    environment: string,
    menu: string,
    value: boolean,
  ) => void;
}

export function AccordionWithSwitch({
  permission,
  handleExpandPermissions,
  handleCheckPermissions,
  handleCheckOrRemoveAllPermissions,
  ...rest
}: AccordionWithSwitchProps) {
  if (!permission) {
    return null;
  }

  const { menu, name, data, hasPermission, environment } = permission;

  const [allChecked, setAllChecked] = useState(false);

  const hasSelectAllChecked =
    permission.data &&
    permission.data.every(
      (permission) =>
        permission.hasPermission &&
        permission.data &&
        permission.data.every((permission) => permission.hasPermission),
    );

  useEffect(() => {
    if (hasSelectAllChecked) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [permission]);

  return (
    <S.AccordionRoot
      type="single"
      className="AccordionRoot"
      collapsible
      {...rest}
    >
      <S.AccordionItem className="AccordionItem" value={name}>
        <S.AccordionHeader>
          <S.AccordionTrigger>
            <S.SwitchStyled
              onChange={(_, event) => {}}
              checked={hasPermission}
              readOnly
            />
            Access to {menu}
          </S.AccordionTrigger>
        </S.AccordionHeader>
        <S.AccordionContent>
          <S.MassiveActionsWrapper>
            <S.MassiveAction
              onClick={(event) => {
                event.preventDefault();
                handleCheckOrRemoveAllPermissions(environment, menu, true);
              }}
            >
              <input
                type="checkbox"
                checked={allChecked}
                onChange={() =>
                  handleCheckOrRemoveAllPermissions(environment, menu, true)
                }
              />
              Select All
            </S.MassiveAction>
            <S.MassiveAction
              onClick={(event) => {
                event.preventDefault();
                handleCheckOrRemoveAllPermissions(environment, menu, false);
              }}
            >
              <CgTrashEmpty size={24} /> Remove All
            </S.MassiveAction>
          </S.MassiveActionsWrapper>

          <S.Separator />

          <S.PermissionsList>
            {data?.map((child, index) => (
              <Fragment key={child.name}>
                <li>
                  <S.PermissionItem>
                    {child.data?.length ? (
                      child.modal ? (
                        <GrFormSubtract
                          size={12}
                          color={colors["gray-800"]}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleExpandPermissions(
                              child.environment,
                              child.menu,
                              child.name,
                            );
                          }}
                        />
                      ) : (
                        <GrAdd
                          size={12}
                          color={colors["gray-800"]}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleExpandPermissions(
                              child.environment,
                              child.menu,
                              child.name,
                            );
                          }}
                        />
                      )
                    ) : null}

                    <input
                      type="checkbox"
                      id={`permission-${child.name}`}
                      checked={child.hasPermission}
                      onChange={(event) =>
                        handleCheckPermissions(
                          child.environment,
                          child.menu,
                          child.name,
                          event.target.checked,
                        )
                      }
                    />
                    <label htmlFor={`permission-${child.name}`}>
                      {child.name}
                    </label>
                  </S.PermissionItem>

                  {child.modal &&
                    child.data?.map((grandChild) => (
                      <S.ExpandGroup key={grandChild.name}>
                        <S.PermissionItem>
                          <input
                            type="checkbox"
                            id={`permission-${grandChild.name}`}
                            checked={grandChild.hasPermission}
                            onChange={(event) =>
                              handleCheckPermissions(
                                grandChild.environment,
                                grandChild.menu,
                                grandChild.name,
                                event.target.checked,
                              )
                            }
                          />
                          <label htmlFor={`permission-${grandChild.name}`}>
                            {grandChild.name}
                          </label>
                        </S.PermissionItem>
                      </S.ExpandGroup>
                    ))}
                </li>
                {data?.length && index < data.length - 1 && <S.Separator />}
              </Fragment>
            ))}
          </S.PermissionsList>
        </S.AccordionContent>
      </S.AccordionItem>
    </S.AccordionRoot>
  );
}
