import { ReactNode, useEffect, useState } from "react";
import { createContext } from "use-context-selector";
import { routesURL } from "../services/routesUrl";
import { wrapperRequests } from "../services/api";
import { Permissions, Permission, RoleData } from "../@types/Role";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface RolesContextType {
  fetchRole: (roleId: string) => Promise<void>;
  fetchAllPermissions: () => Promise<void>;
  roleData: RoleData;
  loadings: {
    editOrCreateRole: boolean;
    permissions: boolean;
  };
  permissions: Permissions;
  handleExpandPermissions: (
    environment: string,
    menu: string,
    name: string,
  ) => void;
  handleCheckPermissions: (
    environment: string,
    menu: string,
    name: string,
    value: boolean,
  ) => void;
  handleCheckOrRemoveAllPermissions: (
    environment: string,
    menu: string,
    value: boolean,
  ) => void;
  handleUpdateRoleName: (name: string) => void;
  handleEditRole: (roleId: string) => Promise<void>;
  handleCreateRole: () => Promise<void>;
}

interface RolesProviderProps {
  children: ReactNode;
}

export const RolesContext = createContext({} as RolesContextType);

export function RolesProvider({ children }: RolesProviderProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [loadings, setLoadings] = useState({
    editOrCreateRole: false,
    permissions: false,
  });
  const [id, setId] = useState("");
  const [roleData, setRoleData] = useState<RoleData>({
    name: "",
    permissions: [],
    updateUsers: true,
  });
  const [permissions, setPermissions] = useState<Permissions>({
    WEB: null,
    APP: null,
  });

  const handleLoading = (
    loadingType: keyof typeof loadings,
    value: boolean,
  ) => {
    setLoadings((state) => ({
      ...state,
      [loadingType]: value,
    }));
  };

  const fetchRole = async (roleId: string) => {
    handleLoading("permissions", true);
    setId(roleId);

    try {
      const { data } = await wrapperRequests(
        routesURL.settings.roles.getRole(roleId),
        "GET",
      );

      setRoleData((state) => ({
        ...state,
        name: data.name,
      }));
      handlePermissions(data.permissions);
    } catch (error) {
      console.error(error);
    } finally {
      handleLoading("permissions", false);
    }
  };

  const fetchAllPermissions = async () => {
    handleLoading("permissions", true);
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.users.getAllPermissions,
        "GET",
      );

      handlePermissions(data.permissions);
    } catch (error) {
      console.error(error);
    } finally {
      handleLoading("permissions", false);
    }
  };

  const handleEditRole = async (roleId: string) => {
    handleLoading("editOrCreateRole", true);
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.roles.editRole(roleId),
        "PUT",
        {
          data: roleData,
        },
      );

      toast.success("Role edited successfully!", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate("/settings/roles/");
    } catch (error) {
      console.error(error);
    } finally {
      handleLoading("editOrCreateRole", false);
    }
  };

  const handleCreateRole = async () => {
    handleLoading("editOrCreateRole", true);
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.roles.createRole,
        "POST",
        {
          data: roleData,
        },
      );

      toast.success("Role created successfully!", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate("/settings/roles/");
    } catch (error) {
      console.error(error);
    } finally {
      handleLoading("editOrCreateRole", false);
    }
  };

  const handleUpdateRoleName = (name: string) => {
    setRoleData((state) => ({
      ...state,
      name,
    }));
  };

  const handlePermissions = (permissions: Permission[]) => {
    const findChildren = (parentPermission: Permission): Permission[] =>
      permissions
        .filter(
          (permission) =>
            permission.parentPermission === parentPermission.permissionString,
        )
        .map((permission) => ({
          ...permission,
          modal: false,
          data: findChildren(permission),
        }));

    const rootPermissions = permissions.filter(
      (permission) => permission.parentPermission === "",
    );

    const organizedPermissions = rootPermissions.map((permission) => ({
      ...permission,
      modal: false,
      data: findChildren(permission),
    }));

    const { WEB, APP } = {
      WEB: organizedPermissions.filter(
        (permission) => permission.environment === "WEB",
      ),
      APP: organizedPermissions.filter(
        (permission) => permission.environment === "APP",
      ),
    };

    const checkHasPermission = APP.some(
      (permission) => permission.hasPermission,
    );

    const appPermissions = {
      name: "App Permissions",
      environment: "APP",
      parentPermission: "",
      permissionString: "app_permissions",
      menu: "App",
      hasPermission: checkHasPermission,
      data: APP.map((permissions) => ({
        ...permissions,
        parentPermission: "app_permissions",
      })),
      modal: false,
    };

    setPermissions({
      APP: {
        App: appPermissions,
      },
      WEB: {
        Inventory: findByMenu(WEB, "Inventory"),
        Report: findByMenu(WEB, "Report"),
        Settings: findByMenu(WEB, "Settings"),
      },
    });

    function findByMenu(permissions: Permission[], menu: string) {
      return permissions.find((permission) => permission.menu === menu) ?? null;
    }
  };

  const handleExpandPermissions = (
    environment: string,
    menu: string,
    name: string,
  ) => {
    setPermissions((state) => {
      const currentEnvironment = state[environment];

      if (currentEnvironment) {
        const currentMenuState = currentEnvironment[menu];

        if (currentMenuState && currentMenuState.data) {
          const updatedData = currentMenuState.data.map((child: Permission) =>
            child && child.name === name
              ? { ...child, modal: !child.modal }
              : child,
          );

          return {
            ...state,
            [environment]: {
              ...currentEnvironment,
              [menu]: {
                ...currentMenuState,
                data: updatedData,
              },
            },
          };
        }
      }
      return state;
    });
  };

  const handleCheckPermissions = (
    environment: string,
    menu: string,
    name: string,
    value: boolean,
  ) => {
    setPermissions((state) => {
      const currentEnvironment = state[environment];

      if (currentEnvironment) {
        const currentMenuState = currentEnvironment[menu];

        if (currentMenuState && currentMenuState.data) {
          const updatedData = currentMenuState.data.map((child) => {
            if (child.name === name) {
              return {
                ...child,
                hasPermission: value,
                data: child.data?.map((grandChild) => ({
                  ...grandChild,
                  hasPermission: value,
                })),
              };
            } else {
              return {
                ...child,
                data: (child.data || []).map((grandChild) =>
                  grandChild.name === name
                    ? {
                        ...grandChild,
                        hasPermission: value,
                      }
                    : grandChild,
                ),
              };
            }
          });

          const updatedDataWithParentCheck = updatedData.map((child) => {
            if (
              child.data &&
              child.data.some(
                (grandChild) =>
                  grandChild.name === name && !child.hasPermission,
              )
            ) {
              return {
                ...child,
                hasPermission: value,
              };
            }
            return child;
          });

          const hasAnyPermissionChecked = updatedData.some(
            (child) =>
              child.hasPermission ||
              child.data?.some((grandChild) => grandChild.hasPermission),
          )
            ? true
            : false;

          return {
            ...state,

            [environment]: {
              ...currentEnvironment,
              [menu]: {
                ...currentMenuState,
                hasPermission: hasAnyPermissionChecked,
                data: updatedDataWithParentCheck,
              },
            },
          };
        }
      }
      return state;
    });
  };

  const handleCheckOrRemoveAllPermissions = (
    environment: string,
    menu: string,
    value: boolean,
  ) => {
    setPermissions((state) => {
      const currentEnvironment = state[environment];

      if (currentEnvironment) {
        const currentMenuState = currentEnvironment[menu];

        if (currentMenuState && currentMenuState.data) {
          const updatedData = currentMenuState.data.map((child) => {
            return {
              ...child,
              hasPermission: value,
              data: child.data?.map((grandChild) => ({
                ...grandChild,
                hasPermission: value,
              })),
            };
          });

          const hasAnyPermissionChecked = updatedData.some(
            (child) =>
              child.hasPermission ||
              child.data?.some((grandChild) => grandChild.hasPermission),
          )
            ? true
            : false;

          return {
            ...state,
            [environment]: {
              ...currentEnvironment,
              [menu]: {
                ...currentMenuState,
                hasPermission: hasAnyPermissionChecked,
                data: updatedData,
              },
            },
          };
        }
      }
      return state;
    });
  };

  const handlePickPermissions = () => {
    const pickPermissions = [
      permissions.WEB && permissions.WEB.Inventory,
      permissions.WEB && permissions.WEB.Settings,
      permissions.WEB && permissions.WEB.Report,
      permissions.APP && permissions.APP.App,
    ].filter((permission) => permission?.hasPermission);

    const pickPermissionsParent = pickPermissions
      .filter(
        (permission) =>
          permission?.permissionString !== "app_permissions" &&
          permission?.permissionString,
      )
      .map((permission) => permission?.permissionString);

    const pickPermissionsChild = pickPermissions
      .flatMap(
        (permission) =>
          permission?.data
            ?.filter((permission) => permission.hasPermission)
            .map((permission) => permission?.permissionString),
      )
      .filter((permissionString) => permissionString) as string[];

    const pickPermissionsGranChild = pickPermissions
      .flatMap(
        (permission) =>
          permission?.data?.flatMap(
            (permission) =>
              permission.data
                ?.filter((permission) => permission.hasPermission)
                .map((permission) => permission?.permissionString),
          ),
      )
      .filter((permissionString) => permissionString) as string[];

    const allPermissionStrings = [
      ...(pickPermissionsParent || []),
      ...pickPermissionsChild,
      ...pickPermissionsGranChild,
    ];

    setRoleData((state) => ({
      ...state,
      permissions: allPermissionStrings,
    }));
  };

  useEffect(() => {
    handlePickPermissions();
  }, [permissions]);

  useEffect(() => {
    if (location.pathname !== `/settings/roles/${id}`) {
      setRoleData((state) => ({
        ...state,
        name: "",
        permissions: [],
      }));
    }
  }, [location.pathname]);

  return (
    <RolesContext.Provider
      value={{
        fetchRole,
        fetchAllPermissions,
        loadings,
        roleData,
        permissions,
        handleExpandPermissions,
        handleCheckPermissions,
        handleCheckOrRemoveAllPermissions,
        handleUpdateRoleName,
        handleEditRole,
        handleCreateRole,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
}
