import React from "react";
import * as S from "./styles";
import { IoSearchOutline } from "react-icons/io5";
import { LuArrowDownUp } from "react-icons/lu";
import { HiOutlineFilter } from "react-icons/hi";
import { IoFilterCircleOutline } from "react-icons/io5";

interface StyledInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onToggleOrder: () => void;
  searchIcon?: boolean;
  onClick?: () => void;
  onPressEnter?: () => void;
}

export function StyledInput({
  searchIcon,
  onClick,
  onPressEnter,
  onToggleOrder,
  ...rest
}: StyledInputProps) {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && onPressEnter) {
      onPressEnter();
    }
  };

  return (
    <S.InputContainer>
      <S.InputWrapper>
        {searchIcon && <IoSearchOutline size={24} onClick={onClick} />}
        <input type="text" onKeyDown={handleKeyDown} {...rest} />
        <div className="icons-right">
          <LuArrowDownUp
            size={24}
            onClick={onToggleOrder}
            style={{ cursor: "pointer" }}
            title="Sort" 
          />
          {/* <HiOutlineFilter size={24} /> */}
          {/* <IoFilterCircleOutline size={24} /> */}
        </div>
      </S.InputWrapper>
    </S.InputContainer>
  );
}

export default StyledInput;
