import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createContext } from "use-context-selector";
import { CreateUserData } from "../@types/User";

import { api } from "../services/api";

interface UsersContextType {
  loading: boolean;
  error: string;
  selectSortBy: (value: string) => void;
  createUser: (userData: CreateUserData) => Promise<void>;
}

interface UsersProviderProps {
  children: ReactNode;
}

export const UsersContext = createContext({} as UsersContextType);

export function UsersProvider({ children }: UsersProviderProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sortBy, setSortBy] = useState("");

  const navigate = useNavigate();

  async function createUser(userData: CreateUserData) {
    try {
      setLoading(true);

      await api.post("api/users", userData);

      setError("");

      toast.success(`🚴‍♂️ ${userData.name} registered successfully!`, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoading(false);

      navigate("/settings/users", { state: { reload: true } });
    } catch (err: any) {
      setLoading(false);
    }
  }

  const selectSortBy = (value: string) => {
    setSortBy(value);
  };

  return (
    <UsersContext.Provider
      value={{
        error,
        selectSortBy,
        loading,
        createUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
