import * as S from "./styles";
import { PageHeader } from "../../../../../components/Headers/PageHeader";
import { SwitchInput } from "../../../../../components/Switch/SwitchInput";
import { Button } from "../../../../../components/Button";
import { Table } from "../../../../../components/New/Table";
import { ColumnDef } from "@tanstack/react-table";
import { Input } from "../../../../../components/New/Input";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IPrinter, IStore } from "../../../../../@types/Stores";
import { Modal } from "../../../../../components/New/Modal";
import { ReduceString } from "../../../../../shared/ReduceString";
import { PrintersModalContent } from "../components/PrintersModalContent";
import { StoreSchema, TStoreSchema } from "./schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { StoresContext } from "../../../../../contexts/StoresContext";
import { useContextSelector } from "use-context-selector";
import {
  hasUnsavedChanges,
  useLeavePageConfirm,
} from "../../../../../shared/HasUnsavedChanges";

type TaskData = {
  task: string;
  status: {
    id: number;
    name: string;
    color: string;
  };
  due: Date | null;
  notes: string;
};

type InitialValues = {
  active: boolean;
  displayName: string;
  channelAdvisorDistributionCenterID: number | undefined;
  channelAdvisorDistributionCenterCode: string | undefined;
  geolocationLat: string | undefined;
  geolocationLon: string | undefined;
  shipstationTagIdShipping: string | undefined;
  shipstationTagIdDelivery: string | undefined;
  shipstationTagIdStorePickup: string | undefined;
  shipstationCompanyName: string | undefined;
};

const columns: ColumnDef<TaskData>[] = [
  {
    accessorKey: "id",
    header: "ID",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "serialNumber",
    header: "Serial number",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "tag",
    header: "Tag",
    size: 170,
    cell: (props) => (
      <p title={props.getValue<string>()}>
        {ReduceString(props.getValue<string>(), 15)}
      </p>
    ),
  },
  {
    accessorKey: "active",
    size: 120,
    header: "Active",
    cell: (props) => (
      <S.RowValueContainer>
        {props.getValue() ? "On" : "Off"}
      </S.RowValueContainer>
    ),
  },
  {
    accessorKey: "activeForOrderItem",
    size: 120,
    header: "Order item",
    cell: (props) => (
      <S.RowValueContainer>
        {props.getValue() ? "On" : "Off"}
      </S.RowValueContainer>
    ),
  },
  {
    accessorKey: "size",
    size: 190,
    header: "Size",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "activeForProductPrice",
    size: 140,
    header: "Product Price",
    cell: (props) => (
      <S.RowValueContainer>
        {props.getValue() ? "On" : "Off"}
      </S.RowValueContainer>
    ),
  },
  {
    accessorKey: "activeForWarehouseLabel",
    size: 170,
    header: "Warehouse Label",
    cell: (props) => (
      <S.RowValueContainer>
        {props.getValue() ? "On" : "Off"}
      </S.RowValueContainer>
    ),
  },
  {
    accessorKey: "activeForTransferCompleteLabel",
    size: 140,
    header: "Transfer label",
    cell: (props) => (
      <S.RowValueContainer>
        {props.getValue() ? "On" : "Off"}
      </S.RowValueContainer>
    ),
  },
  {
    accessorKey: "activeForManualTransferCompleteLabel",
    size: 210,
    header: "Manual Transfer Label",
    cell: (props) => (
      <S.RowValueContainer>
        {props.getValue() ? "On" : "Off"}
      </S.RowValueContainer>
    ),
  },
  {
    accessorKey: "",
    size: 100,
    header: "Actions",
    cell: () => (
      <S.IconContainer title="Edit">
        <MdOutlineModeEditOutline size={24} />
      </S.IconContainer>
    ),
  },
];

export function RegisterStore() {
  const [initialValues, setInicialValues] = useState({} as InitialValues);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    watch,
  } = useForm<TStoreSchema>({
    resolver: zodResolver(StoreSchema),
  });

  const { editStore, fetchStore, selectedStore, setSelectedStore } =
    useContextSelector(StoresContext, (context) => {
      return context;
    });

  const location = useLocation();
  const navigate = useNavigate();
  const storeId = location.pathname.split("/").pop();
  const active = watch("active");
  const currentValues = watch();

  const handleRegisterStore = async (data: TStoreSchema) => {
    setIsLoading(true);

    const dataWithValidValues = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== "" && value !== 0),
    );

    await editStore(dataWithValidValues);

    navigate("/settings/stores");
  };

  const getStore = async (storeId: string) => {
    const response = await fetchStore(storeId);

    setInicialValues({
      active: response.active || false,
      displayName: response.displayName || "",
      channelAdvisorDistributionCenterID:
        response.channelAdvisorDistributionCenterID,
      channelAdvisorDistributionCenterCode:
        response.channelAdvisorDistributionCenterCode || "",
      geolocationLat: response.geolocationLat?.toString() || "",
      geolocationLon: response.geolocationLon?.toString() || "",
      shipstationTagIdShipping:
        response.shipStationProperties?.tagIdShopShipping || "",
      shipstationTagIdDelivery:
        response.shipStationProperties?.tagIdDelivery || "",
      shipstationTagIdStorePickup:
        response.shipStationProperties?.tagIdStorePickup || "",
      shipstationCompanyName: response.shipStationProperties?.companyName || "",
    });

    reset({
      active: response.active || false,
      displayName: response.displayName,
      channelAdvisorDistributionCenterID:
        response.channelAdvisorDistributionCenterID,
      channelAdvisorDistributionCenterCode:
        response.channelAdvisorDistributionCenterCode,
      geolocationLat: response.geolocationLat?.toString(),
      geolocationLon: response.geolocationLon?.toString(),
      shipstationTagIdShipping:
        response.shipStationProperties?.tagIdShopShipping,
      shipstationTagIdDelivery: response.shipStationProperties?.tagIdDelivery,
      shipstationTagIdStorePickup:
        response.shipStationProperties?.tagIdStorePickup,
      shipstationCompanyName: response.shipStationProperties?.companyName || "",
    });
  };

  useEffect(() => {
    if (storeId) {
      getStore(storeId);
    }

    return () => {
      setSelectedStore({} as IStore);
    };
  }, [storeId]);

  useLeavePageConfirm(hasUnsavedChanges(initialValues, currentValues));

  return (
    <S.RegisterStoreContainer onSubmit={handleSubmit(handleRegisterStore)}>
      <PageHeader
        title="Edit Store"
        customBreadcrumbString={selectedStore.displayName}
      />

      <S.RegisterStoreForm>
        <S.FieldsContainer>
          <SwitchInput
            title="Status"
            label={active ? "Active" : "Disabled"}
            checked={!!active}
            onChange={() => setValue("active", !active)}
          />
        </S.FieldsContainer>

        <h3>Store Information</h3>

        <S.FieldsContainer>
          <Input
            title="Store Name *"
            defaultValue={selectedStore.name}
            disabled
          />
          <Input
            title="Display Name *"
            defaultValue={selectedStore.displayName}
            {...register("displayName")}
            errorMessage={errors.displayName?.message}
          />
          <Input
            title="Store ID"
            defaultValue={selectedStore.shopMetadata?.id}
            disabled
          />
        </S.FieldsContainer>

        <Input
          title="Address *"
          defaultValue={selectedStore.address1}
          disabled
        />

        <S.FieldsContainer>
          <Input
            title="POS Account Name"
            defaultValue={selectedStore.tenantName}
            disabled
          />
          <Input
            title="POS Account Number"
            defaultValue={selectedStore?.tenant?.lightspeedAccountId}
            disabled
          />
          <Input
            title="Channel Advisor Id *"
            defaultValue={selectedStore.channelAdvisorDistributionCenterID}
            {...register("channelAdvisorDistributionCenterID")}
            errorMessage={errors.channelAdvisorDistributionCenterID?.message}
          />
        </S.FieldsContainer>

        <S.FieldsContainer>
          <Input
            title="Channel Advisor Code *"
            defaultValue={selectedStore.channelAdvisorDistributionCenterCode}
            {...register("channelAdvisorDistributionCenterCode")}
            errorMessage={errors.channelAdvisorDistributionCenterCode?.message}
          />
        </S.FieldsContainer>

        <S.ButtonAlignContainer>
          <Button isLoading={isLoading}>Submit</Button>
        </S.ButtonAlignContainer>

        <S.Separator style={{ margin: "2.5rem 0" }} />

        <h3>Location Coordinates</h3>

        <S.FieldsContainer>
          <Input
            title="Geolocation Latitude *"
            defaultValue={selectedStore.geolocationLat}
            {...register("geolocationLat")}
            errorMessage={errors.geolocationLat?.message}
          />

          <Input
            title="Geolocation Longitude *"
            defaultValue={selectedStore.geolocationLon}
            {...register("geolocationLon")}
            errorMessage={errors.geolocationLon?.message}
          />
        </S.FieldsContainer>

        <S.ButtonAlignContainer>
          <Button isLoading={isLoading}>Submit</Button>
        </S.ButtonAlignContainer>

        <S.Separator style={{ margin: "2.5rem 0" }} />

        <h3>Shipping Information</h3>

        <S.FieldsContainer>
          <Input
            title="ShipStation Properties Tag Id Shop Shipping"
            defaultValue={
              selectedStore.shipStationProperties?.tagIdShopShipping
            }
            {...register("shipstationTagIdShipping")}
            errorMessage={errors.shipstationTagIdShipping?.message}
          />
          <Input
            title="ShipStation Properties Tag Id Delivery"
            defaultValue={selectedStore.shipStationProperties?.tagIdDelivery}
            {...register("shipstationTagIdDelivery")}
            errorMessage={errors.shipstationTagIdDelivery?.message}
          />
          <Input
            title="ShipStation Properties Tag Id Store Pickup"
            defaultValue={selectedStore.shipStationProperties?.tagIdStorePickup}
            {...register("shipstationTagIdStorePickup")}
            errorMessage={errors.shipstationTagIdStorePickup?.message}
          />
        </S.FieldsContainer>

        <S.FieldsContainer>
          <Input
            title="ShipStation Properties Company Name"
            defaultValue={selectedStore.shipStationProperties?.companyName}
            {...register("shipstationCompanyName")}
            errorMessage={errors.shipstationCompanyName?.message}
          />
        </S.FieldsContainer>

        <S.FieldsContainer>
          <Input
            title="Zapiet Location IDs"
            defaultValue={selectedStore.shipStationProperties?.zapietLocationIDs.join(
              ", ",
            )}
            disabled
          />
          <Input title="Zapiet Pickup" disabled />
          <Input title="Zapiet Delivery" disabled />
        </S.FieldsContainer>

        <S.ButtonAlignContainer>
          <Button isLoading={isLoading}>Submit</Button>
        </S.ButtonAlignContainer>

        <S.Separator style={{ margin: "2.5rem 0" }} />

        <h3>Warehouse Information</h3>

        <S.FieldsContainer>
          <Input
            title="WarehouseShop ShopID *"
            defaultValue={selectedStore.warehouseShop?.shopID}
            disabled
          />
          <Input
            title="WarehouseShop Tenant Name *"
            defaultValue={selectedStore.warehouseShop?.tenantName}
            disabled
          />
        </S.FieldsContainer>
      </S.RegisterStoreForm>

      <S.Separator style={{ margin: "2rem 0" }} />

      <S.PrinterTitleAlign style={{ margin: 0, marginBottom: 32 }}>
        <h3 style={{ margin: 0 }}>Printer Information</h3>

        <Modal
          title="Printer Information"
          customButtons
          modalContent={PrintersModalContent({
            storeData: selectedStore,
            printer: {} as IPrinter,
          })}
        >
          <Button isLoading={isLoading}>Add a new printer</Button>
        </Modal>
      </S.PrinterTitleAlign>

      {selectedStore?.printers && selectedStore?.printers[0] && (
        <Table
          columns={columns}
          columnToFilter="task"
          data={selectedStore?.printers}
          completeData={selectedStore}
          modalOnClick
        />
      )}

      <S.Separator style={{ margin: "2.5rem 0" }} />

      <h3>Shop Metadata</h3>

      <S.FieldsContainer>
        <Input
          title="Shops._id"
          defaultValue={selectedStore.shipstationShopMetadata?.id}
          disabled
        />
        <Input
          title="Shipstation-shop-metadata Region Name"
          defaultValue={selectedStore.shipstationShopMetadata?.regionName}
          disabled
        />
        <Input
          title="Shipstation-shop-metadata UserId"
          defaultValue={selectedStore.shipstationShopMetadata?.userId}
          disabled
        />
      </S.FieldsContainer>

      <S.FieldsContainer>
        <Input
          title="Shipstation-shop-metadata Shops"
          defaultValue={selectedStore.shipstationShopMetadata?.shops.join(", ")}
          disabled
        />
      </S.FieldsContainer>

      <S.ButtonAlignContainer>
        <Button isLoading={isLoading}>Submit</Button>
      </S.ButtonAlignContainer>
    </S.RegisterStoreContainer>
  );
}
