import styled, { css } from "styled-components";

export const CreateStoreStepsContainer = styled.header`
  display: flex;

  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};

  margin-bottom: 1rem;
`;

interface StepsButtonProps {
  active?: boolean;
}

export const StepsButton = styled.button<StepsButtonProps>`
  background: transparent;
  border: none;

  padding: 1rem 2rem;

  font-family: ${(props) => props.theme.fontFamily.Sora};
  color: ${(props) => props.theme.colors["gray-800"]};

  ${(props) =>
    props.active &&
    css`
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      color: ${(props) => props.theme.colors["blue-600"]};
      border-bottom: 2px solid ${(props) => props.theme.colors["blue-600"]};
    `};
`;
