import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const CardButtonContainer = styled(NavLink)`
  display: flex;

  padding: 2rem 1.5rem 1.625rem;

  width: 22rem;
  height: 11rem;

  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.MD};
  text-decoration: none;

  gap: 1rem;

  &:hover {
    outline: 1px solid ${(props) => props.theme.colors["blue-600"]};
    transition: outline 0.2s;
  }
`;

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4.5rem;
  width: 4.5rem;
  border-radius: ${(props) => props.theme.borderRadius.Circular};

  background-color: ${(props) => props.theme.colors["blue-200"]};

  svg {
    height: 2.5rem;
    width: 2.5rem;
    color: ${(props) => props.theme.colors["blue-600"]};
  }
`;

export const CardButtonInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  max-width: 13rem;
`;

export const CardButtonTitle = styled.h2`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  line-height: ${(props) => props.theme.lineHeight.MD};
  color: ${(props) => props.theme.colors["gray-900"]};

  margin-bottom: 0.25rem;
`;

export const CardButtonText = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  line-height: ${(props) => props.theme.lineHeight.LG};
  color: ${(props) => props.theme.colors["gray-900"]};
`;
