import { useState } from "react";
import { IoMdMail } from "react-icons/io";
import ReactLoading from "react-loading";
import { ForgotPasswordUserDataProps } from "..";
import { SignInValidationMessage } from "../../components/SignInValidationMessage";
import { Input, InputContainer, SignInButton, Title } from "../../styles";

import { wrapperRequests } from "../../../../services/api";

import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";

export function CheckEmail({ setUserData }: ForgotPasswordUserDataProps) {
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  async function handleCheckEmail() {
    setIsLoading(true);
    setError("");
    const emailRegex = /^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

    try {
      if (!emailRegex.test(userEmail)) {
        setError("Invalid email");
        throw Error("Invalid email");
      }

      await wrapperRequests(
        routesURL.auth.passwordRecoverValidateEmail,
        "POST",
        {
          data: {
            email: userEmail,
          },
        },
      );


      setUserData((currentValue) => {
        return {
          ...currentValue,
          email: userEmail,
        };
      });
    } catch (error: any) {
      setIsLoading(false);
      error?.response?.status === 400 &&
        setError("Email not found in our database");

      throw Error(error);
    }
  }

  return (
    <S.CheckEmailContainer>
      <span>
        We will help you to recover your access, <br />
        enter your username or access email?
      </span>

      <Title>Email</Title>
      <InputContainer className='container' filled={!!userEmail}>
        <IoMdMail />
        <Input
          type='email'
          name='email'
          value={userEmail}
          onChange={(value) => {
            setUserEmail(value.target.value);
          }}
        />
      </InputContainer>

      <SignInButton
        type='submit'
        disabled={isLoading}
        onClick={handleCheckEmail}
      >
        {isLoading ? (
          <ReactLoading height={25} width={25} type={"spin"} color='#ffffff' />
        ) : (
          "Recover Password"
        )}
      </SignInButton>

      {error && <SignInValidationMessage message={error} />}
    </S.CheckEmailContainer>
  );
}
