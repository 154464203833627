import { ReactNode, useEffect, useState } from "react";
import { createContext } from "use-context-selector";
import {
  IReportExploreFilters,
  IReportExploreFiltersToSelect,
  IReportExploreSelectedFilter,
  IReportExploreSelectedFilterData,
} from "../@types/ReportExplore";
import { wrapperRequests } from "../services/api";
import { routesURL } from "../services/routesUrl";

interface ExploreItemsContextType {
  allSelectedFilters: IReportExploreFilters[];
  changeRowLimit: (value: number) => void;
  exploreItemsFilters: IReportExploreFilters[];
  exploreItemsLoading: ExploreItemsLoadingProps;
  exploreItemsSelectFilters: (
    collection: string,
    selectedFilter: IReportExploreFiltersToSelect,
  ) => void;
  exploreItemsError: ExploreItemsErrorProps;
  fetchExploreItems: () => void;
  fetchExploreItemsCSV: () => void;
  filtersBodyApi: IReportExploreSelectedFilter[];
  handleDateFilterModal: (
    collection?: string,
    key?: string,
    id?: string,
  ) => void;
  insertFiltersValues: (
    collection: string,
    filter: IReportExploreSelectedFilterData,
  ) => void;
  loadExploreItemsFilters: () => void;
  tableData: any[];
}

interface ExploreItemsContextProviderProps {
  children: ReactNode;
}

interface ExploreItemsLoadingProps {
  type: "filters" | "data" | "csv";
  isLoading: boolean;
}

interface ExploreItemsErrorProps {
  type: "csv";
  message: string;
}

export const ExploreItemsContext = createContext({} as ExploreItemsContextType);

export function ExploreItemsContextProvider({
  children,
}: ExploreItemsContextProviderProps) {
  const [exploreItemsFilters, setExploreItemsFilters] = useState<
    IReportExploreFilters[]
  >([]);
  const [allSelectedFilters, setAllSelectedFilters] = useState<
    IReportExploreFilters[]
  >([]);
  const [filtersBodyApi, setFiltersBodyApi] = useState<
    IReportExploreSelectedFilter[]
  >([]);
  const [exploreItemsLoading, setExploreItemsLoading] =
    useState<ExploreItemsLoadingProps>({
      type: "filters",
      isLoading: false,
    });
  const [exploreItemsError, setExploreItemsError] =
    useState<ExploreItemsErrorProps>({
      type: "csv",
      message: "",
    });
  const [tableData, setTableData] = useState<any>([]);
  const [rowLimit, setRowLimit] = useState(10);

  function changeRowLimit(value: number) {
    setRowLimit(value);
  }

  async function loadExploreItemsFilters() {
    setExploreItemsLoading({
      type: "filters",
      isLoading: true,
    });
    try {
      const { data } = await wrapperRequests(
        routesURL.report.explore.getFilters,
        "POST",
      );

      setExploreItemsFilters(data);
      setExploreItemsLoading({
        type: "filters",
        isLoading: false,
      });
    } catch (error) {
      setExploreItemsLoading({
        type: "filters",
        isLoading: false,
      });
      throw Error(String(error));
    }
  }

  function handleDateFilterModal(
    collection?: string,
    key?: string,
    id?: string,
  ) {
    setAllSelectedFilters((state) => {
      const updatedSelectedFilters = state.map((item) => {
        if (item.collection === collection) {
          const currentFilter = item.filters.map((filter) => {
            if (filter.key === key && id === "1") {
              return {
                ...filter,
                modal: {
                  inputStart: !filter.modal?.inputStart,
                  inputEnd: false,
                  inputSingleDate: false,
                },
              };
            } else if (filter.key === key && id === "2") {
              return {
                ...filter,
                modal: {
                  inputStart: false,
                  inputEnd: !filter.modal?.inputEnd,
                  inputSingleDate: false,
                },
              };
            } else if (filter.key === key && id === "3") {
              return {
                ...filter,
                modal: {
                  inputStart: false,
                  inputEnd: false,
                  inputSingleDate: !filter.modal?.inputSingleDate,
                },
              };
            }
            return filter;
          });

          return { ...item, filters: currentFilter };
        } else {
          return item;
        }
      });

      return updatedSelectedFilters;
    });
  }

  function exploreItemsSelectFilters(
    collection: string,
    selectedFilter: IReportExploreFiltersToSelect,
  ) {
    const existingCollectionIndex = allSelectedFilters.findIndex(
      (item) => item.collection === collection,
    );
    if (existingCollectionIndex !== -1) {
      const updatedFilters = allSelectedFilters[
        existingCollectionIndex
      ].filters.some((filter) => filter.key === selectedFilter.key)
        ? allSelectedFilters[existingCollectionIndex].filters.filter(
            (filter) => filter.key !== selectedFilter.key,
          )
        : [
            ...allSelectedFilters[existingCollectionIndex].filters,
            selectedFilter,
          ];

      const updatedCollection = {
        ...allSelectedFilters[existingCollectionIndex],
        filters: updatedFilters,
      };

      const updatedState = allSelectedFilters.map((item, index) =>
        index === existingCollectionIndex ? updatedCollection : item,
      );

      setAllSelectedFilters(updatedState);
    } else {
      const newCollection = {
        collection: collection,
        filters: [selectedFilter],
      };

      setAllSelectedFilters((currentState) => {
        return [...currentState, newCollection];
      });
    }
  }

  function insertFiltersValues(
    collection: string,
    filterData?: IReportExploreSelectedFilterData,
  ) {
    const updatedState = [...filtersBodyApi];

    const existingFilterIndex = updatedState.findIndex(
      (item) => item.collection === collection,
    );

    if (existingFilterIndex !== -1) {
      if (filterData) {
        const existingFilters = updatedState[existingFilterIndex].filters || [];
        const existingFilterIndexToUpdate = existingFilters.findIndex(
          (filter) => filter.filter === filterData.filter,
        );

        if (filterData.operator === "") {
          if (existingFilterIndexToUpdate !== -1) {
            existingFilters.splice(existingFilterIndexToUpdate, 1);
          }
        } else {
          if (filterData.operator === "between") {
            const existingFilterToUpdate = existingFilters.find(
              (filter) => filter.filter === filterData.filter,
            );
            if (existingFilterToUpdate) {
              const updatedFilter = {
                ...existingFilterToUpdate,
                operator: filterData.operator,
                start: filterData.start ?? existingFilterToUpdate.start,
                end: filterData.end ?? existingFilterToUpdate.end,
              };
              delete updatedFilter.value;
              existingFilters[existingFilterIndexToUpdate] = updatedFilter;
            } else {
              updatedState[existingFilterIndex] = {
                ...updatedState[existingFilterIndex],
                filters: [...existingFilters, filterData],
              };
            }
          } else {
            if (existingFilterIndexToUpdate !== -1) {
              existingFilters[existingFilterIndexToUpdate] = filterData;
            } else {
              updatedState[existingFilterIndex] = {
                ...updatedState[existingFilterIndex],
                filters: [...existingFilters, filterData],
              };
            }
          }
        }
      }
    } else {
      if (filterData && filterData.operator !== "") {
        const newFilter: IReportExploreSelectedFilter = {
          collection,
          filters: [filterData],
        };
        updatedState.push(newFilter);
      }
    }

    updatedState.forEach((item) => {
      if (item.filters && item.filters.length === 0) {
        delete item.filters;
      }
    });

    setFiltersBodyApi(updatedState);
  }

  function insertFieldsValues(allSelectedFiltersProp: IReportExploreFilters[]) {
    const selectedFilters: IReportExploreSelectedFilter[] = [];

    allSelectedFiltersProp.forEach((filtersObj, index) => {
      const fieldsArray: string[] = filtersObj.filters.map(
        (filter) => filter.key,
      );
      const newSelectedFilter: IReportExploreSelectedFilter = {
        collection: filtersObj.collection,
        fields: fieldsArray,
      };

      selectedFilters.push(newSelectedFilter);
    });

    const selectedFields = selectedFilters.filter(
      (filterObj) => filterObj.fields && filterObj.fields.length > 0,
    );

    selectedFields.forEach((selectedFilter) => {
      const matchingFilter = filtersBodyApi.find(
        (filter) => filter.collection === selectedFilter.collection,
      );

      if (matchingFilter) {
        selectedFilter.filters = matchingFilter.filters;
      }
    });

    selectedFields.forEach((selectedFilter) => {
      selectedFilter.filters =
        selectedFilter.filters &&
        selectedFilter.filters.filter(
          (filter) =>
            selectedFilter.fields &&
            selectedFilter.fields.includes(filter.filter),
        );
    });

    setFiltersBodyApi(selectedFields);
  }

  async function fetchExploreItems() {
    setExploreItemsLoading({
      type: "data",
      isLoading: true,
    });

    try {
      const response = await wrapperRequests(
        routesURL.report.explore.getExploreItems,
        "POST",
        {
          data: {
            filter: {
              collections: [...filtersBodyApi],
            },
            limit: rowLimit,
          },
        },
      );

      setTableData(response.data as []);
      setExploreItemsLoading({
        type: "data",
        isLoading: false,
      });
    } catch (error) {
      setExploreItemsLoading({
        type: "data",
        isLoading: false,
      });
      throw Error(String(error));
    }
  }

  async function fetchExploreItemsCSV() {
    setExploreItemsLoading({
      type: "csv",
      isLoading: true,
    });

    function padWithZero(num: number) {
      return num < 10 ? `0${num}` : `${num}`;
    }

    function formatDate(date: Date) {
      const year = date.getFullYear();
      const month = padWithZero(date.getMonth() + 1);
      const day = padWithZero(date.getDate());
      const hours = padWithZero(date.getHours());
      const minutes = padWithZero(date.getMinutes());
      const seconds = padWithZero(date.getSeconds());
      return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    }

    function generateReportFileName() {
      const currentDate = new Date();
      const formattedDate = formatDate(currentDate);
      return `report_${formattedDate}.csv`;
    }

    try {
      const response = await wrapperRequests(
        routesURL.report.explore.getExploreItemsCSV,
        "POST",
        {
          data: {
            filter: {
              collections: [...filtersBodyApi],
            },
            limit: 0,
          },
          responseType: "blob",
          timeout: 900000,
        },
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", generateReportFileName());
      link.style.display = "none";
      document.body.appendChild(link);

      document.body.removeChild(link);

      link.click();

      URL.revokeObjectURL(url);
      setExploreItemsLoading({
        type: "csv",
        isLoading: false,
      });
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        setExploreItemsError({
          type: "csv",
          message:
            "Error downloading csv, change the collection and try again.",
        });
        setTimeout(() => {
          setExploreItemsError({
            type: "csv",
            message: "",
          });
        }, 5000);
      }
      setExploreItemsLoading({
        type: "csv",
        isLoading: false,
      });
      throw Error(String(error));
    }
  }

  useEffect(() => {
    if (allSelectedFilters.length > 0) {
      insertFieldsValues(allSelectedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelectedFilters]);

  return (
    <ExploreItemsContext.Provider
      value={{
        allSelectedFilters,
        changeRowLimit,
        exploreItemsFilters,
        exploreItemsSelectFilters,
        exploreItemsLoading,
        exploreItemsError,
        filtersBodyApi,
        fetchExploreItems,
        fetchExploreItemsCSV,
        insertFiltersValues,
        loadExploreItemsFilters,
        tableData,
        handleDateFilterModal,
      }}
    >
      {children}
    </ExploreItemsContext.Provider>
  );
}
