import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "../../../../components/Button";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { Pagination } from "../../../../components/Pagination";
import { SearchInput } from "../../../../components/SearchInput";

import { RoleTypes } from "../../../../@types/Role";
import { WebRoleList } from "../../../../components/RoleTable/WebRoleList";
import * as S from "./styles";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import { AlertModal } from "./components/AlertModal";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

export function Roles() {
  const navigate = useNavigate();

  const updatedParams = new URLSearchParams();

  const [roleSearchParams, setRoleSearchParams] = useSearchParams();
  const [roles, setRoles] = useState<RoleTypes>({
    content: [],
    name: roleSearchParams.get("name") ?? "",
    page: roleSearchParams.get("page") ?? "1",
    limit: roleSearchParams.get("limit") ?? "5",
    sortBy: roleSearchParams.get("sortBy") ?? "",
    totalPages: roleSearchParams.get("totalPages") ?? "0",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { name, page, limit, sortBy, totalPages } = roles;

  const searchAllRoles = async () => {
    setLoading(true);
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.roles.searchRoles,
        "GET",
        {
          params: {
            name,
            page,
            limit,
            sortBy,
            totalPages,
          },
        },
      );

      setRoles((state) => ({
        ...state,
        content: data.content,
        totalPages: data.totalPages || "0",
      }));
    } catch (error) {
      throw Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRole = async (id: string) => {
    try {
      await wrapperRequests(routesURL.settings.roles.deleteRole(id), "DELETE");

      toast.success("Role deleted successfully!", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setRoles((state) => ({
        ...state,
        content: state.content.filter((role) => role.id !== id),
      }));
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data.message);
      }
    }
  };

  const updateRoleParamValue = (key: keyof RoleTypes, value: string) => {
    setRoles((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const updateSearchParams = () => {
    name && updatedParams.set("name", name);
    sortBy && updatedParams.set("sortBy", sortBy);

    updatedParams.set("limit", String(limit));
    updatedParams.set("page", "1");

    setRoleSearchParams(updatedParams);
  };

  const resetParams = () => {
    setRoleSearchParams({
      limit: "5",
      page: "1",
    });
  };

  const updateRows = (value: number) => {
    setRoleSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setRoleSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const updateSortBy = (value: string) => {
    setRoleSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("sortBy", String(value));
      return updatedParams;
    });
  };

  useEffect(() => {
    searchAllRoles();
  }, []);
  return (
    <S.Container>
      <S.SideContainer>
        <PageHeader
          title="Roles"
          description="Create stores or manage active stores in the system"
          refreshFunction={resetParams}
          hasButtons
        />

        <S.UsersFiltersContainer>
          <div className="filters_align">
            <S.FilterContainer>
              <SearchInput
                variant="xlarge"
                value={name}
                searchIcon
                disabled={loading}
                placeholder={"Search by name"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.preventDefault();
                  updateRoleParamValue("name", event.target.value);
                }}
              />
            </S.FilterContainer>

            <Button disabled={loading} onClick={updateSearchParams}>
              {loading ? (
                <ReactLoading
                  height={24}
                  width={24}
                  type={"spin"}
                  color="#fff"
                />
              ) : (
                "Search"
              )}
            </Button>
          </div>

          <Button onClick={() => navigate("/settings/roles/create")}>
            New Role
          </Button>
        </S.UsersFiltersContainer>

        <S.Content>
          <WebRoleList
            selectSortBy={updateSortBy}
            onHandleDeleteRole={handleDeleteRole}
            roles={roles.content}
            loading={loading}
          />

          <Pagination
            selectPage={updatePage}
            page={Number(page)}
            placeholder={String(limit)}
            selectRowsPerPage={updateRows}
            totalPages={Number(totalPages)}
          />
        </S.Content>
      </S.SideContainer>

      <AlertModal
        message={errorMessage}
        opened={!!errorMessage}
        closeModal={() => setErrorMessage("")}
      />
    </S.Container>
  );
}
