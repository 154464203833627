import { DayPicker } from "react-day-picker";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const DayPickerComponent = styled(DayPicker)`
  padding: 0.5rem 1rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;

  position: absolute;
  top: 1.5rem;
  left: -1rem;
  z-index: 1;

  animation: ${fadeIn} 0.3s ease-in forwards;

  .rdp-dropdown {
    cursor: pointer;
  }

  .rdp-caption_dropdowns {
    select {
      background-color: ${(props) => props.theme.colors.white};

      option {
        color: ${(props) => props.theme.colors["gray-800"]};
        font-size: ${(props) => props.theme.fontSize.XS};
      }
    }
  }

  .rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
    background-color: transparent;
    border: none;
    border-radius: 0;
  }

  .rdp-caption_label {
    color: ${(props) => props.theme.colors["black-700"]};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    background-color: ${(props) => props.theme.colors["blue-600"]};
  }
`;
