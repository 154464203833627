import { FiSearch } from "react-icons/fi";

import { ReactNode } from "react";
import * as S from "./styles";

interface SearchInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  children?: ReactNode;
  variant?: "form" | "small" | "xsmall" | "xlarge";
  searchIcon?: boolean;
  title?: string;
  titleVariant?: "light";
  onClick?: () => void;
  onPressEnter?: () => void;
}

export function SearchInput({
  children,
  variant,
  searchIcon,
  title,
  titleVariant,
  onClick,
  onPressEnter,
  ...rest
}: SearchInputProps) {
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && onPressEnter) {
      onPressEnter();
    }
  }

  return (
    <S.SearchInputContainer variant={variant}>
      {title && <S.InputTitle variant={titleVariant}>{title}</S.InputTitle>}
      <S.SearchInputWrapper variant={variant}>
        {children}
        {searchIcon && <FiSearch size={22} onClick={onClick} />}
        <input type="text" onKeyDown={handleKeyDown} {...rest} />
      </S.SearchInputWrapper>
    </S.SearchInputContainer>
  );
}
