import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useContextSelector } from "use-context-selector";
import { Button } from "../../../../components/Button";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { Pagination } from "../../../../components/Pagination";
import { SearchInput } from "../../../../components/SearchInput";
import { SelectInput } from "../../../../components/SelectInput";
import { DuplicateItemsContext } from "../../../../contexts/DuplicateItemsContext";
import { DuplicateItemsList } from "./components/DuplicateItemsList";

import { useSearchParams } from "react-router-dom";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";

export interface DuplicateItemsProps {
  id: string;
  brands: string[];
  descriptions: string[];
  systemSkus: string[];
  tenantName: string;
  quantitiesOnHand: number[];
  upc?: string;
  sku?: string;
  ean?: string;
}

export function DuplicateItems() {
  const { allTenants, fetchAllTenants } = useContextSelector(
    DuplicateItemsContext,
    (context) => {
      return context;
    },
  );

  const [newDuplicateItems, setNewDuplicateItems] = useState<
    DuplicateItemsProps[]
  >([]);

  const [duplicatedItemsSearchParams, setDuplicatedItemsSearchParams] =
    useSearchParams();

  const [duplicatedItemsParams, setDuplicatedItemsParams] = useState({
    search: duplicatedItemsSearchParams.get("search") ?? "",
    brand: duplicatedItemsSearchParams.get("brand") ?? "",
    tenantName: duplicatedItemsSearchParams.get("tenantName") ?? "",
    pendingImport: duplicatedItemsSearchParams.get("pendingImport") === "true",
    page: duplicatedItemsSearchParams.get("page") ?? "1",
    rowsPerPage: duplicatedItemsSearchParams.get("rowsPerPage") ?? "5",
    totalPages: duplicatedItemsSearchParams.get("totalPages") ?? "0",
  });
  const [loading, setLoading] = useState(false);

  type UpdateDuplicatedItemsParamsProps = keyof typeof duplicatedItemsParams;

  const updatedParams = new URLSearchParams();

  const getDuplicatedItems = async () => {
    setLoading(true);
    try {
      const { data } = await wrapperRequests(
        routesURL.report.duplicatedItems.getDuplicatedItems,
        "GET",
        {
          params: {
            page: duplicatedItemsParams?.page,
            limit: duplicatedItemsParams?.rowsPerPage,
            tenantName: duplicatedItemsParams?.tenantName
              ? duplicatedItemsParams?.tenantName
              : undefined,
            brand: duplicatedItemsParams?.brand
              ? duplicatedItemsParams?.brand
              : undefined,
            search: duplicatedItemsParams?.search
              ? duplicatedItemsParams?.search
              : undefined,
            pendingImport: duplicatedItemsParams?.pendingImport,
          },
        },
      );

      setNewDuplicateItems(data.content);
      setDuplicatedItemsParams((currentState) => {
        return {
          ...currentState,
          totalPages: data?.totalPages ?? "0",
        };
      });
    } catch (error) {
      throw Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitDuplicateItem = async (itemId: string) => {
    setLoading(true);
    try {
      const submitResponse = await wrapperRequests(
        routesURL.report.duplicatedItems.editDuplicatedItems,
        "PUT",
        {
          data: {
            ids: [itemId],
          },
        },
      );

      if (submitResponse.status === 200) {
        const removeItemFromList =
          newDuplicateItems &&
          newDuplicateItems.filter((item) => item.id !== itemId);

        setNewDuplicateItems(removeItemFromList);
      }
    } catch (error) {
      throw new Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const updateInputSearchValues = async () => {
    duplicatedItemsParams?.search &&
      updatedParams.set("search", duplicatedItemsParams.search);
    duplicatedItemsParams?.brand &&
      updatedParams.set("brand", duplicatedItemsParams.brand);
    duplicatedItemsParams?.tenantName &&
      updatedParams.set("tenantName", duplicatedItemsParams.tenantName);
    updatedParams.set(
      "pendingImport",
      String(duplicatedItemsParams.pendingImport),
    );
    updatedParams.set("rowsPerPage", String(duplicatedItemsParams.rowsPerPage));
    updatedParams.set("page", "1");

    setDuplicatedItemsSearchParams(updatedParams);
  };

  const updateDuplicatedItemsParams = (
    key: UpdateDuplicatedItemsParamsProps,
    value: any,
  ) => {
    setDuplicatedItemsParams((currentValue) => {
      return {
        ...currentValue,
        [key]: value,
      };
    });
  };

  const updatePendingImport = (value: boolean) => {
    setDuplicatedItemsSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("pendingImport", String(value));
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setDuplicatedItemsSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const updateRows = (value: number) => {
    setDuplicatedItemsSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("rowsPerPage", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  useEffect(() => {
    fetchAllTenants();
    getDuplicatedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.DuplicateItemsContainer>
      <PageHeader
        title="Duplicate Items"
        description={
          duplicatedItemsParams.pendingImport === false
            ? "List of items that are currently duplicated in Lightspeed. Check the box next to each duplicated item listed on this page. Then, log in to Lightspeed and resolve the duplicated products."
            : "These items have been checked as merged and are waiting to be updated. Uncheck them if they have not been merged yet."
        }
        hasButtons
        hasBorder={false}
        refreshFunction={() =>
          setDuplicatedItemsSearchParams({
            pendingImport: String(duplicatedItemsParams.pendingImport),
          })
        }
      />

      <S.DuplicateItemsHeaderButtonContainer>
        <S.DuplicateItemsHeaderButton
          active={duplicatedItemsParams.pendingImport === false}
          onClick={() => {
            updatePendingImport(false);
            duplicatedItemsParams.pendingImport !== false &&
              updateDuplicatedItemsParams("pendingImport", false);
          }}
        >
          Pending Merge
        </S.DuplicateItemsHeaderButton>

        <S.DuplicateItemsHeaderButton
          active={duplicatedItemsParams.pendingImport === true}
          onClick={() => {
            updatePendingImport(true);
            duplicatedItemsParams.pendingImport !== true &&
              updateDuplicatedItemsParams("pendingImport", true);
          }}
        >
          Pending Import
        </S.DuplicateItemsHeaderButton>
      </S.DuplicateItemsHeaderButtonContainer>

      <S.DuplicateItemsFiltersContainer>
        <div className="filters_align">
          <SearchInput
            title="Search item Identifier"
            value={duplicatedItemsParams.search ?? ""}
            searchIcon
            placeholder="Search by UPC/SKU/EAN/Description"
            onPressEnter={() => updateInputSearchValues()}
            onChange={(event) => {
              updateDuplicatedItemsParams("search", event.target.value);
            }}
          />

          <SearchInput
            title="Filter by Brand"
            value={duplicatedItemsParams.brand ?? ""}
            searchIcon
            variant="small"
            placeholder="Filter by Brand"
            onPressEnter={() => updateInputSearchValues()}
            onChange={(event) => {
              updateDuplicatedItemsParams("brand", event.target.value);
            }}
          />

          <SelectInput
            title="Filter by Region"
            value={duplicatedItemsParams.tenantName ?? ""}
            placeholder="Select an option"
            options={allTenants.map((tenantName) => ({
              id: tenantName.id,
              value: tenantName.name,
              label: tenantName.name,
            }))}
            onChange={(event) => {
              updateDuplicatedItemsParams("tenantName", event.target.value);
            }}
          />

          <Button
            disabled={loading}
            onClick={(event) => {
              event.preventDefault();
              updateInputSearchValues();
            }}
          >
            {loading ? (
              <ReactLoading height={24} width={24} type={"spin"} color="#fff" />
            ) : (
              "Search"
            )}
          </Button>
        </div>
      </S.DuplicateItemsFiltersContainer>

      <>
        {!!newDuplicateItems && (
          <DuplicateItemsList
            duplicateItemsData={newDuplicateItems}
            duplicateItemsLoading={loading}
            handleSubmitDuplicateItem={handleSubmitDuplicateItem}
            selectedButton={duplicatedItemsParams.pendingImport}
          />
        )}
        <Pagination
          placeholder={String(duplicatedItemsParams.rowsPerPage)}
          selectPage={updatePage}
          page={Number(duplicatedItemsParams.page)}
          selectRowsPerPage={updateRows}
          totalPages={Number(duplicatedItemsParams.totalPages)}
        />
      </>
    </S.DuplicateItemsContainer>
  );
}
