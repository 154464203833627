export const borderRadius = {
  S: "0.25rem",
  SM: "0.5rem",
  SMM: "0.75rem",
  MD: "1rem",
  LG: "1.5rem",
  XXLG: "12.5rem",
  Pill: "31.25rem",
  Circular: "50%",
} as const;
