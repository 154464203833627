import styled, { css } from "styled-components";

interface ContainerProps {
  ordersSyncLoading: boolean;
}

export const Container = styled.main<ContainerProps>`
  flex: 1;

  ${(props) =>
    props.ordersSyncLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;

      height: 50vh;
    `};
`;
