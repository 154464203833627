import { FormEvent, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoKey } from "react-icons/io5";
import ReactLoading from "react-loading";
import PackageJson from "../../../package.json";
import Logo from "../../assets/images/white-logo.svg";
import { useAuth } from "../../hooks/auth";

import * as Yup from "yup";

import * as S from "./styles";

export function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { signIn, isLoading } = useAuth();

  async function handleSignIn(event: FormEvent) {
    event.preventDefault();
    try {
      const schema = Yup.object().shape({
        password: Yup.string().required("Enter your password"),
        email: Yup.string().required("Invalid email").email("Invalid email"),
      });

      const data = { email, password };

      await schema.validate(data);

      setError("");

      await signIn({
        email,
        password,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setError(err.message);
      }
    }
  }

  return (
    <S.Container>
      <S.Content onSubmit={(event) => handleSignIn(event)}>
        <img src={Logo} alt="logo" />

        <S.Title>Email</S.Title>
        <S.InputContainer className="container" filled={!!email}>
          <IoMdMail />
          <S.Input
            type="email"
            name="email"
            value={email}
            onChange={(value) => {
              setError("");
              setEmail(value.target.value);
            }}
          />
        </S.InputContainer>
        {error === "Invalid email" && (
          <div className="error_div">
            <FaExclamationTriangle />
            <label>{error}</label>
          </div>
        )}

        <S.Title>Password</S.Title>
        <S.InputContainer className="container" filled={!!password}>
          <IoKey />
          <S.Input
            type="password"
            autoComplete="new-password"
            value={password}
            onChange={(value) => {
              setError("");
              setPassword(value.target.value);
            }}
          />
        </S.InputContainer>
        {error === "Enter your password" && (
          <div className="error_div">
            <FaExclamationTriangle />
            <label>{error}</label>
          </div>
        )}

        <S.SignInButton type="submit" disabled={isLoading}>
          {isLoading ? (
            <ReactLoading
              height={25}
              width={25}
              type={"spin"}
              color="#ffffff"
            />
          ) : (
            "Sign In"
          )}
        </S.SignInButton>

        <S.ForgotPasswordButton to="/forgot-password">
          Forgot your password?
        </S.ForgotPasswordButton>

        <S.VersionContainer>
          <p>Version {PackageJson.version}</p>
        </S.VersionContainer>
      </S.Content>
    </S.Container>
  );
}
