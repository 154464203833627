import { MdOutlineArrowDropDown, MdOutlineArrowRight } from "react-icons/md";
import ReactLoading from "react-loading";
import { SearchInput } from "../../../../../../components/SearchInput";

import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useContextSelector } from "use-context-selector";
import { ExploreItemsContext } from "../../../../../../contexts/ExploreItemsContext";
import { ConvertStringAddSpaceBeforeUppercase } from "../../../../../../shared/ConvertStringAddSpaceBeforeUppercase";
import * as S from "./styles";

export function SideFilters() {
  const [allFiltersTab, setAllFiltersTab] = useState(true);
  const [filterTitleSelected, setFilterTitleSelected] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [isFiltersOpened, setIsFiltersOpened] = useState(true);

  const {
    allSelectedFilters,
    exploreItemsFilters,
    exploreItemsLoading,
    exploreItemsSelectFilters,
    loadExploreItemsFilters,
  } = useContextSelector(ExploreItemsContext, (context) => {
    return context;
  });

  const filteredArray =
    exploreItemsFilters.length > 0 &&
    exploreItemsFilters.map((item) => {
      const filteredFilters = item.filters.filter((filter) =>
        filter.label.toLowerCase().includes(filterSearch.toLowerCase()),
      );
      return { ...item, filters: filteredFilters };
    });

  const theme = useTheme();

  function handleFilterTitleSelected(filterValue: string) {
    setFilterTitleSelected((currentValue) =>
      currentValue === filterValue ? "" : filterValue,
    );
  }

  function filterIsSelected(collection: string, filter: string): boolean {
    const selectedCollection = allSelectedFilters?.filter(
      (filter) => filter.collection === collection,
    );
    return selectedCollection?.some(
      (selectedFilter) =>
        selectedFilter?.filters.some((mapFilter) => mapFilter.key === filter),
    );
  }

  useEffect(() => {
    loadExploreItemsFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isFiltersOpened ? (
    <S.ExploreSideFilterMenu showFilters={isFiltersOpened}>
      <S.ExploreSideFilterInputContainer showFilters={isFiltersOpened}>
        <div>
          <S.ExploreSideFilterMenuTitle>Filters</S.ExploreSideFilterMenuTitle>
          <S.ReduceArrowIcon
            title="Reduce filters"
            onClick={() => setIsFiltersOpened(!isFiltersOpened)}
          />
        </div>

        <SearchInput
          searchIcon
          variant="xsmall"
          placeholder="Search keywords"
          onPressEnter={() => { }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            setFilterSearch(event.target.value);
          }}
        />
      </S.ExploreSideFilterInputContainer>

      <S.ExploreSideFilterButtonsContainer>
        <S.ExploreSideFilterTabButton
          selected={allFiltersTab}
          onClick={() => setAllFiltersTab(true)}
        >
          All Filters
        </S.ExploreSideFilterTabButton>

        <S.ExploreSideFilterTabButton
          selected={!allFiltersTab}
          onClick={() => setAllFiltersTab(false)}
        >
          In use
        </S.ExploreSideFilterTabButton>
      </S.ExploreSideFilterButtonsContainer>

      {exploreItemsLoading.type === "filters" &&
        exploreItemsLoading.isLoading ? (
        <S.ExploreFiltersLoadingContainer>
          <ReactLoading
            height={35}
            width={35}
            type={"spin"}
            color={theme.colors["blue-600"]}
          />
        </S.ExploreFiltersLoadingContainer>
      ) : (
        <S.ExploreFiltersContainer>
          {allFiltersTab &&
            filteredArray &&
            filteredArray.map((category) => (
              <S.ExploreFiltersContent key={category.collection}>
                <S.ExploreFiltersTitleButton
                  onClick={() => handleFilterTitleSelected(category.collection)}
                  selected={filterTitleSelected === category.collection}
                >
                  <div>
                    {filterTitleSelected === category.collection ? (
                      <MdOutlineArrowDropDown size={24} />
                    ) : (
                      <MdOutlineArrowRight size={24} />
                    )}
                    {ConvertStringAddSpaceBeforeUppercase(category.collection)}
                  </div>
                  <span>{category?.filters?.length}</span>
                </S.ExploreFiltersTitleButton>
                {filterTitleSelected === category.collection &&
                  category.filters
                    .sort((a, b) => a.key.localeCompare(b.key))
                    .map((filter) => (
                      <S.ExploreFiltersCategoryButtonContainer key={filter.key}>
                        <S.ExploreFiltersCategoryButton
                          selected={filterIsSelected(
                            category.collection,
                            filter?.key,
                          )}
                          onClick={() => {
                            const selectedFilter =
                              filter.type === "date"
                                ? {
                                  ...filter,
                                  modal: {
                                    inputStart: false,
                                    inputEnd: false,
                                    inputSingleDate: false,
                                  },
                                }
                                : filter;

                            exploreItemsSelectFilters(
                              category.collection,
                              selectedFilter,
                            );
                          }}
                        >
                          <span>
                            {filterIsSelected(category.collection, filter?.key)
                              ? "-"
                              : "+"}
                          </span>
                          {filter.label}
                        </S.ExploreFiltersCategoryButton>
                      </S.ExploreFiltersCategoryButtonContainer>
                    ))}
              </S.ExploreFiltersContent>
            ))}
          {!allFiltersTab &&
            allSelectedFilters.map((category) => (
              <S.ExploreFiltersContent key={category.collection}>
                <S.ExploreFiltersTitleButton
                  onClick={() => handleFilterTitleSelected(category.collection)}
                  selected={filterTitleSelected === category.collection}
                >
                  <div>
                    {filterTitleSelected === category.collection ? (
                      <MdOutlineArrowDropDown size={24} />
                    ) : (
                      <MdOutlineArrowRight size={24} />
                    )}
                    {ConvertStringAddSpaceBeforeUppercase(category.collection)}
                  </div>
                  <span>{category?.filters?.length}</span>
                </S.ExploreFiltersTitleButton>
                {filterTitleSelected === category.collection &&
                  category.filters
                    .sort((a, b) => a.key.localeCompare(b.key))
                    .map((filter) => (
                      <S.ExploreFiltersCategoryButtonContainer key={filter.key}>
                        <S.ExploreFiltersCategoryButton
                          selected={filterIsSelected(
                            category.collection,
                            filter?.key,
                          )}
                          onClick={() =>
                            exploreItemsSelectFilters(
                              category.collection,
                              filter,
                            )
                          }
                        >
                          {filterIsSelected(category.collection, filter?.key)
                            ? "-"
                            : "+"}{" "}
                          {filter.label}
                        </S.ExploreFiltersCategoryButton>
                      </S.ExploreFiltersCategoryButtonContainer>
                    ))}
              </S.ExploreFiltersContent>
            ))}
        </S.ExploreFiltersContainer>
      )}
    </S.ExploreSideFilterMenu>
  ) : (
    <S.ExploreSideFilterMenu showFilters={isFiltersOpened}>
      <S.ExploreSideFilterInputContainer showFilters={isFiltersOpened}>
        <S.ExpandArrowIcon
          title="Expand filters"
          onClick={() => setIsFiltersOpened(!isFiltersOpened)}
        />
      </S.ExploreSideFilterInputContainer>
    </S.ExploreSideFilterMenu>
  );
}
