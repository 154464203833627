import { MdOutlineRemoveRedEye } from "react-icons/md";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { IInventoryItems } from "../../../../../@types/InventoryItems";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../../../../components/TableElements";
import { ActionsButton } from "../../../../../components/TableElements/components/ActionsButton";

import { CopyContainer } from "../../../../../components/TableElements/components/CopyContainer";
import * as S from "./styles";

interface OrdersSyncErrorProps {
  loading: boolean;
  data: IInventoryItems[];
  selectSortBy: (value: string) => void;
}

export function ItemSearchList({
  data,
  loading,
  selectSortBy,
}: OrdersSyncErrorProps) {
  const navigate = useNavigate();

  const handleNavigateToDetails = (id: string, sku: string) => {
    navigate(`${id}`, { state: { itemSku: sku } });
  };

  return (
    <S.Container ordersSyncLoading={loading}>
      {loading ? (
        <ReactLoading height={35} width={35} type={"spin"} color="#000" />
      ) : (
        <TableComponent>
          <thead>
            <tr>
              <ThComponent
                arrowUpFunction={() => selectSortBy("name_desc")}
                arrowDownFunction={() => selectSortBy("name_asc")}
              >
                Item Name
              </ThComponent>
              <ThComponent
                arrowUpFunction={() => selectSortBy("sku_desc")}
                arrowDownFunction={() => selectSortBy("sku_asc")}
              >
                SKU
              </ThComponent>
              <ThComponent
                arrowUpFunction={() => selectSortBy("upc_desc")}
                arrowDownFunction={() => selectSortBy("upc_asc")}
              >
                UPC
              </ThComponent>
              <ThComponent
                arrowUpFunction={() => selectSortBy("quantity_desc")}
                arrowDownFunction={() => selectSortBy("quantity_asc")}
              >
                QTY
              </ThComponent>
              <ThComponent
                arrowUpFunction={() => selectSortBy("total_quantity_desc")}
                arrowDownFunction={() => selectSortBy("total_quantity_asc")}
              >
                Total QTY
              </ThComponent>
              <ThComponent>Vendor QTY</ThComponent>
              <ThComponent withArrows={false}></ThComponent>
            </tr>
          </thead>

          <tbody>
            {data.map((inventoryItem) => (
              <TrComponent key={inventoryItem.id}>
                <TdComponent>
                  <CopyContainer stringValue={inventoryItem?.name} />
                </TdComponent>
                <TdComponent tdWidth={"20%"}>
                  <CopyContainer stringValue={inventoryItem?.sku} />
                </TdComponent>
                <TdComponent tdWidth={"20%"}>
                  <CopyContainer stringValue={inventoryItem?.upc} />
                </TdComponent>
                <TdComponent tdWidth={"10%"}>
                  {inventoryItem.quantityOnHand}
                </TdComponent>
                <TdComponent tdWidth={"10%"}>
                  {inventoryItem.quantityTotal}
                </TdComponent>
                <TdComponent>
                  {inventoryItem.quantitySpecialized ?? 0}
                </TdComponent>
                <TdComponent singleButton>
                  <ActionsButton
                    title="Item details"
                    onClick={() =>
                      handleNavigateToDetails(
                        inventoryItem.id,
                        inventoryItem.sku ?? inventoryItem.upc,
                      )
                    }
                  >
                    <MdOutlineRemoveRedEye size={24} />
                  </ActionsButton>
                </TdComponent>
              </TrComponent>
            ))}
          </tbody>
        </TableComponent>
      )}
    </S.Container>
  );
}
