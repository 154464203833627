import styled from "styled-components";

export const ExploreContainer = styled.main`
  display: flex;
`;

export const ExploreContentContainer = styled.div`
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 3rem;
`;

export const ExploreItemsError = styled.span`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors["red-500"]};

  padding: 0.15rem 0;
`;
