import * as S from "./styles";

interface SwitchButtonProps {
  onChange: () => void;
  checked: boolean;
}

export function SwitchButton({ checked, onChange }: SwitchButtonProps) {
  return <S.SwitchStyled onChange={onChange} checked={checked} />;
}
