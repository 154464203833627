import React, { useEffect, useState } from "react";

import { useAuth } from "../../../hooks/auth";
import { useLocation } from "react-router-dom";
import { routeName } from "../../../shared/RouteName";
import { HeaderDropdown } from "./components/Dropdown";

import Logo from "../../../assets/images/white-logo.svg";

import * as S from "./styles";

export default function Header() {
  const [route, setRoute] = useState("");
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setRoute(routeName(location.pathname));
  }, [location.pathname]);

  return (
    <S.HeaderContainer>
      <S.Title>{route}</S.Title>
      <S.UserContainer>
        <S.GreetingsText>
          Hi, <S.UserName>{user.user.name}!</S.UserName>
        </S.GreetingsText>

        <HeaderDropdown>
          <S.UserPhoto src={Logo} alt="User photo" />
        </HeaderDropdown>
        <HeaderDropdown>
          <button className="arrow_btn">
            <S.ArrowDown />
          </button>
        </HeaderDropdown>
      </S.UserContainer>
    </S.HeaderContainer>
  );
}
