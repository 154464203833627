import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  padding: 3rem;
  padding-top: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

export const LoadingContainer = styled.div`
  height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    div.align_error {
      display: flex;
      flex-direction: column;
    }
  }

  div.error_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    color: ${(props) => props.theme.colors["red-500"]};

    svg {
      margin-right: 5px;
    }
  }
`;

export const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1.5rem;

  div.input_align {
    display: flex;
    flex-direction: column;

    p {
      font-size: ${(props) => props.theme.fontSize.XXXS};
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      font-family: ${(props) => props.theme.fontFamily.Sora};
      color: ${(props) => props.theme.colors.black};
    }

    input {
      border: 1px solid ${(props) => props.theme.colors["gray-400"]};
      border-radius: ${(props) => props.theme.borderRadius.SM};
      color: ${(props) => props.theme.colors["gray-800"]};
      background-color: ${(props) => props.theme.colors.white};

      font-size: ${(props) => props.theme.fontSize.XXXS};
      font-family: ${(props) => props.theme.fontFamily.Sora};

      padding: 1rem;
      width: 22rem;
      max-height: 3rem;

      &:hover {
        outline: 1px solid ${(props) => props.theme.colors["gray-650"]};
      }
    }
  }
`;

export const MenuAccessRowContainer = styled(InputRowContainer)`
  align-items: baseline;
  display: grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  width: 100%;
`;

export const Divider = styled.div`
  background-color: ${(props) => props.theme.colors["gray-400"]};
  width: 100%;
  height: 0.0625rem;
  margin-top: 2.5rem;
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.MD};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors["gray-900"]};
  line-height: ${(props) => props.theme.lineHeight.Default};
  margin: 2.5rem 0;
`;
