import { ReactNode, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineAccountCircle } from "react-icons/md";
import { VscSignOut } from "react-icons/vsc";
import { useAuth } from "../../../../../hooks/auth";
import { ConfirmationModal } from "../../../../ConfirmationModal";

import * as S from "./styles";

interface DropdownProps {
  children?: ReactNode;
}

export function HeaderDropdown({ children }: DropdownProps) {
  const [confirmationOpened, setConfirmationOpened] = useState(false);
  const { signOut } = useAuth();

  function handleSignOut() {
    signOut();
  }

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <S.DropdownContent sideOffset={32}>
          <DropdownMenu.Arrow fill="white" width="16" height="8" />
          <S.DropdownItemContainer>
            <S.DropdownLinkButton to="/profile">
              <MdOutlineAccountCircle size={24} />
              Profile
            </S.DropdownLinkButton>
          </S.DropdownItemContainer>
          <S.DropdownItemContainer>
            <S.DropdownLinkButton to="/settings">
              <IoSettingsOutline size={24} />
              Settings
            </S.DropdownLinkButton>
          </S.DropdownItemContainer>
          <S.DropdownItemContainer>
            <S.LogoutButton
              onClick={() => {
                setConfirmationOpened(!confirmationOpened);
              }}
            >
              <VscSignOut size={24} />
              Logout
            </S.LogoutButton>
          </S.DropdownItemContainer>
        </S.DropdownContent>
      </DropdownMenu.Portal>

      <ConfirmationModal
        title="Are you sure you want to sign out?"
        confirmFunction={handleSignOut}
        closeModal={() => setConfirmationOpened(!confirmationOpened)}
        opened={confirmationOpened}
      />
    </DropdownMenu.Root>
  );
}
