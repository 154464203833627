import styled from "styled-components";

export const SelectStoreContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  margin: 2.5rem 0 1.5rem;

  border-top: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;

export const SelectStoreHeader = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
  padding-bottom: 1.5rem;

  h3 {
    font-size: ${(props) => props.theme.fontSize.SM};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors.black};

    margin-right: 1rem;
  }

  span {
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors["gray-800"]};
  }

  div.error_align,
  div.section_align {
    display: flex;
    align-items: center;
    padding: 1.5rem 0 1rem;
  }

  div.error_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    color: ${(props) => props.theme.colors["red-500"]};

    svg {
      margin-right: 5px;
    }
  }
`;

export const StoresLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;

export const SelectStoreFiltersContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 1.5rem;
  gap: 1rem;
`;

export const SelectAllContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  padding: 1rem 0;
  width: 10rem;
  max-height: 3rem;

  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.SM};
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};

  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  color: ${(props) => props.theme.colors["gray-800"]};

  &:hover {
    outline: 1px solid ${(props) => props.theme.colors["gray-650"]};
    transition: outline 0.2s;
  }

  input {
    width: 16px;
    height: 16px;

    margin-right: 0.5rem;
    cursor: pointer;
  }

  svg {
    margin-right: 0.3rem;
  }
`;

export const StoreTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.colors["pink-600"]};
  color: ${(props) => props.theme.colors["white"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};

  height: 2rem;
  width: 6rem;
`;

export const StoreStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.colors["green-650"]};
  color: ${(props) => props.theme.colors["gray-900"]};
  border-radius: ${(props) => props.theme.borderRadius.XXLG};

  height: 2rem;
  width: 6rem;
`;
