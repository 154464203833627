import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding-left: 1rem;

  background-color: ${(props) => props.theme.newColors["white-500"]};
  border: 1px solid ${(props) => props.theme.newColors["gray-500"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};

  input {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: ${(props) => props.theme.borderRadius.SM};
    padding: 0.5rem;

    color: ${(props) => props.theme.newColors["gray-900"]};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};
  }
`;
