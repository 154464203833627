import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled, { css } from "styled-components";

interface ContainerProps {
  userListLoading: boolean;
}

export const Container = styled.main<ContainerProps>`
  flex: 1;

  ${(props) =>
    props.userListLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;

      height: 50vh;
    `};
`;

interface UserRoleContainerProps {
  role: string;
}

export const UserRoleContainer = styled.div<UserRoleContainerProps>`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  text-transform: capitalize;

  border-radius: ${(props) => props.theme.borderRadius.XXLG};
  padding: 8px 16px;

  height: 2rem;

  ${({ role }) =>
    role === "MANAGER" || role === "ADMIN"
      ? css`
          background-color: ${(props) => props.theme.colors["blue-600"]};
        `
      : css`
          background-color: ${(props) => props.theme.colors["gray-800"]};
        `};
`;

export const ActionsButton = styled.button`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: 8px;

  padding: 0.5rem;
  cursor: pointer;

  & + button {
    margin-left: 8px;
  }

  svg {
    color: ${(props) => props.theme.colors["gray-800"]};
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors["gray-900"]};
    transition: border 0.2s;

    svg {
      color: ${(props) => props.theme.colors["gray-900"]};
      transition: color 0.2s;
    }
  }
`;

interface StatusProps {
  variant?: string;
}

export const StatusBadge = styled.span<StatusProps>`
  display: flex;
  justify-content: center;
  width: 4.75rem;

  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors["green-550"]};
  padding: 0.5rem;

  border-radius: ${(props) => props.theme.borderRadius["XXLG"]};

  ${(props) =>
    props.variant === "Inactive" &&
    css`
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors["gray-650"]};
    `};
`;
