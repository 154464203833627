import React from "react";
import * as S from "./styles";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  title?: string;
  errorMessage?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ errorMessage, title, ...rest }, ref) => {
    return (
      <S.Container>
        {title && <span>{title}</span>}
        <S.InputContent>
          <input ref={ref} {...rest} />
          {!!errorMessage && <span>{errorMessage}</span>}
        </S.InputContent>
      </S.Container>
    );
  },
);
