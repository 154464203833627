import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { Role } from "../../../@types/Role";
import { MdOutlineDelete, MdOutlineModeEditOutline } from "react-icons/md";

import { ConfirmationModal } from "../../ConfirmationModal";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../TableElements";

import * as S from "./styles";

interface WebRoleListProps {
  loading: boolean;
  roles: Role[];
  onHandleDeleteRole: (id: string) => Promise<void>;
  selectSortBy: (value: string) => void;
}

export function WebRoleList({
  roles,
  selectSortBy,
  loading,
  onHandleDeleteRole,
}: WebRoleListProps) {
  const navigate = useNavigate();

  function handleConfirmDeleteRole(id: string) {
    onHandleDeleteRole(id);
  }

  function handleNavigateToEditRole(id: string) {
    navigate(`/settings/roles/${id}`, { state: { id: id } });
  }

  return (
    <S.Container userListLoading={loading}>
      {loading ? (
        <ReactLoading height={35} width={35} type={"spin"} color="#000" />
      ) : (
        <TableComponent>
          <thead>
            <tr>
              <ThComponent
                arrowUpFunction={() => selectSortBy("name_desc")}
                arrowDownFunction={() => selectSortBy("name_asc")}
                // style={{ marginRight: "auto" }}
              >
                Employee Role
              </ThComponent>

              <ThComponent withArrows={false}>Actions</ThComponent>
            </tr>
          </thead>

          <tbody>
            {roles.map(({ id, name }) => (
              <TrComponent key={id}>
                <TdComponent tdWidth={"auto"}>{name}</TdComponent>

                <TdComponent tdWidth={"10%"}>
                  <S.ActionsButton
                    title="Edit role"
                    onClick={() => handleNavigateToEditRole(id)}
                  >
                    <MdOutlineModeEditOutline size={24} />
                  </S.ActionsButton>
                  <ConfirmationModal
                    title={"Do you want to delete this role?"}
                    toDelete
                    variant="delete"
                    confirmFunction={() => {
                      handleConfirmDeleteRole(id);
                    }}
                  >
                    <S.ActionsButton
                      className="delete_button"
                      title="Delete user"
                    >
                      <MdOutlineDelete size={24} />
                    </S.ActionsButton>
                  </ConfirmationModal>
                </TdComponent>
              </TrComponent>
            ))}
          </tbody>
        </TableComponent>
      )}
    </S.Container>
  );
}
