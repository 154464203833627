export function ConvertStringAddSpaceBeforeUppercase(categoryName: string) {
  let result = categoryName[0];

  for (let i = 1; i < categoryName.length; i++) {
    if (
      categoryName[i] === categoryName[i].toUpperCase() &&
      categoryName[i - 1] !== " "
    ) {
      result += " ";
    }
    result += categoryName[i];
  }

  return result;
}
