import * as Dialog from "@radix-ui/react-dialog";
import { AiOutlineClose } from "react-icons/ai";
import { GrAdd, GrFormSubtract } from "react-icons/gr";
import ReactLoading from "react-loading";
import { Button } from "../../../../../../components/Button";

import { useEffect, useState } from "react";
import { useContextSelector } from "use-context-selector";
import { UsersPermissionsContext } from "../../../../../../contexts/UsersPermissions";
import { colors } from "../../../../../../styles/themes/default/foundations/colors";
import * as S from "./styles";

interface PermissionsModalProps {
  userId?: string;
}

export function PermissionsModal({ userId }: PermissionsModalProps) {
  const {
    permissions,
    handleExpandPermissions,
    handleCheckPermissions,
    handleCheckOrRemoveAllPermissions,
    updateUserPermissions,
    permissionMessage,
    loadings,
  } = useContextSelector(UsersPermissionsContext, (context) => {
    return context;
  });

  const { webPermissions, appPermissions } = {
    webPermissions: permissions.WEB ? Object.values(permissions.WEB) : null,
    appPermissions: permissions.APP ? Object.values(permissions.APP) : null,
  };

  const [allChecked, setAllChecked] = useState(false);

  const hasSelectAllChecked = () => {
    const allWebChecked =
      webPermissions &&
      webPermissions.every((permission) => {
        return (
          permission?.hasPermission &&
          permission?.data?.every((child) => {
            return (
              child?.hasPermission &&
              child?.data?.every((grandChild) => grandChild?.hasPermission)
            );
          })
        );
      });

    const allAppChecked =
      appPermissions &&
      appPermissions.every((permission) => {
        return (
          permission && permission.data?.every((child) => child.hasPermission)
        );
      });

    if (allAppChecked && allWebChecked) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  useEffect(() => {
    hasSelectAllChecked();
  }, [permissions]);

  return (
    <Dialog.Root>
      <S.ModalTrigger asChild>
        <button>Edit access</button>
      </S.ModalTrigger>

      <Dialog.Portal>
        <S.Overlay>
          <S.Content>
            <S.TitleContainer>
              <S.ModalTitle>Edit Permissions</S.ModalTitle>

              <Dialog.Close asChild>
                <button>
                  <AiOutlineClose size={24} />
                </button>
              </Dialog.Close>
            </S.TitleContainer>

            <S.Separator style={{ margin: 0 }} />

            <S.SubTitleContainer>
              <S.ModalDescription>
                Choose the permissions that this user will not have on the
                system
              </S.ModalDescription>
              <button>
                <label htmlFor="select-all">
                  <input
                    id="select-all"
                    type="checkbox"
                    checked={allChecked}
                    onChange={(event) =>
                      handleCheckOrRemoveAllPermissions(event.target.checked)
                    }
                  />
                  Select all
                </label>
              </button>
            </S.SubTitleContainer>

            <S.PermissionsContent
              permissionsLoading={loadings.getUsersPermissions}
            >
              {loadings.getUsersPermissions ? (
                <ReactLoading
                  height={36}
                  width={36}
                  type={"spin"}
                  color="#232A3B"
                />
              ) : (
                <>
                  <S.PermissionsWrapper>
                    <S.PermissionType>Web</S.PermissionType>

                    <S.PermissionsList style={{ paddingBottom: "4rem" }}>
                      {webPermissions &&
                        webPermissions.map((permission) => (
                          <li key={permission?.name ?? ""}>
                            <S.PermissionItem>
                              {permission?.modal && permission.data?.length ? (
                                <GrFormSubtract
                                  size={10}
                                  color={colors["gray-800"]}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    permission &&
                                      handleExpandPermissions(
                                        permission?.environment,
                                        permission?.menu,
                                      );
                                  }}
                                />
                              ) : (
                                <GrAdd
                                  size={10}
                                  color={colors["gray-800"]}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    permission &&
                                      handleExpandPermissions(
                                        permission?.environment,
                                        permission?.menu,
                                      );
                                  }}
                                />
                              )}

                              <input
                                type="checkbox"
                                id={`permission-${permission?.name}`}
                                checked={permission?.hasPermission}
                                onChange={(event) =>
                                  permission &&
                                  handleCheckPermissions(
                                    permission.environment,
                                    permission.menu,
                                    permission.name,
                                    event.target.checked,
                                  )
                                }
                              />
                              <label htmlFor={`permission-${permission?.name}`}>
                                {permission?.menu}
                              </label>
                            </S.PermissionItem>

                            {permission?.modal && (
                              <>
                                {permission?.data?.map((child) => (
                                  <S.ExpandGroup key={child.name}>
                                    <S.PermissionItem>
                                      <input
                                        type="checkbox"
                                        id={`permission-${child.name}`}
                                        checked={child.hasPermission}
                                        onChange={(event) =>
                                          handleCheckPermissions(
                                            child.environment,
                                            child.menu,
                                            child.name,
                                            event.target.checked,
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={`permission-${child.name}`}
                                      >
                                        {child.name}
                                      </label>
                                    </S.PermissionItem>

                                    {child.data?.map((grandChild) => (
                                      <S.PermissionItem key={grandChild.name}>
                                        <input
                                          type="checkbox"
                                          id={`permission-${grandChild.name}`}
                                          checked={grandChild.hasPermission}
                                          onChange={(event) =>
                                            handleCheckPermissions(
                                              grandChild.environment,
                                              grandChild.menu,
                                              grandChild.name,
                                              event.target.checked,
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={`permission-${grandChild.name}`}
                                        >
                                          {grandChild.name}
                                        </label>
                                      </S.PermissionItem>
                                    ))}
                                  </S.ExpandGroup>
                                ))}
                              </>
                            )}
                          </li>
                        ))}
                    </S.PermissionsList>

                    <S.Separator />

                    <S.PermissionType>App</S.PermissionType>

                    <S.PermissionsList>
                      {appPermissions &&
                        appPermissions.map((permission) => (
                          <li key={permission?.name}>
                            {permission?.data?.map((child) => (
                              <S.PermissionItem key={child.name}>
                                <input
                                  type="checkbox"
                                  id={`permission-${child.name}`}
                                  checked={child?.hasPermission}
                                  onChange={(event) =>
                                    handleCheckPermissions(
                                      child.environment,
                                      child.menu,
                                      child.name,
                                      event.target.checked,
                                    )
                                  }
                                />
                                <label htmlFor={`permission-${child.name}`}>
                                  {child?.name}
                                </label>
                              </S.PermissionItem>
                            ))}
                          </li>
                        ))}
                    </S.PermissionsList>
                  </S.PermissionsWrapper>
                </>
              )}
            </S.PermissionsContent>

            <S.ButtonContainer>
              {permissionMessage && (
                <S.Message error={permissionMessage.includes("Error")}>
                  {permissionMessage}
                </S.Message>
              )}

              <Dialog.Close asChild>
                <Button variant="ghost" disabled={loadings.updatePermissions}>
                  Cancel
                </Button>
              </Dialog.Close>
              <Button
                disabled={loadings.updatePermissions}
                onClick={() => updateUserPermissions(userId)}
              >
                Save
              </Button>
            </S.ButtonContainer>
          </S.Content>
        </S.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
