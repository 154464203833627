import { useState } from "react";
import { IInventoryItemDetails } from "../../../../../@types/InventoryItems";
import { SearchInput } from "../../../../../components/SearchInput";

import { ConvertStringWithoutValueToDefault } from "../../../../../shared/ConvertStringWithoutValueToDefault";
import * as S from "./styles";

interface ItemDetailsCardProps {
  title: "Identification" | "Pricing" | "Stock" | "Location" | "History";
  search?: boolean;
  data: IInventoryItemDetails;
}


export function ItemDetailsCard({
  title,
  data,
  search = false,
}: ItemDetailsCardProps) {
  const [stockFilter, setStoreFilter] = useState("");
  const [storesWithStock, setStoresWithStock] = useState(false);

  const stockValue = data?.stock?.filter(
    ({ name }) => name === "In Stock",
  )[0] ?? {
    name: undefined,
    quantity: undefined,
  };

  const filteredStock = data.stock
    ? data.stock
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((stock) => {
          const partialStores = stock.name
            .toLocaleLowerCase()
            .includes(stockFilter.toLocaleLowerCase());

          if (storesWithStock) {
            return (
              partialStores && stock.quantity > 0 && stock.name !== "In Stock"
            );
          }

          return partialStores && stock.name !== "In Stock";
        })
    : [];

  return (
    <S.ItemDetailsCardContainer>
      <S.ItemDetailsCardTitle>{title}</S.ItemDetailsCardTitle>
        {search && (
          <S.SearchInputContainer>
            <SearchInput
            disabled={!search}
            placeholder="Search"
            onChange={(event) => setStoreFilter(event.target.value)}
          /> 
          </S.SearchInputContainer>
          )}
        {title === "Stock" && (
          <S.FilterContainer>
            <S.FilterButton
              onClick={() => setStoresWithStock(!storesWithStock)}
            >
              Stores with stock only
            </S.FilterButton>
            <input
              type="checkbox"
              checked={storesWithStock}
              onChange={() => setStoresWithStock(!storesWithStock)}
              onClick={() => setStoresWithStock(!storesWithStock)}
            />
          </S.FilterContainer>
        )}

      <S.ItemDetailsContent search={search}>
        {title === "Identification" && (
          <>
            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>UPC</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer>
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(data.upc)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>EAN</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer>
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(data.ean)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>Custom SKU</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer>
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(data.customSku)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>Man. SKU</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer>
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(data.manufacturerSku)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>
          </>
        )}

        {title === "Pricing" && (
          <>
            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>Default</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
                isPricingOrLocation={title === "Pricing" || title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(data?.prices?.default)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>MSRP</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
                isPricingOrLocation={title === "Pricing" || title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(data?.prices?.msrp)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>Online</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
               isPricingOrLocation={title === "Pricing" || title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(data?.prices?.online)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>CA</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
                isPricingOrLocation={title === "Pricing" || title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(data?.prices?.ca)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>TS</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
                isPricingOrLocation={title === "Pricing" || title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(data?.prices?.ts)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>Default Cost</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
                isPricingOrLocation={title === "Pricing" || title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(
                    data?.prices?.defaultCost,
                  )}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>
          </>
        )}

        {title === "Stock" && (
          <S.StockContainer>
            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>
                {ConvertStringWithoutValueToDefault(stockValue.name)}
              </S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
                isSpecial={ title === "Stock" || title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(stockValue.quantity)}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>
            {filteredStock.map(({ name, quantity }, index) => (
              <S.ItemDetailsRow key={index}>
                <S.ItemDetailsProperty>{name}</S.ItemDetailsProperty>
                <S.ItemDetailsValueContainer
                  isSpecial={ title === "Stock" || title === "Location"}
                >
                  <S.ItemDetailsValue>
                    {ConvertStringWithoutValueToDefault(quantity)}
                  </S.ItemDetailsValue>
                </S.ItemDetailsValueContainer>
              </S.ItemDetailsRow>
            ))}
          </S.StockContainer>
        )}

        {title === "Location" && (
          <>
            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>Box Number</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
                isPricingOrLocation={title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(
                    data.location?.boxNumber?.location,
                  )}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>Backstock 1</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
                isPricingOrLocation={title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(
                    data.location?.backStock1?.location,
                  )}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>Backstock 2</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
                isPricingOrLocation={title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(
                    data.location?.backStock2?.location,
                  )}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>

            <S.ItemDetailsRow>
              <S.ItemDetailsProperty>Backstock 3</S.ItemDetailsProperty>
              <S.ItemDetailsValueContainer
               isPricingOrLocation={title === "Location"}
              >
                <S.ItemDetailsValue>
                  {ConvertStringWithoutValueToDefault(
                    data.location?.backStock3?.location,
                  )}
                </S.ItemDetailsValue>
              </S.ItemDetailsValueContainer>
            </S.ItemDetailsRow>
          </>
        )}
      </S.ItemDetailsContent>
    </S.ItemDetailsCardContainer>
  );
}
