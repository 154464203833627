import styled, { css } from "styled-components";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

interface CollectionProps {
  showFilters: boolean;
}

export const FilterDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;

  width: 100%;
  height: 3rem;

  padding: 0.75rem 1rem;

  span {
    font-size: ${(props) => props.theme.fontSize.SM};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["black-925"]};

    margin-left: 0.25rem;
  }

  svg {
    color: ${(props) => props.theme.colors["gray-800"]};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 1.5rem 0;

  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};

  svg {
    cursor: pointer;
    margin-right: 0.5rem;
    color: ${(props) => props.theme.colors["gray-800"]};
  }

  span {
    width: 25%;
  }

  div {
    margin-right: 1rem;
  }

  input {
    font-size: ${(props) => props.theme.fontSize.XXS};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors["black-925"]};

    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors["gray-400"]};

    background-color: ${(props) => props.theme.colors.white};

    margin-right: 1.5rem;
  }
`;

export const FilterCollectionContainer = styled.div<CollectionProps>`
  ${(props) =>
    props.showFilters &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
    `}
`;

export const FilterCollection = styled.button<CollectionProps>`
  padding: 1.5rem 0 1rem;
  width: 100%;
  background: transparent;
  border: transparent;
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};

  ${(props) =>
    props.showFilters &&
    css`
      border-bottom: transparent;
    `}
`;

export const CollectionIndicator = styled.div<CollectionProps>`
  display: flex;

  gap: 1rem;
  align-items: center;

  & > span {
    background-color: ${(props) => props.theme.colors["blue-150"]};
    padding: 4px 12px;
    border-radius: ${(props) => props.theme.borderRadius.XXLG};

    color: ${(props) => props.theme.colors["blue-600"]};
    font-weight: ${(props) => props.theme.fontWeight.Bold};
  }

  & > p {
    font-size: ${(props) => props.theme.fontSize.XS};
    color: ${(props) => props.theme.colors["gray-950"]};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
    text-transform: capitalize;

    ${(props) =>
      props.showFilters &&
      css`
        font-weight: ${props.theme.fontWeight.Bold};
        text-decoration: underline;
      `}
  }
`;

export const ReduceArrowIcon = styled(IoMdArrowDropdown).attrs({
  size: 28,
})`
  cursor: pointer;

  color: ${(props) => props.theme.colors["black-925"]};
`;

export const ExpandArrowIcon = styled(IoMdArrowDropright).attrs({
  size: 28,
})`
  cursor: pointer;

  color: ${(props) => props.theme.colors["black-925"]};
`;
