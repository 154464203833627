import { ButtonHTMLAttributes } from "react";
import NoDataImage from "../../assets/images/no-data.png";

import * as S from "./styles";

interface NotFoundProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  buttonTitle: string;
}

export function NotFound({ title, buttonTitle, ...rest }: NotFoundProps) {
  return (
    <S.Container>
      <img src={NoDataImage} alt="No data img" />

      <h3>No data found</h3>
      <span>{title}</span>

      <button {...rest}>{buttonTitle}</button>
    </S.Container>
  );
}
