import { useState } from "react";
import { useContextSelector } from "use-context-selector";
import { ExploreItemsContext } from "../../../../../../contexts/ExploreItemsContext";
import { ConvertStringAddSpaceBeforeUppercase } from "../../../../../../shared/ConvertStringAddSpaceBeforeUppercase";
import { FilterOperator } from "../FilterOperator";
import * as S from "./styles";

export function FilterDetails() {
  const { allSelectedFilters, filtersBodyApi } = useContextSelector(
    ExploreItemsContext,
    (context) => {
      return context;
    },
  );

  const [colllectionSelected, setCollectionSelected] = useState({
    name: "",
    isOpen: false,
  });

  const handleOpeningDropdown = (collection: string) => {
    if (
      colllectionSelected.name &&
      colllectionSelected.isOpen &&
      colllectionSelected.name !== collection
    ) {
      setCollectionSelected({
        name: collection,
        isOpen: true,
      });
    } else {
      setCollectionSelected({
        name: collection,
        isOpen: !colllectionSelected.isOpen,
      });
    }
  };

  return (
    <>
      <S.FilterDetailsContainer>
        <span>Filters</span>
      </S.FilterDetailsContainer>

      {allSelectedFilters.map((collection) => {
        const currentCategoryIsOpen =
          colllectionSelected.name === collection.collection &&
          colllectionSelected.isOpen;

        return (
          collection.filters.length !== 0 && (
            <S.FilterCollectionContainer
              key={collection.collection}
              showFilters={currentCategoryIsOpen}
            >
              <S.FilterCollection
                showFilters={currentCategoryIsOpen}
                onClick={() => handleOpeningDropdown(collection.collection)}
              >
                <S.CollectionIndicator showFilters={currentCategoryIsOpen}>
                  {currentCategoryIsOpen ? (
                    <S.ReduceArrowIcon />
                  ) : (
                    <S.ExpandArrowIcon />
                  )}
                  <p>
                    {ConvertStringAddSpaceBeforeUppercase(
                      collection.collection,
                    )}
                  </p>
                  <span>{collection.filters.length}</span>
                </S.CollectionIndicator>
              </S.FilterCollection>

              {colllectionSelected.name === collection.collection &&
                colllectionSelected.isOpen &&
                collection.filters.map((filter) => {
                  const currentFilterValues = filtersBodyApi
                    .find(
                      (filters) =>
                        filters?.filters?.some(
                          (filterMap) => filterMap?.filter === filter?.key,
                        ),
                    )
                    ?.filters?.filter(
                      (filterFilter) => filterFilter.filter === filter.key,
                    )[0];

                  return (
                    <FilterOperator
                      key={filter.key}
                      filter={filter}
                      collection={collection.collection}
                      currentFilterValues={currentFilterValues}
                    />
                  );
                })}
            </S.FilterCollectionContainer>
          )
        );
      })}
    </>
  );
}
