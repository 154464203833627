import { Outlet } from "react-router-dom";
import Header from "../../components/Headers/AppHeader";
import { SideBarMenu } from "../../components/SideBarMenu";
import { useResponsive } from "../../hooks/useResponsive";
import * as S from "./styles";

export function DefaultLayout() {
  const { isResponsive } = useResponsive();

  return (
    <S.LayoutContainer isResponsive={isResponsive}>
      <SideBarMenu />
      <S.ContentContainer>
        <Header />
        <Outlet />
      </S.ContentContainer>
    </S.LayoutContainer>
  );
}
