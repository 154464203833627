import * as Dialog from "@radix-ui/react-dialog";

import * as S from "./styles";

interface ConfirmationProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
  toDelete?: boolean;
  variant?: "delete";
  opened?: boolean;
  closeModal?: () => void;
  confirmFunction: () => void;
}

export function ConfirmationModal({
  opened,
  children,
  title,
  description,
  toDelete,
  variant,
  closeModal,
  confirmFunction,
}: ConfirmationProps) {
  return (
    <Dialog.Root open={opened}>
      <S.ModalTrigger asChild>{children}</S.ModalTrigger>
      <Dialog.Portal>
        <S.Overlay />

        <S.Content>
          <S.ModalTitle>{title}</S.ModalTitle>

          {description && (
            <Dialog.Description asChild>
              <S.ModalDescription toDelete={toDelete}>
                {description}
              </S.ModalDescription>
            </Dialog.Description>
          )}

          <div>
            <Dialog.Close asChild>
              <S.ModalButton variant="transparent" onClick={closeModal}>
                Cancel
              </S.ModalButton>
            </Dialog.Close>
            <Dialog.Close asChild>
              <S.ModalButton
                toDelete={toDelete}
                onClick={confirmFunction}
                variant={variant}
              >
                Confirm
              </S.ModalButton>
            </Dialog.Close>
          </div>
        </S.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
