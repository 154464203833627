import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FaExclamationTriangle } from "react-icons/fa";
import { useContextSelector } from "use-context-selector";
import { UserSchemaTypes } from "../../../../../@types/User";
import { FormInput } from "../../../../../components/FormElements/FormInput";
import { PageHeader } from "../../../../../components/Headers/PageHeader";
import { Pagination } from "../../../../../components/Pagination";
import { SelectInput } from "../../../../../components/SelectInput";
import { StoresContext } from "../../../../../contexts/StoresContext";
import { useRegisterUsers } from "../../../../../hooks/api/Users/useRegisterUsers";
import { PermissionsModal } from "../components/PermissionsModal";
import { WebSelectStore } from "../components/WebSelectStore";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ZodType, z } from "zod";
import { createUserSchema, editUserSchema } from "./schemas";

import { UsersPermissionsContext } from "../../../../../contexts/UsersPermissions";
import { wrapperRequests } from "../../../../../services/api";
import { routesURL } from "../../../../../services/routesUrl";
import { colors } from "../../../../../styles/themes/default/foundations/colors";
import * as S from "./styles";

export function RegisterUser() {
  const [userDataToEdit, setUserDataToEdit] = useState({} as UserSchemaTypes);

  const [userStoresGranted, setUserStoresGranted] = useState<string[]>([]);
  const [userDefaultStore, setUserDefaultStore] = useState("");
  const [userUsePassword, setUserUsePassword] = useState(false);
  const [registerFormSchema, setRegisterFormSchema] = useState(
    {} as ZodType<any, any, any>,
  );

  type RegisterFormData = z.infer<typeof registerFormSchema>;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RegisterFormData>({
    resolver: zodResolver(registerFormSchema),
    defaultValues: userDataToEdit,
  });

  const {
    allStoresAllTenants,
    allStoresSelectPage,
    allStoresSelectRowsPerPage,
    fetchAllStoresAllTenants,
    storesLoading,
  } = useContextSelector(StoresContext, (context) => {
    return context;
  });

  const { createUser, registerUserLoading, registerUserError, updateUser } =
    useRegisterUsers();

  const {
    getAllRoles,
    allRoles,
    handleAddAcessNewRole,
    handleRemoveAcessRole,
    userRoles,
    getUserPermissions,
    handleDefaultUserRoles,
    updateUserPermissions,
  } = useContextSelector(UsersPermissionsContext, (context) => {
    return context;
  });

  const navigate = useNavigate();
  const { userId } = useParams();

  const isEditUser = userId;

  async function handleAddUser(data: RegisterFormData) {
    await createUser(data);
  }

  async function handleEditUser(data: RegisterFormData) {
    isEditUser && (await updateUser({ data, isEditUser }));
  }

  useEffect(() => {
    fetchAllStoresAllTenants();
  }, [fetchAllStoresAllTenants]);

  useEffect(() => {
    const loadUserToEdit = async () => {
      if (isEditUser) {
        try {
          const response = await wrapperRequests(
            routesURL.settings.users.getUser(isEditUser),
            "GET",
          );

          setUserDataToEdit(response.data);

          const storeId = response.data.shopsGranted.map(({ id }: any) => {
            return id;
          });
          setUserStoresGranted(storeId);
          setUserDefaultStore(response.data?.defaultShop?.id || "");
          setUserUsePassword(response.data?.usePassword ?? false);

          setValue("name", response.data.name);
          setValue("email", response.data.email);
          setValue("defaultShop", response.data?.defaultShop?.id || "");
          setValue("shopsGranted", storeId);
          setValue("usePassword", response.data?.usePassword ?? false);
          setValue("password", "");
          setValue("passwordConfirmation", "");
        } catch (error) {
          toast.error("User does not exist.", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/settings/users");
        }
      } else {
        setValue("defaultShop", "");
        setValue("shopsGranted", []);
        setValue("usePassword", false);
        setValue("password", "");
        setValue("passwordConfirmation", "");
      }
    };
    loadUserToEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditUser]);

  useEffect(() => {
    if (isEditUser) {
      return setRegisterFormSchema(
        editUserSchema({
          userDefaultStore,
          userStoresGranted,
          userUsePassword,
        }),
      );
    }
    setRegisterFormSchema(
      createUserSchema({
        userDefaultStore,
        userStoresGranted,
        userUsePassword,
      }),
    );
  }, [isEditUser, userDefaultStore, userStoresGranted, userUsePassword]);

  useEffect(() => {
    getAllRoles();

    if (isEditUser) {
      getUserPermissions(isEditUser);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allRoles.length && userDataToEdit.roles?.length > 0) {
      const defaultUserRoles = allRoles.filter(
        (role) => userDataToEdit.roles?.find((_role) => _role.id === role.id),
      );

      handleDefaultUserRoles(defaultUserRoles);
    }
  }, [allRoles, userDataToEdit.roles]);

  return (
    <S.Container>
      <PageHeader
        title={isEditUser ? "Edit user" : "Create new user"}
        customBreadcrumbString={
          userDataToEdit.name ? userDataToEdit.name : "Create user"
        }
      />

      <S.Form
        autoComplete="off"
        onSubmit={handleSubmit(isEditUser ? handleEditUser : handleAddUser)}
      >
        <S.InputRowContainer>
          <FormInput
            title="Name"
            type="text"
            error={errors?.name?.message ?? ""}
            placeholder="Enter name"
            {...register("name")}
          />

          <FormInput
            title="Email"
            type="email"
            disabled={!!isEditUser}
            autoComplete="new-password"
            error={errors?.email?.message ?? ""}
            placeholder="Enter email"
            {...register("email")}
          />
        </S.InputRowContainer>

        <S.InputRowContainer>
          <FormInput
            title="PIN"
            type="text"
            error={errors?.pin?.message ?? ""}
            placeholder="Enter PIN"
            {...register("pin")}
          />

          <SelectInput
            title="Remote access"
            placeholder={userUsePassword ? "On" : "Off"}
            variant="form"
            options={[
              {
                id: "1",
                value: "pin",
                label: "Off",
              },
              {
                id: "2",
                value: "password",
                label: "On",
              },
            ]}
            onChange={(e) => setUserUsePassword(e.target.value === "password")}
          />
        </S.InputRowContainer>

        {userUsePassword && (
          <S.InputRowContainer>
            <FormInput
              title="Password"
              type="password"
              autoComplete="new-password"
              error={errors?.password?.message ?? ""}
              placeholder="Enter password"
              {...register("password")}
            />

            <FormInput
              title="Confirm Password"
              type="password"
              error={errors?.passwordConfirmation?.message ?? ""}
              placeholder="Enter password"
              {...register("passwordConfirmation")}
            />
          </S.InputRowContainer>
        )}

        <S.InputRowContainer>
          <button
            disabled={registerUserLoading}
            onClick={() => {
              handleSubmit(isEditUser ? handleEditUser : handleAddUser);
            }}
          >
            {registerUserLoading ? (
              <ReactLoading height={20} width={20} type={"spin"} color="#fff" />
            ) : isEditUser ? (
              "Save"
            ) : (
              "Register"
            )}
          </button>
        </S.InputRowContainer>

        {errors.defaultShop?.message ||
        errors.shopsGranted?.message ||
        registerUserError ? (
          <div className="error_div">
            <FaExclamationTriangle />
            <label>
              {registerUserError && registerUserError}
              {errors.shopsGranted?.message && errors.shopsGranted?.message}
              {errors.defaultShop?.message && errors.defaultShop?.message}
            </label>
          </div>
        ) : null}

        {isEditUser && (
          <S.PermissionsContainer>
            <div>
              <h3>Tools and administration access</h3>
              <span>
                Access to tools and administration all stores in the
                organizations. This can include permissions to see and change
                data in all stores, regardiess specific store access
              </span>

              <S.RolesAcess>
                <SelectInput
                  title="Roles"
                  variant="roles"
                  onChange={(event) => {
                    handleAddAcessNewRole(
                      allRoles.find((role) => role.name === event.target.value),
                    );
                  }}
                  placeholder="Select an option"
                  options={allRoles.map((role) => ({
                    id: role.id,
                    value: role.name,
                    label: role.name,
                  }))}
                />

                {!!userRoles.length && (
                  <div>
                    <strong>Access granted</strong>
                    <ul>
                      {userRoles.map((role) => (
                        <li key={role.id} title={role.name}>
                          <span>{role.name}</span>

                          <IoMdClose
                            style={{ cursor: "pointer" }}
                            size={16}
                            color={colors["black-925"]}
                            onClick={() => {
                              handleRemoveAcessRole(role);
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </S.RolesAcess>
            </div>

            <PermissionsModal userId={userId} />
          </S.PermissionsContainer>
        )}
      </S.Form>

      <WebSelectStore
        stores={allStoresAllTenants.content}
        storesGranted={userStoresGranted}
        setStoresGranted={setUserStoresGranted}
        userDefaultStore={userDefaultStore}
        setUserDefaultStore={setUserDefaultStore}
        error={
          errors?.shopsGranted?.message || errors?.defaultShop?.message || ""
        }
        storesLoading={storesLoading}
      />

      <Pagination
        page={allStoresAllTenants.page}
        totalPages={allStoresAllTenants.totalPages}
        selectPage={allStoresSelectPage}
        selectRowsPerPage={allStoresSelectRowsPerPage}
      />
    </S.Container>
  );
}
