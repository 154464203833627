import styled, { css } from "styled-components";

interface WrapperInputProps {
  disabled: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSize.XXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  color: ${(props) => props.theme.colors["black-925"]};
  line-height: ${(props) => props.theme.lineHeight.Default};
`;

export const Wrapper = styled.div<WrapperInputProps>`
  width: min(22rem, 100%);
  height: 3rem;
  border: 1px solid ${(props) => props.theme.colors["gray-700"]};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 0 1rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${(props) => props.theme.colors["gray-400"]};
      opacity: 1;
    `};
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: transparent;
  background-color: ${(props) => props.theme.colors.white};

  font-size: ${(props) => props.theme.fontSize.XXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  color: ${(props) => props.theme.colors["gray-950"]};
  line-height: ${(props) => props.theme.lineHeight.Default};

  &:disabled {
    background-color: ${(props) => props.theme.colors["gray-400"]};
    opacity: 1;
  }
`;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors["red-500"]};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
`;
