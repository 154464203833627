import { ReactNode, useState } from "react";
import { createContext } from "use-context-selector";
import { wrapperRequests } from "../services/api";
import { routesURL } from "../services/routesUrl";

interface DuplicateItemsContextType {
  allTenants: TenantType[];
  fetchAllTenants: () => Promise<void>;
}

interface DuplicateItemsProviderProps {
  children: ReactNode;
}

interface TenantType {
  id: string;
  name: string;
}

export const DuplicateItemsContext = createContext(
  {} as DuplicateItemsContextType,
);

export function DuplicateItemsProvider({
  children,
}: DuplicateItemsProviderProps) {
  const [allTenants, setAllTenants] = useState<TenantType[]>([]);

  const fetchAllTenants = async () => {
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.stores.getAllTenants,
        "GET",
      );

      setAllTenants(data.content);
    } catch (error) {
      throw Error(String(error));
    }
  };

  return (
    <DuplicateItemsContext.Provider
      value={{
        allTenants,
        fetchAllTenants,
      }}
    >
      {children}
    </DuplicateItemsContext.Provider>
  );
}
