import { useEffect, useState } from "react";
import { IPermissions } from "../../../@types/User";

import { wrapperRequests } from "../../../services/api";
import { routesURL } from "../../../services/routesUrl";

interface UpdateUserPermissionsProps {
  userPermission?: IPermissions;
  type?: "all";
  selectAll?: boolean;
  direction: "up" | "down";
}

interface EditUserPermissionsProps {
  permissions: IPermissions[];
  userId: string;
}

export default function usePermissions() {
  const [permissions, setPermissions] = useState<IPermissions[]>([]);
  const [allPermissions, setAllPermissions] = useState<IPermissions[]>([]);
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [userPermissions, setUserPermissions] = useState<IPermissions[] | []>(
    permissions || [],
  );
  const [permissionMessage, setPermissionMessage] = useState("");

  async function fetchUserPermissions(userId: string) {
    setPermissionsLoading(true);
    try {
      const response = await wrapperRequests(
        routesURL.settings.users.getUserPermissions(userId),
        "GET",
      );

      setPermissions(response.data.permissions);
      setPermissionsLoading(false);

      return response.data.permissions;
    } catch (error) {
      setPermissionsLoading(false);
      throw Error(String(error));
    }
  }

  async function editUserPermissions({
    permissions,
    userId,
  }: EditUserPermissionsProps) {
    setPermissionsLoading(true);
    try {
      await wrapperRequests(
        routesURL.settings.users.editUserPermissions(userId),
        "POST",
        {
          data: { permissions },
        },
      );

      setPermissionMessage("Permissions Updated.");
      setPermissionsLoading(false);
      setTimeout(() => {
        setPermissionMessage("");
      }, 2000);
    } catch (error) {
      setPermissionMessage("Error updating permissions, please try again.");
      setPermissionsLoading(false);
      setTimeout(() => {
        setPermissionMessage("");
      }, 2000);
      throw Error(String(error));
    }
  }

  async function getAllPermissions() {
    try {
      const response = await wrapperRequests(
        routesURL.settings.users.getAllPermissions,
        "GET",
      );

      setAllPermissions(response.data.permissions);

      return response.data.permissions;
    } catch (error) {
      throw Error(String(error));
    }
  }

  function updateUserPermissions({
    userPermission,
    type,
    selectAll,
    direction,
  }: UpdateUserPermissionsProps) {
    if (type === "all") {
      const updateUserPermission = userPermissions.map((permission) => {
        const invertPermission = {
          ...permission,
          hasPermission: selectAll!,
        };

        return invertPermission;
      });

      return setUserPermissions(updateUserPermission);
    }

    const getPermissionTree = (
      userPermissions: IPermissions[],
      permissionToFind: IPermissions,
      direction: "up" | "down",
    ) => {
      function searchRelatedPermissions(
        userPermissions: IPermissions[],
        permissionToFind: IPermissions,
        direction: "up" | "down",
      ) {
        const foundPermissions: IPermissions[] = [];

        userPermissions.forEach((permission) => {
          const wasPermissionFound =
            direction === "up"
              ? permissionToFind.parentPermission ===
                permission?.permissionString
              : permissionToFind.permissionString ===
                permission?.parentPermission;

          if (wasPermissionFound) {
            foundPermissions.push(
              ...searchRelatedPermissions(
                userPermissions,
                permission,
                direction,
              ),
              permission,
            );
          }
        });

        return foundPermissions;
      }

      return [
        ...searchRelatedPermissions(
          userPermissions,
          permissionToFind,
          direction,
        ),
        permissionToFind,
      ];
    };

    const changeHasPermission =
      userPermission &&
      getPermissionTree(permissions, userPermission, direction).map(
        (permissionMap) => {
          const newHasPermissionValue = !userPermission.hasPermission;
          return {
            ...permissionMap,
            hasPermission: newHasPermissionValue,
          };
        },
      );

    const newPermissionUpdated = userPermissions.map((userPermissionMap) => {
      const samePermission = changeHasPermission?.find(
        (changeHasPermissionMap) =>
          changeHasPermissionMap.name === userPermissionMap.name,
      );

      if (samePermission) {
        return { ...samePermission };
      }

      return userPermissionMap;
    });

    setUserPermissions(newPermissionUpdated);
  }

  useEffect(() => {
    if (permissions) {
      setUserPermissions(permissions);
    }
  }, [permissions]);

  return {
    allPermissions,
    fetchUserPermissions,
    getAllPermissions,
    editUserPermissions,
    permissionsLoading,
    permissionMessage,
    userPermissions,
    updateUserPermissions,
  };
}
