import React from "react";

import { MenuProvider } from "./useMenu";
import { ResponsiveProvider } from "./useResponsive";
import { AuthProvider } from "./auth";

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <ResponsiveProvider>
        <MenuProvider>{children}</MenuProvider>
      </ResponsiveProvider>
    </AuthProvider>
  );
};

export default AppProvider;
