import styled from "styled-components";

export const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 16px;
    width: 16px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const CaOrderIdSpan = styled.span`
  display: flex;
  align-items: center;
  width: 100%;

  word-break: break-all;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 3rem;
    height: 2rem;
  }
`;
