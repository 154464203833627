import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { Button } from "../../../../components/Button";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { Pagination } from "../../../../components/Pagination";
import { SearchInput } from "../../../../components/SearchInput";
import { SelectInput } from "../../../../components/SelectInput";
import { StoresContext } from "../../../../contexts/StoresContext";
import { WebStoreList } from "./components/WebStoreList";

import { StoresListParams } from "../../../../@types/Stores";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";

export function Stores() {
  const { fetchAllTenants, allTenants } = useContextSelector(
    StoresContext,
    (context) => {
      return context;
    },
  );
  const navigate = useNavigate();
  const updatedParams = new URLSearchParams();

  const [storeSearchParams, setStoreSearchParams] = useSearchParams();
  const [stores, setStores] = useState<StoresListParams>({
    content: [],
    name: storeSearchParams.get("name") ?? "",
    page: storeSearchParams.get("page") ?? "1",
    limit: storeSearchParams.get("limit") ?? "5",
    sortBy: storeSearchParams.get("sortBy") ?? "",
    totalPages: storeSearchParams.get("totalPages") ?? "0",
    shopID: storeSearchParams.get("shopID") ?? "",
    tenantName: storeSearchParams.get("tenantName") ?? "",
  });
  const [loading, setLoading] = useState(false);

  const { tenantName, page, limit, shopID, name, sortBy, totalPages, content } =
    stores;

  const fetchAllStoresAllTenants = async () => {
    setLoading(true);
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.stores.getAllStores,
        "GET",
        {
          params: {
            tenantName,
            page,
            limit,
            shopID,
            sortBy,
            name,
          },
        },
      );

      setStores((state) => ({
        ...state,
        content: data.content,
        totalPages: data.totalPages || "0",
      }));
    } catch (error) {
      throw Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const updateStoreValue = (key: keyof StoresListParams, value: string) => {
    setStores((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const updateSearchParams = () => {
    name && updatedParams.set("name", name);
    shopID && updatedParams.set("shopID", shopID);
    tenantName && updatedParams.set("tenantName", tenantName);
    sortBy && updatedParams.set("sortBy", sortBy);

    updatedParams.set("limit", String(limit));
    updatedParams.set("page", "1");

    setStoreSearchParams(updatedParams);
  };

  const resetParams = () => {
    setStoreSearchParams({
      limit: "5",
      page: "1",
    });
  };

  const updateRows = (value: number) => {
    setStoreSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setStoreSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const updateSortBy = (value: string) => {
    setStoreSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("sortBy", String(value));
      return updatedParams;
    });
  };

  useEffect(() => {
    fetchAllStoresAllTenants();
    fetchAllTenants();
  }, []);

  return (
    <S.StoresContainer>
      <PageHeader
        title="Stores"
        description="Create stores or manage active stores in the system"
        hasButtons
        refreshFunction={() => {
          resetParams();
        }}
      />

      <S.StoresFiltersContainer>
        <div className="filters_align">
          <SearchInput
            title="Search for name"
            searchIcon
            variant="small"
            placeholder="Store"
            value={name}
            onPressEnter={() => updateSearchParams()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateStoreValue("name", event.target.value);
            }}
          />

          <SearchInput
            title="Search for Shop ID"
            searchIcon
            variant="small"
            placeholder="Shop ID"
            value={shopID}
            onPressEnter={() => updateSearchParams()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateStoreValue("shopID", event.target.value);
            }}
          />

          <SelectInput
            title="Filter by Region"
            placeholder="Select an option"
            value={tenantName}
            options={allTenants.map((tenantName) => ({
              id: tenantName.id,
              value: tenantName.name,
              label: tenantName.name,
            }))}
            onChange={(event) => {
              updateStoreValue("tenantName", event.target.value);
            }}
          />

          <Button disabled={loading} onClick={() => updateSearchParams()}>
            {loading ? (
              <ReactLoading height={24} width={24} type={"spin"} color="#fff" />
            ) : (
              "Search"
            )}
          </Button>
        </div>

        <Button disabled onClick={() => navigate("/settings/stores/create")}>
          New Store
        </Button>
      </S.StoresFiltersContainer>

      <S.Content>
        {!!content.length && (
          <WebStoreList
            storesData={content}
            storesLoading={loading}
            selectSortBy={updateSortBy}
          />
        )}
        <Pagination
          placeholder={String(limit)}
          selectPage={updatePage}
          page={Number(page)}
          selectRowsPerPage={updateRows}
          totalPages={Number(totalPages)}
        />
      </S.Content>
    </S.StoresContainer>
  );
}
