import styled, { css } from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const InputTitle = styled.span<{ variant?: "light" }>`
  font-weight: bold;
  color: ${({ variant, theme }) =>
    variant === "light" ? theme.colors["gray-600"] : theme.colors.black};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.1rem 1rem;
  gap: 0.5rem;
  height: 2.5rem;
  width: 100%; 
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};
  background-color: ${(props) => props.theme.colors.white};

  input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    font-family: ${(props) => props.theme.fontFamily.Sora};
    font-size: ${(props) => props.theme.fontSize.XXXS};
    color: ${(props) => props.theme.colors["gray-900"]};
    padding: 0.2rem;

    &::placeholder {
      color: ${(props) => props.theme.colors["gray-900"]};
    }
  }

  .icons-right {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: ${(props) => props.theme.colors["gray-900"]};
  }
`;


