export function routeName(pathname: string) {
  let routeName = "";

  if (pathname === "/") {
    return (routeName = "Stores Overview");
  }

  if (pathname.includes("/settings")) {
    return (routeName = "Settings");
  }

  if (pathname.includes("/stores")) {
    return (routeName = "Stores Overview");
  }

  if (pathname.includes("/inventory")) {
    return (routeName = "Inventory");
  }

  if (pathname.includes("/report")) {
    return (routeName = "Report");
  }

  if (pathname.includes("/profile")) {
    return (routeName = "Profile");
  }

  return routeName;
}
