import { InputHTMLAttributes, ReactNode, Ref, forwardRef } from "react";
import * as S from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  icon?: ReactNode;
  disabled?: boolean;
  errors?: string;
}

export const Input = forwardRef(
  (
    { label, icon, disabled = false, errors, ...rest }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <S.Container>
        <S.Label>{label}</S.Label>
        <S.Wrapper disabled={disabled}>
          {icon}
          <S.Input ref={ref} disabled={disabled} {...rest} />
        </S.Wrapper>
        {errors && <S.ErrorMessage>{errors}</S.ErrorMessage>}
      </S.Container>
    );
  },
);
