import { forwardRef } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import PhoneInput from "react-phone-number-input/input";

import * as S from "./styles";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

interface FormInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  title: string;
  isPhone?: boolean;
  phoneOnChange?: (value: any) => void;
  phonePlaceholder?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
}

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    { title, isPhone, phoneOnChange, phonePlaceholder, error, ...rest },
    ref
  ) => {
    return (
      <S.FormInputContainer>
        <S.ErrorAlignContainer>
          <p>{title}</p>
          {error && (
            <div className='error_div'>
              <FaExclamationTriangle />
              <label>{error}</label>
            </div>
          )}
        </S.ErrorAlignContainer>
        <div className='align_error'>
          {isPhone ? (
            <PhoneInput
              placeholder={
                phonePlaceholder ? phonePlaceholder : "Enter phone number"
              }
              maxLength={14}
              onChange={(value) => (phoneOnChange ? phoneOnChange(value) : {})}
              country='US'
            />
          ) : (
            <input {...rest} ref={ref} />
          )}
        </div>
      </S.FormInputContainer>
    );
  }
);
