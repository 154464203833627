import { IoMdDownload } from "react-icons/io";
import { MdRefresh, MdSearch } from "react-icons/md";
import ReactLoading from "react-loading";
import { Breadcrumb } from "../../Breadcrumb";

import { useTheme } from "styled-components";
import { OrderStatus, OrderStatusConverter } from "../../../enums/Orders";
import { StatusTag } from "../../../pages/Report/pages/Orders/components/OrdersList/styles";
import * as S from "./styles";

interface PageHeaderProps {
  buttonType?: "search";
  children?: React.ReactNode;
  customBreadcrumbString?: string;
  customButton?: () => void;
  description?: string;
  downloadFunction?: () => void;
  hasButtons?: boolean;
  hasBorder?: boolean;
  loading?: {
    type: "filters" | "data" | "csv";
    isLoading: boolean;
  };

  refreshFunction?: () => void;
  orderDetailsStatus?: OrderStatus | "loading";
  title: string;
}

export function PageHeader({
  children,
  buttonType,
  title,
  description,
  hasButtons,
  hasBorder = true,
  loading,
  customBreadcrumbString,
  customButton,
  downloadFunction,
  refreshFunction,
  orderDetailsStatus,
}: PageHeaderProps) {
  const theme = useTheme();
  return (
    <S.PageHeader hasBorder={hasBorder}>
      <Breadcrumb
        customBreadcrumbString={
          customBreadcrumbString && customBreadcrumbString
        }
      />

      {!orderDetailsStatus && <h2>{title}</h2>}

      {orderDetailsStatus && (
        <S.AlignTitle>
          <h2>{title}</h2>
          <StatusTag
            status={orderDetailsStatus}
            title={
              orderDetailsStatus !== "loading"
                ? OrderStatusConverter[orderDetailsStatus]
                : ""
            }
          >
            {orderDetailsStatus === "loading" ? (
              <ReactLoading
                height="100%"
                width={18}
                type={"spin"}
                color={theme.colors["gray-800"]}
              />
            ) : (
              OrderStatusConverter[orderDetailsStatus]
            )}
          </StatusTag>
        </S.AlignTitle>
      )}

      {(hasButtons || description) && (
        <S.DescriptionContainer>
          {description && <span>{description}</span>}
          {(hasButtons || customButton) && (
            <S.ButtonContainer>
              {hasButtons && (
                <>
                  <button                        
                    className="default-button"
                    disabled={loading?.type === "csv" && loading.isLoading}
                    onClick={downloadFunction}
                  >
                    {loading?.type === "csv" && loading.isLoading ? (
                      <ReactLoading
                        height={24}
                        width={24}
                        type={"spin"}
                        color={theme.colors["gray-800"]}
                      />
                    ) : (
                      <IoMdDownload />
                    )}
                  </button>
                  <button                        
                    className="default-button"
                    title={buttonType === "search" ? "Search" : "Refresh page"}
                    disabled={loading?.type === "data" && loading.isLoading}
                    onClick={refreshFunction}
                  >
                    {loading?.type === "data" && loading.isLoading ? (
                      <ReactLoading
                        height={24}
                        width={24}
                        type={"spin"}
                        color={theme.colors["gray-800"]}
                      />
                    ) : buttonType === "search" ? (
                      <MdSearch />
                    ) : (
                      <MdRefresh />
                    )}
                  </button>
                </>
              )}

              {customButton && children}
            </S.ButtonContainer>
          )}
        </S.DescriptionContainer>
      )}
    </S.PageHeader>
  );
}
