import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Button } from "../../../../components/Button";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { Pagination } from "../../../../components/Pagination";
import { SearchInput } from "../../../../components/SearchInput";
import useItemSearch from "../../../../hooks/api/Inventory/useItemSearch";
import { ItemSearchList } from "./ItemSearchList";

import { useSearchParams } from "react-router-dom";
import { ItemsParams } from "../../../../@types/InventoryItems";
import { useAuth } from "../../../../hooks/auth";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";

export function ItemSearch() {
  const { selectSortBy } = useItemSearch();

  const { shopSelected } = useAuth();

  const updatedParams = new URLSearchParams();
  const [itemsSearchParams, setItemsSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ItemsParams>({
    content: [],
    name: itemsSearchParams.get("name") ?? "",
    page: itemsSearchParams.get("page") ?? "1",
    limit: itemsSearchParams.get("limit") ?? "5",
    sortBy: itemsSearchParams.get("sortBy") ?? "",
    totalPages: itemsSearchParams.get("totalPages") ?? "0",
    tenantName: shopSelected.tenantName,
    search: itemsSearchParams.get("search") ?? "",
  });

  const { content, limit, name, page, search, sortBy, tenantName, totalPages } =
    items;

  const fetchItems = async () => {
    setLoading(true);
    try {
      const { data } = await wrapperRequests(
        routesURL.inventory.itemSearch.getAllItems(shopSelected.code),
        "GET",
        {
          params: {
            tenantName: shopSelected.tenantName,
            page,
            limit,
            search,
            name,
            sortBy,
          },
        },
      );

      setItems((state) => {
        return {
          ...state,
          content: data.content,
          totalPages: data.totalPages,
        };
      });
    } catch (error) {
      throw new Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const resetParams = () => {
    setItemsSearchParams({
      limit: "5",
      page: "1",
    });
  };

  const updateItemsValue = (key: keyof ItemsParams, value: string) => {
    setItems((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const updateSearchParams = () => {
    name && updatedParams.set("name", name);
    search && updatedParams.set("search", search);
    tenantName && updatedParams.set("tenantName", tenantName);
    sortBy && updatedParams.set("sortBy", sortBy);

    updatedParams.set("limit", String(limit));
    updatedParams.set("page", "1");

    setItemsSearchParams(updatedParams);
  };

  const updateRows = (value: number) => {
    setItemsSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setItemsSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const updateSortBy = (value: string) => {
    setItemsSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("sortBy", String(value));
      return updatedParams;
    });
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <S.ItemSearchContainer>
      <PageHeader
        title="Item Search"
        description={"Search for items in your store's inventory"}
        hasButtons
        refreshFunction={() => resetParams()}
      />

      <S.ItemSearchFiltersContainer>
        <div className="filters_align">
          <SearchInput
            title="Search by identifier"
            searchIcon
            value={items.search}
            placeholder="Enter a SKU, UPC or EAN"
            onPressEnter={() => updateSearchParams()}
            onChange={(event) => updateItemsValue("search", event.target.value)}
          />

          <SearchInput
            title="Search for item name"
            searchIcon
            placeholder="Enter a item name"
            value={items.name}
            onPressEnter={() => updateSearchParams()}
            onChange={(event) => updateItemsValue("name", event.target.value)}
          />

          <Button disabled={loading} onClick={() => updateSearchParams()}>
            {false ? (
              <ReactLoading height={24} width={24} type={"spin"} color="#fff" />
            ) : (
              "Search"
            )}
          </Button>
        </div>
      </S.ItemSearchFiltersContainer>

      <>
        <ItemSearchList
          data={content}
          loading={loading}
          selectSortBy={updateSortBy}
        />

        <Pagination
          placeholder={String(limit)}
          selectPage={updatePage}
          page={Number(page)}
          selectRowsPerPage={updateRows}
          totalPages={Number(totalPages)}
        />
      </>
    </S.ItemSearchContainer>
  );
}
