import styled, { css } from "styled-components";

interface LayoutContainerProps {
  isResponsive: boolean;
}

export const LayoutContainer = styled.div<LayoutContainerProps>`
  display: flex;

  margin-left: var(--sideBarWidth);

  ${(props) =>
    props.isResponsive &&
    css`
      margin-left: var(--sideBarWidthResponsive);
    `};
`;

export const ContentContainer = styled.main`
  flex: 1;
`;
