import { CgArrowUpR } from "react-icons/cg";
import { IoMdCalendar } from "react-icons/io";
import ReactLoading from "react-loading";
import userPicture from "../../assets/images/white-logo.svg";
import { Button } from "../../components/Button";

import { useState } from "react";
import { useTheme } from "styled-components";
import { SelectInput } from "../../components/SelectInput";
import { useAuth } from "../../hooks/auth";
import { wrapperRequests } from "../../services/api";
import { Input } from "./components/Input";
import * as S from "./styles";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ToastPosition, toast } from "react-toastify";
import * as z from "zod";
import { routesURL } from "../../services/routesUrl";

const schema = z.object({
  pin: z.union([
    z.string().length(4, { message: "PIN must be 4 digits or more" }),
    z.string().min(4, { message: "PIN must be 4 digits or more" }),
  ]),
});

type UpdateUserSchema = z.infer<typeof schema>;

export function Profile() {
  const { colors } = useTheme();
  const { user } = useAuth();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UpdateUserSchema>({
    resolver: zodResolver(schema),
    reValidateMode: "onSubmit",
  });

  const [loadings, setLoadings] = useState({
    updatePIN: false,
  });
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");

  const separateName = user.user.name.split(" ");

  const gendersOptions = [{ id: "1", value: "", label: "Select an Option" }];

  const calendarIcon = (
    <IoMdCalendar size={"1.5rem"} color={colors["gray-800"]} />
  );

  const toastify = (message: string, position: ToastPosition) => {
    toast.success(message, {
      position,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateLoadingState = (key: string, value: boolean) => {
    setLoadings((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const handleUpdatePIN = async (data: UpdateUserSchema) => {
    updateLoadingState("updatePIN", true);
    try {
      await wrapperRequests(
        routesURL.settings.users.editUser(user.user.id),
        "PUT",
        { data },
      );

      toastify("🚴‍♂️ User edited successfully!", "top-center");
      setUpdateErrorMessage("");
    } catch (error: any) {
      setUpdateErrorMessage(error.response?.data?.message || "Duplicated PIN");
      throw Error(String(error));
    } finally {
      updateLoadingState("updatePIN", false);
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.ProfilePicture>
          <h2>Profile picture</h2>
          <S.Wrapper>
            <S.UserImage>
              <img src={userPicture} />
            </S.UserImage>

            <S.ActionButtons>
              <Button disabled>
                <div>
                  <p>Upload</p>
                  <CgArrowUpR size={"1.5rem"} />
                </div>
              </Button>

              <Button variant="gray" disabled>
                <div>
                  <p>Delete</p>
                </div>
              </Button>
            </S.ActionButtons>
          </S.Wrapper>
        </S.ProfilePicture>

        <S.PersonalInformation>
          <h2>Personal information</h2>

          <S.UserDataContainer>
            <Input
              label="Name"
              type="text"
              defaultValue={separateName[0]}
              disabled
            />
            <Input
              label="Last Name"
              type="text"
              defaultValue={separateName[1]}
              disabled
            />
            <SelectInput
              title="Gender"
              variant="profile"
              options={gendersOptions}
              onChange={() => {}}
              disabled
            />
            <Input label="Birth date" icon={calendarIcon} disabled />
            <Input
              label="Email"
              defaultValue={user.user.email}
              type="email"
              disabled
            />
            <Input label="Phone number" disabled />
          </S.UserDataContainer>
        </S.PersonalInformation>

        <S.LoginSecurity>
          <h2>Login and security</h2>

          <S.UpdatePasswordContainer>
            {/* <S.WrapperUpdateAt>
                <div className="wrapper_text">
                  <h3>Password and PIN</h3>
                  <p>Last updated a {formattedTimeSinceUpdatedPassword} ago</p>
                </div>
                <button onClick={() => setPasswordUpdate(true)}>Update</button>
              </S.WrapperUpdateAt>
           */}

            <S.WrapperChangePassword>
              <form className="form_update_password">
                <h3>Password</h3>
                <Input label="Current password" type="password" disabled />
                <Input label="New Password" type="password" disabled />

                <Button disabled>
                  <p>Update</p>
                </Button>
              </form>

              <form
                className="form_update_pin"
                onSubmit={handleSubmit(handleUpdatePIN)}
              >
                <h3>PIN</h3>

                <div>
                  <Input
                    type="password"
                    errors={errors.pin?.message || updateErrorMessage}
                    {...register("pin")}
                  />

                  <Button type="submit">
                    {loadings.updatePIN ? (
                      <ReactLoading
                        height={24}
                        width={24}
                        type={"spin"}
                        color={colors.white}
                      />
                    ) : (
                      <p>Update</p>
                    )}
                  </Button>
                </div>
              </form>
            </S.WrapperChangePassword>
          </S.UpdatePasswordContainer>
        </S.LoginSecurity>
      </S.Content>
    </S.Container>
  );
}
