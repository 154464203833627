import * as Accordion from "@radix-ui/react-accordion";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

import { useState } from "react";
import { colors } from "../../../../../../styles/themes/default/foundations/colors";
import * as S from "./styles";

interface OrdersAccordionProps {
  title: string;
  children: React.ReactNode;
}

export function OrdersAccordion({ title, children }: OrdersAccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.AccordionContainer type="single" collapsible defaultValue="item-1">
      <Accordion.Item value="item-1">
        <Accordion.Header>
          <Accordion.Trigger asChild onClick={() => setIsOpen(!isOpen)}>
            <S.AccordionButtonContainer>
              {isOpen ? (
                <IoChevronUp size={24} color={colors["black-925"]} />
              ) : (
                <IoChevronDown size={24} color={colors["black-925"]} />
              )}
              <S.AccordionTitle>{title}</S.AccordionTitle>
            </S.AccordionButtonContainer>
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>{children}</Accordion.Content>
      </Accordion.Item>
    </S.AccordionContainer>
  );
}
