import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
`;

export const Content = styled(Dialog.Content)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;

  border-radius: 1rem;
  padding: 2rem;
  background: ${(props) => props.theme.colors.white};

  width: 24rem;
  height: 19rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Message = styled(Dialog.Description)`
  text-align: center;
  color: ${(props) => props.theme.colors["red-500"]};
  font-size: ${(props) => props.theme.fontSize.XS};
`;

export const ModalButton = styled.button`
  height: 3.5rem;
  width: 10.25rem;

  border-radius: ${(props) => props.theme.borderRadius.SM};

  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors.white};
  border: transparent;
  background-color: ${(props) => props.theme.colors["red-450"]};

  &:hover {
    border: none;
    background-color: ${(props) => props.theme.colors["red-300"]};
    transition: background-color 0.2s;
  }
`;
