import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import ReactLoading from "react-loading";
import { DuplicateItemsProps } from "../..";
import { Checkbox } from "../../../../../../components/Checkbox";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../../../../../components/TableElements";
import { useAuth } from "../../../../../../hooks/auth";
import { permissions } from "../../../../../../shared/Permissions";

import { CopyContainer } from "../../../../../../components/TableElements/components/CopyContainer";
import * as S from "./styles";

interface DuplicateItemsListProps {
  duplicateItemsData?: DuplicateItemsProps[];
  duplicateItemsLoading: boolean;
  handleSubmitDuplicateItem: (itemId: string) => Promise<void>;
  selectedButton: boolean;
}

export function DuplicateItemsList({
  duplicateItemsData,
  duplicateItemsLoading,
  handleSubmitDuplicateItem,
  selectedButton,
}: DuplicateItemsListProps) {
  const [collapsible, setCollapsible] = useState({
    active: false,
    itemId: "",
  });

  const { user } = useAuth();

  return (
    <S.DuplicateItemsListContainer
      duplicateItemsLoading={duplicateItemsLoading}
      selectedButton={selectedButton}
    >
      {duplicateItemsLoading ? (
        <ReactLoading height={35} width={35} type={"spin"} color="#000" />
      ) : (
        <TableComponent>
          <thead>
            <tr>
              <ThComponent withArrows={false} />
              <ThComponent thWidth={"110px"} withArrows={false}>
                Region
              </ThComponent>
              <ThComponent withArrows={false}>UPC/SKU/EAN</ThComponent>
              <ThComponent withArrows={false}>System ID</ThComponent>
              <ThComponent withArrows={false}>Region QOH</ThComponent>
              <ThComponent withArrows={false}>Brand</ThComponent>
              <ThComponent withArrows={false}>Description</ThComponent>
            </tr>
          </thead>

          <tbody>
            {duplicateItemsData &&
              duplicateItemsData.map((item) => {
                const skuQuantity = item.systemSkus.length;
                const hasMoreThanTwoSku = skuQuantity > 2;

                return (
                  <React.Fragment key={item.id}>
                    <TrComponent>
                      <TdComponent tdPadding={"1rem 0 1rem 2rem"}>
                        {user.user.permissions.includes(
                          permissions.report.duplicatedItems.add,
                        ) && (
                          <Checkbox
                            onCheckedChange={() =>
                              handleSubmitDuplicateItem(item.id)
                            }
                          />
                        )}
                      </TdComponent>
                      <TdComponent tdWidth={"10px"} tdPadding={"1rem"}>
                        {item.tenantName}
                      </TdComponent>
                      <S.CustomTd>
                        {item && item.upc && (
                          <span className="upc_sku_ean">
                            <CopyContainer stringValue={item.upc} />
                          </span>
                        )}

                        {item && item.sku && (
                          <span className="upc_sku_ean">
                            <CopyContainer stringValue={item.sku} />
                          </span>
                        )}

                        {(!item.upc || !item.sku) && item.ean && (
                          <span className="upc_sku_ean">
                            <CopyContainer stringValue={item.ean} />
                          </span>
                        )}
                      </S.CustomTd>
                      <S.CustomTd>
                        {hasMoreThanTwoSku ? (
                          <div className="count_align">
                            <CopyContainer stringValue={item.systemSkus[0]} />
                            <S.CountCircle>+{skuQuantity - 1}</S.CountCircle>
                          </div>
                        ) : (
                          item.systemSkus.map((sku, index) => (
                            <div className="count_align">
                              <CopyContainer key={index} stringValue={sku} />
                            </div>
                          ))
                        )}
                      </S.CustomTd>
                      <S.CustomTd>
                        {hasMoreThanTwoSku ? (
                          <div className="count_align">
                            <span>{item.quantitiesOnHand[0]}</span>
                          </div>
                        ) : (
                          item.quantitiesOnHand.map((qoh, index) => (
                            <span key={index}>{qoh}</span>
                          ))
                        )}
                      </S.CustomTd>
                      <TdComponent tdPadding={"1rem"}>
                        {item.brands[0]}
                      </TdComponent>
                      <S.CustomTd>
                        {hasMoreThanTwoSku ? (
                          <div className="description">
                            <span>- {item.descriptions[0]}</span>
                            {collapsible.active &&
                            collapsible.itemId === item.id ? (
                              <MdKeyboardArrowUp
                                size={24}
                                onClick={() =>
                                  setCollapsible(({ active }) => {
                                    return { active: !active, itemId: item.id };
                                  })
                                }
                              />
                            ) : (
                              <MdKeyboardArrowDown
                                size={24}
                                onClick={() =>
                                  setCollapsible(({ active }) => {
                                    return { active: !active, itemId: item.id };
                                  })
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <div className="description">
                            <div>
                              {item.descriptions.map((item, index) => (
                                <span key={index}>- {item}</span>
                              ))}
                            </div>
                          </div>
                        )}
                      </S.CustomTd>
                    </TrComponent>
                    {collapsible.active &&
                      collapsible.itemId === item.id &&
                      item.systemSkus.slice(1).map((sku, index) => (
                        <tr className="collapsible" key={index}>
                          <TdComponent tdPadding={"1rem 0 1rem 2rem"}>
                            {/* <Checkbox /> */}
                          </TdComponent>
                          <TdComponent>{item.tenantName}</TdComponent>
                          <S.CustomTd>
                            {item && item.upc && (
                              <span className="upc_sku_ean">
                                <CopyContainer stringValue={item.upc} />
                              </span>
                            )}

                            {item && item.sku && (
                              <span className="upc_sku_ean">
                                <CopyContainer stringValue={item.sku} />
                              </span>
                            )}

                            {(!item.upc || !item.sku) && item.ean && (
                              <span className="upc_sku_ean">
                                <CopyContainer stringValue={item.ean} />
                              </span>
                            )}
                          </S.CustomTd>
                          <S.CustomTd>
                            <span>
                              <CopyContainer stringValue={sku} />
                            </span>
                          </S.CustomTd>
                          <TdComponent>
                            {item.quantitiesOnHand[index + 1]}
                          </TdComponent>
                          <TdComponent>{item.brands[0]}</TdComponent>
                          <TdComponent>
                            - {item.descriptions[index + 1]}
                          </TdComponent>
                        </tr>
                      ))}
                    <tr className="separator" />
                  </React.Fragment>
                );
              })}
          </tbody>
        </TableComponent>
      )}
    </S.DuplicateItemsListContainer>
  );
}
