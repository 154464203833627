import * as Dialog from "@radix-ui/react-dialog";
import styled, { css } from "styled-components";

export const ModalTrigger = styled(Dialog.Trigger)`
  border: none;
  background-color: transparent;
`;

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
`;

export const Content = styled(Dialog.Content)`
  display: flex;
  align-items: center;
  flex-direction: column;

  border-radius: 1rem;
  padding: 3rem;
  background: ${(props) => props.theme.colors.white};

  width: 28rem;
  height: 19rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
  }
`;

export const ModalTitle = styled(Dialog.Title)`
  color: ${(props) => props.theme.colors["gray-900"]};
  text-align: center;
`;

interface ModalButtonProps {
  variant?: "transparent" | "delete";
  toDelete?: boolean;
}

export const ModalDescription = styled.p<ModalButtonProps>`
  margin-top: 1rem;

  ${(props) =>
    props.variant === "delete" &&
    css`
      color: ${(props) => props.theme.colors["red-700"]};
    `};
`;

export const ModalButton = styled.button<ModalButtonProps>`
  height: 3.5rem;
  width: 10.25rem;

  border-radius: ${(props) => props.theme.borderRadius.SM};
  border: 1px solid ${(props) => props.theme.colors["blue-400"]};
  background-color: ${(props) => props.theme.colors["blue-600"]};

  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors.white};

  &:hover {
    border: none;
    background-color: ${(props) => props.theme.colors["gray-400"]};
    color: ${(props) => props.theme.colors["gray-900"]};
    transition: background-color 0.2s;
  }

  ${(props) =>
    props.variant === "transparent" &&
    css`
      color: ${(props) => props.theme.colors["gray-900"]};
      background-color: transparent;
    `};

  ${(props) =>
    props.variant === "delete" &&
    css`
      background-color: ${(props) => props.theme.colors["red-450"]};

      &:hover {
        border: none;
        background-color: ${(props) => props.theme.colors["red-300"]};
        color: ${(props) => props.theme.colors["gray-900"]};
        transition: background-color 0.2s;
      }
    `};
`;
