import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;

  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors["black-900"]};
`;

export const Content = styled.form`
  display: flex;

  align-items: center;
  flex-direction: column;

  width: 20%;
  min-width: 350px;

  padding: 16px 32px 48px 32px;

  img {
    width: 7.8rem;
    height: 3rem;

    margin-bottom: 2rem;
    border-radius: 50%;
  }

  div.error_div {
    display: flex;
    justify-content: flex-start;

    width: 100%;
    margin-top: 4px;

    color: var(--red);

    svg {
      margin-right: 4px;
    }
  }
`;

export const Title = styled.label`
  align-self: flex-start;

  font-size: ${(props) => props.theme.fontSize.XXS};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  color: ${(props) => props.theme.colors["gray-300"]};

  padding-bottom: 0.5rem;
  margin-top: 1.5rem;
`;

interface InputContainerProps {
  filled: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;

  width: 100%;
  border: 1px solid white;
  border-radius: ${(props) => props.theme.borderRadius.SM};
  border: none;

  background: ${(props) => props.theme.colors["gray-900"]};

  gap: 0.5rem;
  padding: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;

    color: ${(props) => props.theme.colors["gray-650"]};
  }

  &:focus-within {
    outline: 1px solid ${(props) => props.theme.colors["blue-600"]};
    background: transparent;

    svg {
      color: ${(props) => props.theme.colors["gray-300"]};
    }
  }

  ${(props) =>
    props.filled &&
    css`
      outline: 1px solid ${(props) => props.theme.colors["blue-600"]};
      background: transparent;

      svg {
        color: ${(props) => props.theme.colors["gray-300"]};
      }
    `};
`;

export const Input = styled.input`
  width: 100%;

  background: transparent;
  color: ${(props) => props.theme.colors["gray-300"]};
  border: none;

  font-family: ${(props) => props.theme.fontFamily.Sora};
`;

export const PinSignInButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 3rem;

  background: ${(props) => props.theme.colors["blue-600"]};
  text-decoration: none;

  border: none;
  border-radius: ${(props) => props.theme.borderRadius.SMM};

  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors.white};

  margin-top: 2rem;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
    outline: ${(props) => props.theme.borderWidth.Thin} solid
      ${(props) => props.theme.colors["blue-800"]};
  }
`;

export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;

  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};

  gap: 0.25rem;

  span {
    color: ${(props) => props.theme.colors.white};
  }

  button {
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors["red-500"]};

    font-family: ${(props) => props.theme.fontFamily.Sora};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    text-decoration: underline;
  }
`;

export const VersionContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  p {
    font-style: italic;
    text-align: center;

    color: ${(props) => props.theme.colors["gray-700"]};
  }
`;
