import { MdContentCopy } from "react-icons/md";
import styled, { css } from "styled-components";

interface DuplicateItemsListContainerProps {
  duplicateItemsLoading: boolean;
  selectedButton: boolean;
}

export const DuplicateItemsListContainer = styled.main<DuplicateItemsListContainerProps>`
  flex: 1;

  table {
    border-spacing: 0;

    tr.separator {
      height: 16px;
    }

    th {
      padding: 0rem 1rem;
    }
  }

  tbody:before {
    content: "@";
    display: block;
    line-height: 1rem;
    text-indent: -99999px;
  }

  tr.collapsible {
    box-shadow: ${(props) => props.theme.shadow["Level 1"]};
    border-radius: 8px;

    td {
      border-radius: 0;
      padding: 0rem 1rem;
    }
  }

  ${(props) =>
    props.duplicateItemsLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;

      height: 50vh;
    `};

  ${(props) =>
    props.selectedButton === true &&
    css`
      td {
        color: ${(props) => props.theme.colors["gray-700"]};
        text-decoration-line: line-through;
      }
    `};
`;

export const CustomTd = styled.td`
  height: 4rem;
  padding: 1rem 1rem;
  border: 0;

  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors["gray-900"]};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-size: ${(props) => props.theme.fontSize.XXXS};

  div.count_align {
    display: flex;
    align-items: center;

    span {
      flex: 1;
    }
  }

  div.description {
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-width: 20rem;
    width: auto;

    div {
      flex-direction: column;
    }

    span {
      width: 100%;
    }

    svg {
      min-width: 28px;
      min-height: 28px;
      cursor: pointer;
    }
  }

  span {
    display: flex;
    align-items: center;

    width: 9rem;
  }

  span.upc_sku_ean {
    width: 180px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }

  svg {
    margin: 4px;
    vertical-align: middle;

    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const CountCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  border-radius: 50%;

  background-color: ${(props) => props.theme.colors["green-450"]};
  color: ${(props) => props.theme.colors.black};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-size: ${(props) => props.theme.fontSize.Nano};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
`;

export const CopyIcon = styled(MdContentCopy)`
  margin: 4px;
  vertical-align: middle;

  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
`;
