import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { CardLinkButton } from "../../components/CardLinkButton";
import { useAuth } from "../../hooks/auth";
import { permissions } from "../../shared/Permissions";

import * as S from "./styles";

export function Inventory() {
  const { user } = useAuth();
  return (
    <S.InventoryContainer>
      {user.user.permissions.includes(
        permissions.inventory.itemSearch.view,
      ) && (
        <CardLinkButton
          href="/inventory/item-search"
          title="Item Search"
          description={"Search for items in your store's inventory"}
        >
          <MdOutlineScreenSearchDesktop />
        </CardLinkButton>
      )}
    </S.InventoryContainer>
  );
}
