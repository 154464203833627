import styled from "styled-components";

export const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.black};
  }

  input {
    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
    color: ${(props) => props.theme.colors["gray-800"]};
    background-color: ${(props) => props.theme.colors.white};

    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-family: ${(props) => props.theme.fontFamily.Sora};

    padding: 1rem;
    width: 22rem;
    max-height: 3rem;

    &:hover {
      outline: 1px solid ${(props) => props.theme.colors["gray-650"]};
    }
  }
`;

export const ErrorAlignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 1rem 0 0.5rem;
`;
