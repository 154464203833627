import styled from "styled-components";

export const ItemDetailsContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 3rem;
`;

export const ItemDetailsDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 2rem;
  gap: 1rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: ${(props) => props.theme.colors["black-950"]};
    
    h3 {
      font-weight: ${(props) => props.theme.fontWeight.Bold};
      font-size: ${(props) => props.theme.fontSize.XXS};
      font-family: ${(props) => props.theme.fontFamily.Roboto};
    }
  }

  span {
    font-size: 1rem;
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    color: ${(props) => props.theme.colors["gray-800"]};

    max-width: 41rem;
  }
`;

export const ItemDetailsCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 1rem;
  margin-top: 1.5rem;
`;
