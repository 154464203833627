import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import ContextsProvider from "./contexts";
import AppProvider from "./hooks";
import { defaultTheme } from "./styles/themes/default/default";

import GlobalStyle from "./styles/global";

import Routes from "./Router";

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <ContextsProvider>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyle />
            <ToastContainer autoClose={3000} />
            <Routes />
          </ThemeProvider>
        </ContextsProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
