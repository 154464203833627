import React, { createContext, useContext, useEffect, useState } from "react";

interface ResponsiveContextData {
  screenWidth: number;
  isResponsive: boolean;
  changeIsResponsive: () => void;
}

const ResponsiveContext = createContext({} as ResponsiveContextData);

const ResponsiveProvider: React.FC = ({ children }) => {
  const [isResponsive, setIsResponsive] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function changeIsResponsive() {
    setIsResponsive(!isResponsive);
  }

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);

  useEffect(() => {
    if (screenWidth <= 1600) {
      setIsResponsive(true);
    }
  }, [screenWidth]);

  return (
    <ResponsiveContext.Provider
      value={{
        changeIsResponsive,
        isResponsive,
        screenWidth,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};

function useResponsive() {
  const context = useContext(ResponsiveContext);

  if (!context) {
    throw new Error("useResponsive must be used within an provider.");
  }

  return context;
}

export { ResponsiveProvider, useResponsive };
