import { IPermissions } from "../@types/User";
import { wrapperRequests } from "../services/api";

type Route = keyof typeof permissions;

export interface AuthorizedPermissionsProps {
  name: string;
  hasAuthorization: boolean;
}

export interface HasPermissionProps {
  routeName: Route;
  userPermissions: IPermissions[] | [];
}

export const permissions = {
  menu: {
    inventory: "web_view_inventory",
    report: "web_view_report",
    settings: "web_view_settings",
  },

  inventory: {
    itemSearch: {
      view: "web_view_inventory_item_search",
      edit: "web_edit_inventory_item_search",
    },
  },

  report: {
    duplicatedItems: {
      view: "web_view_report_duplicate_items",
      add: "web_add_report_duplicate_items_check_pending_import",
    },
    orders: {
      view: "web_view_report_orders",
      edit: "web_edit_report_orders_force_sync_order",
    },
    explore: {
      view: "web_view_report_explore_products",
    },
    exploreAccountingSales: {
      view: "web_view_report_accounting_sales",
    },
  },

  settings: {
    stores: "web_view_settings_stores",
    user: "web_view_settings_user_management",
    roles: "web_view_settings_roles",
  },
};

function routePermissions(route: Route) {
  const routePermissions = permissions[route];
  return routePermissions;
}

export function hasPermission({
  routeName,
  userPermissions,
}: HasPermissionProps) {
  const neededPermissions = routePermissions(routeName);
}

export function filterAuthorizedPermissions(permissions: IPermissions[]) {
  return permissions
    .filter((permission) => permission.hasPermission)
    .map((permission) => permission.name);
}

export async function getPermissions(route: Route) {
  const permissions = {
    permissions: routePermissions(route),
  };

  const response = await wrapperRequests(
    "centralizer/api/permissions/authorized",
    "POST",
    {
      data: permissions,
    },
  );

  const authorized = filterAuthorizedPermissions(response.data.authorizations);

  return authorized;
}
