import { format, parseISO } from "date-fns";
import { MdDoDisturb } from "react-icons/md";
import ReactLoading from "react-loading";
import { ProductList } from "../../../../../../@types/Orders";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../../../../../components/TableElements";
import { ActionsButton } from "../../../../../../components/TableElements/styles";
import {
  OrderItemProcessStatusConverter,
  OrderStatus,
} from "../../../../../../enums/Orders";
import { colors } from "../../../../../../styles/themes/default/foundations/colors";
import { OrdersAccordion } from "../OrdersAccordion";
import * as S from "./styles";
import { useContextSelector } from "use-context-selector";
import { OrdersContext } from "../../../../../../contexts/OrdersContext";

export function OrderDetailsError() {
  const { loading, orderDetails, cancelProductListLineItem } =
    useContextSelector(OrdersContext, (context) => {
      return context;
    });

  const {
    createdAt,
    requestedShippingCarrier,
    requestedShippingClass,
    privateNotes,
    publicNotes,
  } = orderDetails;

  return (
    <>
      {orderDetails.processStatus === OrderStatus.ERROR &&
        orderDetails.processMessage && (
          <S.ErrorMessageContainer>
            <S.ErrorMessage>{orderDetails.processMessage}</S.ErrorMessage>
          </S.ErrorMessageContainer>
        )}
      <OrdersAccordion title="Summary">
        <S.GridContainer>
          <S.SumaryContentColumn>
            <div>
              <S.SumaryDataTitle>Line items:</S.SumaryDataTitle>
              <S.SumaryData>{orderDetails?.items?.length}</S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Buyer:</S.SumaryDataTitle>
              <S.SumaryData>
                {orderDetails?.billing?.lastName ??
                  orderDetails?.shipping?.lastName}
                ,{" "}
                {orderDetails?.billing?.firstName ??
                  orderDetails?.shipping?.firstName}
              </S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Created:</S.SumaryDataTitle>
              <S.SumaryData>
                {createdAt && format(parseISO(createdAt), "MM/dd/yyyy hh:mm a")}
              </S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Imported:</S.SumaryDataTitle>
              <S.SumaryData>
                {orderDetails?.importDateUTC &&
                  format(
                    parseISO(orderDetails?.importDateUTC),
                    "MM/dd/yyyy hh:mm a",
                  )}
              </S.SumaryData>
            </div>
          </S.SumaryContentColumn>
          <S.SumaryContentColumn>
            <div>
              <S.SumaryDataTitle>ChannelAdvisor Order ID:</S.SumaryDataTitle>
              <S.SumaryData>{orderDetails?.orderReferenceNumber}</S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Seller Order ID:</S.SumaryDataTitle>
              <S.SumaryData>{orderDetails?.secondaryStoreOrderID}</S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Site Order ID:</S.SumaryDataTitle>
              <S.SumaryData>{orderDetails?.storeOrderID}</S.SumaryData>
            </div>
          </S.SumaryContentColumn>
          <S.SumaryContentColumn>
            <div>
              <S.SumaryDataTitle>Req. Shipping Shopify:</S.SumaryDataTitle>
              <S.SumaryData>{requestedShippingCarrier}</S.SumaryData>
            </div>

            <div>
              <S.SumaryDataTitle>Carrier/Class:</S.SumaryDataTitle>
              <S.SumaryData>{requestedShippingClass}</S.SumaryData>
            </div>

            <div />
          </S.SumaryContentColumn>
        </S.GridContainer>
      </OrdersAccordion>

      <S.Separator />

      <OrdersAccordion title={"Product list"}>
        <S.ShipmentsContainer>
          <S.NumberOfProducts>
            {orderDetails.items.length} products listed
          </S.NumberOfProducts>
          <S.TableContainer>
            <TableComponent>
              <thead>
                <tr>
                  <ThComponent withArrows={false}>SKU</ThComponent>
                  <ThComponent withArrows={false}>Title</ThComponent>
                  <ThComponent withArrows={false}>Quantity</ThComponent>
                  <ThComponent withArrows={false}>Status</ThComponent>
                  <ThComponent withArrows={false}>Cancel</ThComponent>
                </tr>
              </thead>
              {orderDetails?.items?.length &&
                orderDetails?.items?.map((item: ProductList) => {
                  return (
                    <tbody key={item.id}>
                      <TrComponent key={item.id}>
                        <TdComponent tdWidth={"15%"}>
                          {item?.product?.sku || ""}
                        </TdComponent>
                        <TdComponent tdWidth={"auto"}>
                          {item?.title || ""}
                        </TdComponent>
                        <TdComponent tdWidth={"10%"}>
                          {item?.quantity}
                        </TdComponent>
                        <TdComponent tdWidth={"10%"}>
                          <S.StatusContainer variant={item?.processStatus}>
                            {
                              OrderItemProcessStatusConverter[
                                item?.processStatus
                              ]
                            }
                          </S.StatusContainer>
                        </TdComponent>
                        <TdComponent tdWidth={"10%"}>
                          <ConfirmationModal
                            title={
                              "Are you sure about canceling this order line?"
                            }
                            description={
                              "This action is irreversible. It will only cancel the Shipstation Line Item and will not affect anything in Lightspeed."
                            }
                            variant="delete"
                            confirmFunction={() =>
                              cancelProductListLineItem(
                                orderDetails.id,
                                item.id,
                                orderDetails,
                              )
                            }
                          >
                            <ActionsButton
                              title="Cancel item"
                              disabled={
                                item.processStatus === "CANCELLED" ||
                                loading.cancelItem
                              }
                            >
                              {loading.cancelItem && loading.id === item.id ? (
                                <ReactLoading
                                  height={24}
                                  width={24}
                                  type={"spin"}
                                  color={colors["gray-800"]}
                                />
                              ) : (
                                <MdDoDisturb
                                  color={colors["gray-800"]}
                                  size={32}
                                />
                              )}
                            </ActionsButton>
                          </ConfirmationModal>
                        </TdComponent>
                      </TrComponent>
                    </tbody>
                  );
                })}
            </TableComponent>
          </S.TableContainer>
        </S.ShipmentsContainer>
      </OrdersAccordion>

      <S.Separator />

      <OrdersAccordion title="History & Activity">
        <S.ProductListsTitle
          style={{ marginTop: "2rem", marginBottom: "1.5rem" }}
        >
          Notes
        </S.ProductListsTitle>

        <S.NotesContainer>
          <S.SumaryData>Public Notes</S.SumaryData>
          <S.ActivityTextArea
            style={{ maxWidth: "31rem", maxHeight: "5rem" }}
            defaultValue={publicNotes}
            disabled
          />
        </S.NotesContainer>

        <S.NotesContainer>
          <S.SumaryData>Private Notes</S.SumaryData>
          <S.ActivityTextArea
            style={{ maxWidth: "31rem", maxHeight: "6rem" }}
            defaultValue={privateNotes}
            disabled
          />
        </S.NotesContainer>
      </OrdersAccordion>
    </>
  );
}
