import styled from "styled-components";
import { SignInButton } from "../../styles";

export const SuccessMessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  gap: 2rem;

  span {
    font-size: ${(props) => props.theme.fontSize.XS};
    color: ${(props) => props.theme.colors["gray-400"]};
    text-align: center;
  }
`;

export const SuccessMessageButton = styled(SignInButton)`
  background-color: ${(props) => props.theme.colors["green-650"]};
  color: ${(props) => props.theme.colors["black-950"]};

  &:hover {
    opacity: 0.9;
    outline: none;
  }
`;
