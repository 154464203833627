import * as Checkbox from "@radix-ui/react-checkbox";
import styled from "styled-components";

export const CheckboxContainer = styled(Checkbox.Root)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;
  border-radius: 1rem;
  background-color: transparent;
  line-height: 0;
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors["blue-350"]};

  &[data-state='checked'] {
    background-color: ${(props) => props.theme.colors["blue-600"]};
  }
`;

export const CheckboxIndicator = styled(Checkbox.Indicator)`
  color: ${(props) => props.theme.colors.white};
  width: 1rem;
  height: 1rem;
`;
