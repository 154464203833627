import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";
import ReactLoading from "react-loading";
import * as S from "./styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "ghost" | "gray";
  children: ReactNode;
  isLoading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, children, isLoading, ...rest }, ref) => {
    return (
      <S.ButtonContainer
        ref={ref}
        variant={variant}
        disabled={isLoading}
        {...rest}
      >
        {isLoading ? (
          <ReactLoading height={25} width={25} type={"spin"} color="#ffffff" />
        ) : (
          children
        )}
      </S.ButtonContainer>
    );
  },
);
