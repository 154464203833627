import { BiErrorAlt } from "react-icons/bi";
import { MdTransform } from "react-icons/md";
import { CardLinkButton } from "../../components/CardLinkButton";
import { useAuth } from "../../hooks/auth";
import { permissions } from "../../shared/Permissions";

import { AiOutlineFileDone, AiOutlineFileSearch } from "react-icons/ai";
import * as S from "./styles";

export function Report() {
  const { user } = useAuth();

  return (
    <S.ReportContainer>
      {user.user.permissions.includes(
        permissions.report.duplicatedItems.view,
      ) && (
        <CardLinkButton
          href="/report/duplicate-items"
          title="Duplicate Items"
          description="Pending duplicated items in LightSpeed"
        >
          <MdTransform />
        </CardLinkButton>
      )}

      {user.user.permissions.includes(permissions.report.orders.view) && (
        <CardLinkButton
          href="/report/orders"
          title="Orders"
          description="All orders processed"
        >
          <BiErrorAlt />
        </CardLinkButton>
      )}

      {user.user.permissions.includes(permissions.report.explore.view) && (
        <CardLinkButton
          href="/report/explore"
          title="Dynamic Report"
          description="Explore all reports"
        >
          <AiOutlineFileSearch />
        </CardLinkButton>
      )}

      {user.user.permissions.includes(
        permissions.report.exploreAccountingSales.view,
      ) && (
        <CardLinkButton
          href="/report/accountingSalesReport"
          title="Sales Tax Report"
          description="Accounting sales tax reports"
        >
          <AiOutlineFileDone />
        </CardLinkButton>
      )}
    </S.ReportContainer>
  );
}
