import styled, { keyframes } from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";
import Switch from "react-switch";

export const AccordionRoot = styled(Accordion.Root)`
  border-radius: 6px;
  width: 100%;
  position: relative;
`;

export const AccordionItem = styled(Accordion.Item)`
  &:focus-within {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0 2px var(--mauve-12);
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  display: flex;
`;

export const AccordionTrigger = styled(Accordion.Trigger)`
  padding: 1rem;
  border-radius: ${(props) => props.theme.borderRadius["SM"]};
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  background-color: ${(props) => props.theme.colors.white};

  display: flex;
  align-items: center;
  flex: 1;
  gap: 1rem;

  font-weight: ${(props) => props.theme.fontWeight["Bold"]};
  line-height: 1;
  background-color: white;

  &:after {
    content: "";
    margin-left: auto;
    transition: transform 300ms;

    width: 0;
    height: 0;
    margin-top: -2px;
    border-top: 6px solid #aaa;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }

  &[data-state="open"] {
    border-radius: ${(props) => props.theme.borderRadius["SM"]}
      ${(props) => props.theme.borderRadius["SM"]} 0 0;
  }

  &[data-state="open"]:after {
    transform: rotate(180deg);
  }
`;

const boxSlideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const boxSlideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

const childFadeDown = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const AccordionContent = styled(Accordion.Content)`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: 0 0 0.5rem 0.5rem;
  border-top: 0;

  width: 100%;
  padding: 2rem 1rem 0;
  position: absolute;
  z-index: 10;

  overflow: auto;
  max-height: 20rem;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors["gray-600"]};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors["gray-400"]};
    border-radius: 4px;
  }

  & > * {
    opacity: 0;
  }

  &[data-state="open"] {
    animation: ${boxSlideDown} 300ms cubic-bezier(0.19, 0.48, 0.9, 0.61);

    & > * {
      animation: ${childFadeDown} 100ms cubic-bezier(0.19, 0.48, 0.9, 0.61)
        forwards;
      animation-delay: 300ms;
    }
  }

  &[data-state="closed"] {
    animation: ${boxSlideUp} 300ms cubic-bezier(0.19, 0.48, 0.9, 0.61);
  }
`;

export const SwitchStyled = styled(Switch).attrs({
  onColor: "#0063F9",
  offColor: "#DADADA",
  onHandleColor: "#ffffff",
  handleDiameter: 20,
  uncheckedIcon: false,
  checkedIcon: false,
  height: 18,
  width: 32,
})`
  opacity: 1 !important;
  .react-switch-handle {
    border: 1px solid ${(props) => props.theme.colors["gray-600"]} !important;
  }
  .react-switch-handle,
  .react-switch-bg {
    cursor: pointer !important;
  }
`;

export const MassiveActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 1rem;
  padding-bottom: 2rem;
`;

export const MassiveAction = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  width: 100%;
  padding: 0.5rem 0;

  font-weight: ${(props) => props.theme.fontWeight["Semibold"]};

  border: 0;
  border-radius: ${(props) => props.theme.borderRadius["SM"]};
  color: ${(props) => props.theme.colors["gray-800"]};
  background-color: ${(props) => props.theme.colors["gray-200"]};

  input[type="checkbox"] {
    accent-color: ${(props) => props.theme.colors["gray-800"]};
    cursor: pointer;

    &:hover {
      accent-color: ${(props) => props.theme.colors["gray-800"]} !important;
    }
  }
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors["gray-400"]};
`;

export const PermissionsList = styled.ul`
  list-style: none;

  & > li {
    padding: 1.5rem 0;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  & > li > div:first-child {
    font-size: ${(props) => props.theme.fontSize.XS};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    line-height: ${(props) => props.theme.lineHeight.XS};
  }
`;

export const ExpandGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > div:first-child {
    margin-left: 2.125rem;
  }
`;

export const PermissionItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  input,
  label {
    cursor: pointer;
  }

  input:disabled {
    cursor: not-allowed;
  }
`;
