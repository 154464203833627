import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled, { css } from "styled-components";

interface WebStoreListContainerProps {
  storesLoading: boolean;
}

export const WebStoreListContainer = styled.main<WebStoreListContainerProps>`
  flex: 1;

  ${(props) =>
    props.storesLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;

      height: 50vh;
    `};
`;

interface StatusProps {
  variant?: string;
}

export const StatusButton = styled.button<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  font-size: ${(props) => props.theme.fontSize.XXXS};

  height: 2rem;
  width: 7rem;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  gap: 0.5rem;

  border-radius: ${(props) => props.theme.borderRadius.XXLG};
  border: none;

  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors["green-550"]};

  svg {
    color: ${(props) => props.theme.colors.black};
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    outline: 1px solid ${(props) => props.theme.colors["gray-900"]};
    transition: outline 0.2s;

    svg {
      color: ${(props) => props.theme.colors["gray-900"]};
      transition: color 0.2s;
    }
  }

  ${(props) =>
    props.variant === "Inactive" &&
    css`
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors["gray-650"]};

      svg {
        color: ${(props) => props.theme.colors.white};
      }
    `};
`;

export const DropdownButton = styled(DropdownMenu.Item)<StatusProps>`
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.theme.borderRadius.XXLG};
  border: none;

  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors["green-550"]};

  cursor: pointer;

  ${(props) =>
    props.variant === "Inactive" &&
    css`
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors["gray-650"]};
    `};
`;

export const DropdownSeparator = styled(DropdownMenu.Separator)`
  height: 1px;
  background-color: ${(props) => props.theme.colors["gray-400"]};
`;
