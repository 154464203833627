import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  span {
    color: ${(props) => props.theme.newColors["black-900"]};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};
  }
`;

export const InputContent = styled.div`
  height: 2rem;
  width: 100%;

  background-color: ${(props) => props.theme.newColors["white-500"]};
  border: 1px solid ${(props) => props.theme.newColors["gray-500"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};

  input {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: ${(props) => props.theme.borderRadius.SM};
    padding: 0.5rem;

    color: ${(props) => props.theme.newColors["gray-900"]};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};

    &:disabled {
      cursor: not-allowed;
      background-color: ${(props) => props.theme.newColors["gray-300"]};
    }
  }

  span {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    color: ${(props) => props.theme.colors["red-500"]};
  }

  &:hover {
    transition: all 0.2s;
    border: 1px solid ${(props) => props.theme.newColors["gray-600"]};
  }
`;
