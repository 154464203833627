import React from "react";
import * as S from "./styles";
import { ColumnFilters } from "../..";
import { FiSearch } from "react-icons/fi";
import { newColors } from "../../../../../styles/themes/default/New/foundations/colors";

interface FilterInputProps {
  columnFilters: {
    id: string;
    value: string;
  }[];
  columnToFilter: string;
  setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFilters[]>>;
}

export function FilterInput({
  columnFilters,
  columnToFilter,
  setColumnFilters,
}: FilterInputProps) {
  const taskName =
    columnFilters.find((f) => f.id === columnToFilter)?.value || "";

  const onFilterChange = (id: string, value: string) =>
    setColumnFilters((prev) =>
      prev
        .filter((f) => f.id !== id)
        .concat({
          id,
          value,
        }),
    );

  return (
    <S.Container>
      <FiSearch size={24} color={newColors["gray-900"]} />
      <input
        type="text"
        value={taskName}
        placeholder="Search"
        onChange={(e) => onFilterChange(columnToFilter, e.target.value)}
      />
    </S.Container>
  );
}
