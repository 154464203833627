import { useState } from "react";
import { MdContentCopy, MdDone } from "react-icons/md";
import * as S from "./styles";

interface CopyContainerProps {
  stringValue?: string | number;
}

export function CopyContainer({ stringValue }: CopyContainerProps) {
  const [copyToClipboard, setCopyToClipboard] = useState("");

  const handleCopyInfo = (info?: string) => {
    if (info) {
      navigator.clipboard.writeText(String(info));

      setCopyToClipboard(info);

      setTimeout(() => {
        setCopyToClipboard("");
      }, 2000);
    }
  };

  return (
    <>
      {stringValue ? (
        <S.CopyContainer>
          {stringValue && (
            <S.CaOrderIdSpan>{String(stringValue)}</S.CaOrderIdSpan>
          )}
          {copyToClipboard === String(stringValue) ? (
            <div>
              <MdDone title="Copied" size={16} />
            </div>
          ) : (
            <div>
              <MdContentCopy
                title="Copy"
                size={16}
                onClick={() => {
                  handleCopyInfo(String(stringValue));
                }}
              />
            </div>
          )}
        </S.CopyContainer>
      ) : (
        "--"
      )}
    </>
  );
}
