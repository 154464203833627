import * as Dialog from "@radix-ui/react-dialog";

import { CiCircleAlert } from "react-icons/ci";

import * as S from "./styles";
import { colors } from "../../../../../../styles/themes/default/foundations/colors";

interface AlertModalProps {
  message: string;
  opened?: boolean;
  closeModal?: () => void;
}

export function AlertModal({ message, opened, closeModal }: AlertModalProps) {
  return (
    <Dialog.Root open={opened}>
      <Dialog.Portal>
        <S.Overlay />
        <S.Content>
          <S.Wrapper>
            <CiCircleAlert size={80} color={colors["red-450"]} />
            <S.Message>{message}</S.Message>
          </S.Wrapper>

          <Dialog.Close
            asChild
            style={{ border: "none", borderRadius: "0.5rem" }}
          >
            <S.ModalButton onClick={closeModal}>Close</S.ModalButton>
          </Dialog.Close>
        </S.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
