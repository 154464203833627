import * as Dialog from "@radix-ui/react-dialog";
import * as S from "./styles";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "../../Button";
import { ReactComponentElement, ReactNode } from "react";

interface ModalProps {
  title: string;
  children: ReactNode;
  modalContent: ReactComponentElement<"div">;
  customButtons?: boolean;
  onSubmit?: () => void;
}

export function Modal({
  children,
  customButtons = false,
  modalContent,
  onSubmit,
  title,
}: ModalProps) {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>

      <Dialog.Portal>
        <S.Overlay>
          <S.ModalContent custombuttons={customButtons.toString()}>
            <S.TitleContainer>
              <S.ModalTitle>{title}</S.ModalTitle>

              <Dialog.Close asChild>
                <button>
                  <AiOutlineClose size={24} />
                </button>
              </Dialog.Close>
            </S.TitleContainer>

            <S.Content>{modalContent}</S.Content>

            {!customButtons && (
              <S.ButtonContainer>
                <Dialog.Close asChild>
                  <Button variant="ghost">Cancel</Button>
                </Dialog.Close>
                <Button onClick={onSubmit}>Save</Button>
              </S.ButtonContainer>
            )}
          </S.ModalContent>
        </S.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
