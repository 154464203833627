export const lineHeight = {
  Default: "100%",
  XS: "115%",
  SM: "120%",
  MD: "135%",
  LG: "150%",
  XL: "170%",
  XXL: "200%",
  "Default-px": "16px",
  "XS-px": "115%",
  "SM-px": "120%",
  "MD-px": "135%",
  "LG-px": "150%",
  "XL-px": "170%",
  "XXL-px": "200%",
} as const;
