import { DayPickerProps } from "react-day-picker";

import "react-day-picker/dist/style.css";

import * as S from "./styles";

interface Props {
  open?: boolean;
}

export const DatePicker = (props: DayPickerProps & Props) => {
  const { open, ...rest } = props;

  const currentYear = new Date().getFullYear();

  return open ? (
    <S.DayPickerComponent
      captionLayout="dropdown-buttons"
      fromYear={2020}
      toYear={currentYear}
      {...rest}
    />
  ) : null;
};
