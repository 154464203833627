import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const DropdownContent = styled(DropdownMenu.Content)`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow["Level 1"]};
  border-radius: ${(props) => props.theme.borderRadius.SMM};

  padding: 1rem 1.5rem;
  margin-right: 2rem;
`;

export const DropdownItemContainer = styled(DropdownMenu.Item)`
  margin: 0.5rem 0;
`;

export const DropdownLinkButton = styled(NavLink)`
  display: flex;
  align-items: center;

  gap: 0.5rem;
  padding: 0.25rem;

  text-decoration: none;
  color: ${(props) => props.theme.colors["gray-800"]};
  font-size: 1rem;

  svg {
    color: ${(props) => props.theme.colors["gray-800"]};
  }

  &:hover {
    color: ${(props) => props.theme.colors["gray-900"]};
    transition: color 0.2s;

    svg {
      color: ${(props) => props.theme.colors["gray-900"]};
      transition: color 0.2s;
    }
  }
`;

export const LogoutButton = styled.button`
  display: flex;
  align-items: center;

  border: none;
  background-color: transparent;

  gap: 0.5rem;
  padding: 0.25rem;

  color: ${(props) => props.theme.colors["gray-800"]};

  svg {
    color: ${(props) => props.theme.colors["gray-800"]};
  }

  &:hover {
    color: ${(props) => props.theme.colors["gray-900"]};
    transition: color 0.2s;

    svg {
      color: ${(props) => props.theme.colors["gray-900"]};
      transition: color 0.2s;
    }
  }
`;
