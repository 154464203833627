import axios, { AxiosError, AxiosRequestConfig, Method } from "axios";
import { User } from "../@types/Auth";
import { routesURL } from "./routesUrl";
import { decryptToken, encryptToken } from "./tokenConfig.service";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export async function wrapperRequests<T = any>(
  url: string,
  method: Method,
  config?: AxiosRequestConfig,
) {
  try {
    const requestResponse = await api(url, { ...config, method });

    if (!requestResponse) {
      throw Error("undefined request response");
    }

    return requestResponse;
  } catch (error) {
    const axiosError: AxiosError = error as AxiosError;

    const userDataStoraged = localStorage.getItem("@Incycle:user");
    const userDataDecrypted = decryptToken(userDataStoraged || "");
    const userDataParsed = userDataDecrypted
      ? JSON.parse(userDataDecrypted)
      : "";
    const userData: User = userDataParsed ? JSON.parse(userDataParsed) : "";

    if (
      axiosError?.response &&
      axiosError?.response.status === 401 &&
      userData.refreshToken
    ) {
      const lastRequest = axiosError.config;

      try {
        const tokenResponse = await api.post(routesURL.auth.refreshToken, {
          refreshToken: userData.refreshToken,
          consumerUsername: "centralizer_website",
        });

        const userWithNewToken: User = {
          ...userData,
          accessToken: tokenResponse.data.accessToken,
          refreshToken: tokenResponse.data.refreshToken,
        };

        const encryptedUserData = encryptToken(
          JSON.stringify(userWithNewToken),
        );
        localStorage.setItem("@Incycle:user", encryptedUserData);

        api.defaults.headers.common[
          "Authorization"
        ] = `${tokenResponse.data?.accessToken}`;

        const retryOriginalRequest =
          lastRequest &&
          (await api({
            ...lastRequest,
            headers: {
              ...lastRequest.headers,
              Authorization: `${tokenResponse.data?.accessToken}`,
            },
          }));

        if (!retryOriginalRequest) {
          throw Error("undefined request response");
        }

        return retryOriginalRequest;
      } catch (error) {
        localStorage.removeItem("@Incycle:user");
        localStorage.setItem("@Incycle:isLocked", JSON.stringify(true));
        window.location.href = "/pinSignIn";
        throw error;
      }
    }

    if (axiosError?.response && axiosError?.response.status === 401) {
      localStorage.removeItem("@Incycle:user");
      localStorage.setItem("@Incycle:isLocked", JSON.stringify(true));
      window.location.href = "/pinSignIn";
      throw error;
    }

    throw error;
  }
}
