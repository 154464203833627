import styled from "styled-components";

export const ExploreTableContainer = styled.div`
  table {
    padding-right: 1rem;
    th {
      text-transform: capitalize;
    }
  }
`;

export const TableTitleContainer = styled.div`
  display: flex;
  align-items: center;
  border: none;

  width: 100%;
  height: 3rem;

  padding: 0.75rem 1rem;

  span {
    font-size: ${(props) => props.theme.fontSize.SM};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["black-925"]};

    margin-left: 0.25rem;
    margin-right: 2rem;
  }

  div {
    span {
      font-size: ${(props) => props.theme.fontSize.XXS};
      margin-right: 0.5rem;
    }

    input {
      font-size: ${(props) => props.theme.fontSize.Nano};

      border: 1px solid ${(props) => props.theme.colors["gray-400"]};
      border-radius: 4px;

      padding: 6px;
      width: 3rem;
    }
  }
`;

export const TableContainer = styled.div`
  width: 75vw;
  height: 70vh;
  overflow: scroll;
`;
