import { useState } from "react";
import { BsShieldLockFill } from "react-icons/bs";
import ReactLoading from "react-loading";
import { ForgotPasswordUserDataProps } from "..";
import { SignInValidationMessage } from "../../components/SignInValidationMessage";
import { Input, InputContainer, SignInButton, Title } from "../../styles";

import { wrapperRequests } from "../../../../services/api";

import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";

export function CheckCode({
  userData,
  setUserData,
}: ForgotPasswordUserDataProps) {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleCheckCode() {
    setIsLoading(true);
    setError("");

    try {
      await wrapperRequests(
        routesURL.auth.passwordRecoverValidateCode,
        "POST",
        {
          data: {
            email: userData?.email,
            recoverycode: code,
          },
        },
      );

      setUserData((currentValue) => {
        return {
          ...currentValue,
          code: code,
        };
      });
    } catch (error: any) {
      setIsLoading(false);
      setError("Enclosed code is wrong");
      throw Error(error);
    }
  }

  return (
    <S.CheckCodeContainer>
      <Title>Enter the code sent by email</Title>
      <InputContainer className='container' filled={!!code}>
        <BsShieldLockFill />
        <Input
          type='text'
          value={code}
          onChange={(value) => {
            setCode(value.target.value);
          }}
        />
      </InputContainer>

      <SignInButton
        type='submit'
        disabled={isLoading}
        onClick={handleCheckCode}
        onSubmit={handleCheckCode}
      >
        {isLoading ? (
          <ReactLoading height={25} width={25} type={"spin"} color='#ffffff' />
        ) : (
          "Confirm code"
        )}
      </SignInButton>

      {!error && (
        <SignInValidationMessage
          type='success'
          message={"Email with instructions sent"}
        />
      )}

      {error && <SignInValidationMessage message={error} />}
    </S.CheckCodeContainer>
  );
}
