import { useNavigate } from "react-router-dom";
import CheckForgotPassword from "../../../../assets/images/forgot-password-check.png";

import * as S from "./styles";

export function SuccessMessage() {
  const navigate = useNavigate();

  return (
    <S.SuccessMessageContainer>
      <img
        src={CheckForgotPassword}
        alt='check-confirmation'
        width='120px'
        height='120px'
      />

      <span>
        Your password has been successfully changed, you can login now.
      </span>

      <S.SuccessMessageButton onClick={() => navigate("/signin")} type='submit'>
        Login
      </S.SuccessMessageButton>
    </S.SuccessMessageContainer>
  );
}
