import { z } from "zod";

interface UserSchemaProps {
  userDefaultStore: string;
  userStoresGranted: string[];
  userUsePassword: boolean;
}

export function createUserSchema({
  userDefaultStore,
  userStoresGranted,
  userUsePassword,
}: UserSchemaProps) {
  const createSchema = z
    .object({
      name: z
        .string({ required_error: "Name is required" })
        .min(4, { message: "Name must be 4 digits or more" }),
      email: z
        .string({ required_error: "Email is required" })
        .email({ message: "Invalid email" }),
      pin: z.union([
        z.string().length(4, { message: "PIN must be 4 digits or more" }),
        z.string().min(4, { message: "PIN must be 4 digits or more" }),
      ]),
      usePassword: z.boolean().transform(() => userUsePassword),
      password: z.string().refine(
        (value) => {
          if (userUsePassword) {
            return value.length >= 6;
          }
          return true;
        },
        { message: "Password must be 6 digits or more" }
      ),
      passwordConfirmation: z.string().refine(
        (value) => {
          if (userUsePassword) {
            return value.length >= 6;
          }
          return true;
        },
        {
          message: "Password confirmation must be 6 digits or more",
        }
      ),
      defaultShop: z
        .string()
        .transform(() => userDefaultStore)
        .refine(() => userDefaultStore !== "", {
          message: "Default store is required",
        }),
      shopsGranted: z
        .array(z.string())
        .transform(() => {
          return [...userStoresGranted];
        })
        .refine(
          () => {
            return userStoresGranted.length > 0;
          },
          {
            message: "User needs access to at least one store",
          }
        ),
    })
    .refine(
      ({ password, passwordConfirmation }) => {
        return !userUsePassword || passwordConfirmation === password;
      },
      { message: "The passwords did not match", path: ["passwordConfirmation"] }
    );
  return createSchema;
}

export function editUserSchema({
  userDefaultStore,
  userStoresGranted,
  userUsePassword,
}: UserSchemaProps) {
  const editSchema = z
    .object({
      name: z
        .string({ required_error: "Name is required" })
        .min(4, { message: "Name must be 4 digits or more" }),
      email: z
        .string({ required_error: "Email is required" })
        .email({ message: "Invalid email" }),
      pin: z
        .union([
          z.string().length(0, { message: "PIN must be 4 digits or more" }),
          z.string().min(4, { message: "PIN must be 4 digits or more" }),
        ])
        .optional(),
      usePassword: z.boolean().transform(() => userUsePassword),
      password: z.string().refine(
        (value) => {
          if (userUsePassword) {
            const isChangePassword = value.length > 0 ? value.length >= 6 : true;
            return isChangePassword;
          }
          return true;
        },
        { message: "Password must be 6 digits or more" }
      ),
      passwordConfirmation: z.string().refine(
        (value) => {
          if (userUsePassword) {
            const isChangePassword = value.length > 0 ? value.length >= 6 : true;
            return isChangePassword;
          }
          return true;
        },
        {
          message: "Password confirmation must be 6 digits or more",
        }
      ),
      defaultShop: z
        .string()
        .transform(() => userDefaultStore)
        .refine(() => userDefaultStore !== "", {
          message: "Default store is required",
        }),
      shopsGranted: z
        .array(z.string())
        .transform(() => {
          return [...userStoresGranted];
        })
        .refine(
          () => {
            return userStoresGranted.length > 0;
          },
          {
            message: "User needs access to at least one store",
          }
        ),
    })
    .refine(
      ({ password, passwordConfirmation }) => {
        return !userUsePassword || passwordConfirmation === password;
      },
      { message: "The passwords did not match", path: ["passwordConfirmation"] }
    );
  return editSchema;
}
