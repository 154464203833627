import { BsCheckLg } from "react-icons/bs";
import { CheckboxContainer, CheckboxIndicator } from "./styles";

export function Checkbox({ ...rest }) {
  return (
    <CheckboxContainer {...rest}>
      <CheckboxIndicator asChild>
        <BsCheckLg />
      </CheckboxIndicator>
    </CheckboxContainer>
  );
}
