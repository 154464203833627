import styled from "styled-components";

export const Container = styled.main`
  flex: 1;
`;

export const Content = styled.div`
  padding: 3rem;
`;

export const ProfilePicture = styled.section`
  max-width: 45.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
  margin-bottom: 3rem;

  & > h2 {
    font-size: ${(props) => props.theme.fontSize.MD};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["black-925"]};
    line-height: ${(props) => props.theme.lineHeight.Default};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  margin: 3rem 0;
`;

export const UserImage = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors["black"]};

  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    object-fit: cover;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > button {
    & > div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

export const PersonalInformation = styled.section`
  max-width: 45.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
  margin-bottom: 3rem;

  & > h2 {
    font-size: ${(props) => props.theme.fontSize.MD};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["black-925"]};
    line-height: ${(props) => props.theme.lineHeight.Default};
  }
`;

export const UserDataContainer = styled.div`
  margin: 3rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1.5rem;
`;

export const LoginSecurity = styled.section`
  max-width: 45.5rem;

  & > h2 {
    font-size: ${(props) => props.theme.fontSize.MD};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["black-925"]};
    line-height: ${(props) => props.theme.lineHeight.Default};
  }
`;

export const UpdatePasswordContainer = styled.div``;

export const WrapperUpdateAt = styled.div`
  margin-top: 3rem;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > .wrapper_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > h3 {
      font-size: ${(props) => props.theme.fontSize.XS};
      font-weight: ${(props) => props.theme.fontWeight.Regular};
      color: ${(props) => props.theme.colors["black-925"]};
      line-height: ${(props) => props.theme.lineHeight.MD};
    }

    & > p {
      font-size: ${(props) => props.theme.fontSize.XXS};
      font-weight: ${(props) => props.theme.fontWeight.Regular};
      color: ${(props) => props.theme.colors["gray-800"]};
      line-height: ${(props) => props.theme.lineHeight.Default};
    }
  }

  & > button {
    background-color: transparent;
    border: transparent;
    font-size: ${(props) => props.theme.fontSize.XS};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors["blue-600"]};
    line-height: ${(props) => props.theme.lineHeight.MD};
    text-decoration: underline;
  }
`;

export const WrapperChangePassword = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  margin-top: 3rem;

  h3 {
    font-size: ${(props) => props.theme.fontSize.XS};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
    color: ${(props) => props.theme.colors["black-925"]};
    line-height: ${(props) => props.theme.lineHeight.MD};
  }

  & > .form_update_password {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    & > h3 {
      margin-bottom: 1rem;
    }

    & > button {
      margin-top: 1rem;
    }
  }

  & > .form_update_pin {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;

    & > :last-child {
      display: grid;
      grid-template-rows: 3.5fr 1fr;
      gap: 1.5rem;
    }
  }
`;
