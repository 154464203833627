import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const hasUnsavedChanges = (
  original: Record<string, any>,
  modified: Record<string, any>,
): boolean => {
  for (const key in original) {
    if (original.hasOwnProperty(key)) {
      if (typeof original[key] === "object" && original[key] !== null) {
        if (hasUnsavedChanges(original[key], modified[key])) {
          return true;
        }
      } else {
        if (modified[key] !== undefined && original[key] !== modified[key]) {
          return true;
        }
      }
    }
  }
  for (const key in modified) {
    if (modified.hasOwnProperty(key)) {
      if (original[key] === undefined) {
        continue;
      }
    }
  }
  return false;
};

export const useLeavePageConfirm = (hasUnsavedChanges: boolean) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    const handleNavigation = (event: PopStateEvent) => {
      if (hasUnsavedChanges) {
        const confirmLeave = window.confirm(
          "You have unsaved changes. Are you sure you want to leave?",
        );
        if (!confirmLeave) {
          event.preventDefault();
          window.history.pushState(null, "", location.pathname);
        }
      }
    };

    const handleLinkNavigation = (event: MouseEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        const confirmLeave = window.confirm(
          "You have unsaved changes. Are you sure you want to leave?",
        );
        if (confirmLeave) {
          const targetUrl = (event.target as HTMLAnchorElement).pathname;
          navigate(targetUrl, { replace: true });
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handleNavigation);

    const links = document.querySelectorAll("a");
    links.forEach((link) => {
      link.addEventListener("click", handleLinkNavigation);
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleNavigation);
      links.forEach((link) => {
        link.removeEventListener("click", handleLinkNavigation);
      });
    };
  }, [hasUnsavedChanges, location.pathname, navigate]);
};
