import styled, { css } from "styled-components";

interface ButtonContainerProps {
  variant?: string;
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 3rem;
  min-width: 10.3rem;

  background-color: ${(props) => props.theme.colors["blue-600"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};
  border: none;

  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors.white};

  &:hover:not([disabled]) {
    background-color: ${(props) => props.theme.colors["blue-650"]};
    outline: 2px solid ${(props) => props.theme.colors["blue-500"]};
    transition: all 0.2s;
  }

  ${({ variant }) => {
    if (variant === "ghost") {
      return css`
        background-color: transparent;
        color: ${(props) => props.theme.colors["gray-950"]};
        border: 1px solid ${(props) => props.theme.colors["gray-600"]};

        &:hover:not([disabled]) {
          outline: 1px solid ${(props) => props.theme.colors["gray-700"]};
          transition: all 0.1s;
          background-color: transparent;
        }
      `;
    }
    if (variant === "gray") {
      return css`
        background-color: ${(props) => props.theme.colors["gray-400"]};
        color: ${(props) => props.theme.colors["gray-950"]};
        border: transparent;

        &:hover:not([disabled]) {
          background-color: ${(props) => props.theme.colors["gray-700"]};
          outline: none;
          transition: all 0.1s;
        }
      `;
    }
  }}
`;
