import { AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai";

import * as S from "./styles";

interface SignInValidationMessageProps {
  message: string;
  type?: "success" | "error";
}

export function SignInValidationMessage({
  message,
  type = "error",
}: SignInValidationMessageProps) {
  return (
    <S.ForgotPasswordConfirmation type={type}>
      {type === "success" ? (
        <AiFillCheckCircle size={24} color='#ffffff' />
      ) : (
        <AiFillExclamationCircle size={24} color='#ffffff' />
      )}
      {message}
      {/* Email not found in our database */}
      {/* Email with instructions sent */}
    </S.ForgotPasswordConfirmation>
  );
}
