import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StoreListTypes } from "../../../../../../@types/Stores";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../../../../../components/TableElements";

import * as S from "./styles";

interface WebStoreListProps {
  storesData: StoreListTypes[];
  storesLoading: boolean;
  handleSetSort?: (sortValue: string) => void;
  selectSortBy: (value: string) => void;
}

// interface ModalConfirmStatusChangeProps {
//   shopSelected: StoreListTypes;
//   isOpen: boolean;
//   status: boolean;
// }

export function WebStoreList({
  storesLoading,
  storesData,
  handleSetSort,
  selectSortBy,
}: WebStoreListProps) {
  const navigate = useNavigate();

  return (
    <S.WebStoreListContainer storesLoading={storesLoading}>
      {storesLoading ? (
        <ReactLoading height={35} width={35} type={"spin"} color="#000" />
      ) : (
        <TableComponent>
          <thead>
            <tr>
              <ThComponent
                arrowUpFunction={() => selectSortBy("code_desc")}
                arrowDownFunction={() => selectSortBy("code_asc")}
              >
                Client ID
              </ThComponent>
              <ThComponent>Region</ThComponent>
              <ThComponent
                arrowUpFunction={() => selectSortBy("name_desc")}
                arrowDownFunction={() => selectSortBy("name_asc")}
              >
                Store name
              </ThComponent>
              <ThComponent withArrows={false}>Actions</ThComponent>
            </tr>
          </thead>

          <tbody>
            {storesData &&
              storesData.map((store) => (
                <TrComponent key={store.id}>
                  <TdComponent tdWidth={"20%"}>{store.code}</TdComponent>
                  <TdComponent tdWidth={"20%"}>{store.tenantName}</TdComponent>
                  <TdComponent tdWidth={"60%"}>{store.name}</TdComponent>
                  <TdComponent
                    withActions
                    editActionTitle="Edit store"
                    editActionFunction={() =>
                      navigate(`/settings/stores/${store.id}`)
                    }
                    deleteActionTitle="Delete store"
                    deleteActionModalTitle={`Do you want to delete the store ${store.name} ?`}
                    deleteActionFunction={() => {}}
                  />
                </TrComponent>
              ))}
          </tbody>
        </TableComponent>
      )}
    </S.WebStoreListContainer>
  );
}
