import { ReactNode, TableHTMLAttributes } from "react";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineDelete,
  MdOutlineModeEditOutline,
} from "react-icons/md";
import { ConfirmationModal } from "../ConfirmationModal";
import * as S from "./styles";

interface TableElementsProps
  extends React.DetailedHTMLProps<
    TableHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children?: ReactNode;
  withArrows?: boolean;
  thWidth?: string;
  thPadding?: string;
  arrowUpFunction?: () => void;
  arrowDownFunction?: () => void;
}

export function TableComponent({ children }: TableElementsProps) {
  return <S.TableStyled>{children}</S.TableStyled>;
}

export function TrComponent({ children }: TableElementsProps) {
  return <S.TrStyled>{children}</S.TrStyled>;
}

export function ThComponent({
  children,
  withArrows = true,
  thWidth,
  arrowUpFunction,
  arrowDownFunction,
}: TableElementsProps) {
  return (
    <S.ThStyled thWidth={thWidth}>
      <div className="th_align">
        {children}
        {withArrows && (
          <div>
            <MdKeyboardArrowUp
              size={18}
              onClick={(e) => {
                e.preventDefault();
                arrowUpFunction && arrowUpFunction();
              }}
            />
            <MdKeyboardArrowDown
              size={18}
              onClick={(e) => {
                e.preventDefault();
                arrowDownFunction && arrowDownFunction();
              }}
            />
          </div>
        )}
      </div>
    </S.ThStyled>
  );
}

interface TdComponentProps extends TableElementsProps {
  children?: ReactNode;
  tdWidth?: string;
  tdPadding?: string;
  withActions?: boolean;
  editActionTitle?: string;
  editActionFunction?: () => void;
  deleteActionTitle?: string;
  deleteActionModalTitle?: string;
  deleteActionFunction?: () => void;
  singleButton?: boolean;
}

export function TdComponent({
  children,
  tdWidth,
  tdPadding,
  withActions,
  editActionTitle,
  editActionFunction,
  deleteActionTitle,
  deleteActionModalTitle,
  deleteActionFunction,
  singleButton,
}: TdComponentProps) {
  return (
    <S.TdStyled
      tdWidth={tdWidth}
      tdPadding={tdPadding}
      singleButton={singleButton}
    >
      {withActions ? (
        <>
          <S.ActionsButton title={editActionTitle} onClick={editActionFunction}>
            <MdOutlineModeEditOutline size={24} />
          </S.ActionsButton>
          <ConfirmationModal
            title={deleteActionModalTitle ? deleteActionModalTitle : ""}
            variant="delete"
            confirmFunction={
              deleteActionFunction ? deleteActionFunction : () => {}
            }
          >
            <S.ActionsButton
              className="delete_button"
              title={deleteActionTitle}
            >
              <MdOutlineDelete size={24} />
            </S.ActionsButton>
          </ConfirmationModal>
        </>
      ) : (
        children
      )}
    </S.TdStyled>
  );
}
