import CryptoJS from "crypto-js";

export function encryptToken(token: string) {
  const encJson = CryptoJS.AES.encrypt(
    JSON.stringify(token),
    process.env.REACT_APP_CRYPTO_AES_USER_TOKEN_SECRET || ""
  ).toString();
  const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
}

export function decryptToken(token: string) {
  const decData = CryptoJS.enc.Base64.parse(token).toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(
    decData,
    process.env.REACT_APP_CRYPTO_AES_USER_TOKEN_SECRET || ""
  ).toString(CryptoJS.enc.Utf8);

  return bytes;
}
