import styled, { css } from "styled-components";

interface PageHeaderProps {
  hasBorder?: boolean;
}

export const PageHeader = styled.header<PageHeaderProps>`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
  padding-bottom: 1rem;

  h2 {
    margin: 1.5rem 0 1rem;

    font-size: ${(props) => props.theme.fontSize.MD};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.black};
  }

  ${(props) =>
    !props.hasBorder &&
    css`
      border: none;
    `};
`;

export const AlignTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 3rem;

  span {
    max-width: 37.5rem;

    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors["gray-800"]};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  button.default-button {
    display: flex;
    align-items: center;
    padding: 0.75rem;

    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
    background-color: ${(props) => props.theme.colors.white};

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: ${(props) => props.theme.colors["gray-800"]};
    }

    &:hover {
      border: 1px solid ${(props) => props.theme.colors["gray-900"]};
      transition: border 0.2s;

      svg {
        color: ${(props) => props.theme.colors["gray-900"]};
        transition: color 0.2s;
      }
    }
  }
`;
