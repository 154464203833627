import { FiSkipBack, FiSkipForward } from "react-icons/fi";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

import { SelectInput } from "../SelectInput";

import * as S from "./styles";

interface PaginationProps {
  placeholder?: string;
  selectPage: (value: number) => void;
  selectRowsPerPage: (value: number) => void;
  page: number;
  totalPages: number;
}

export function Pagination({
  placeholder,
  selectPage,
  selectRowsPerPage,
  page,
  totalPages,
}: PaginationProps) {
  const pageLimitToChoose = [
    { id: "1", value: "5", label: "5" },
    { id: "2", value: "10", label: "10" },
    { id: "3", value: "20", label: "20" },
    { id: "4", value: "50", label: "50" },
    { id: "5", value: "100", label: "100" },
  ];

  const handleSetRowsPerPage = (value: number) => {
    selectRowsPerPage(value);
  };

  const handleFirstPage = () => {
    selectPage(1);
  };

  const handlePreviousPage = () => {
    const nextPage = page - 1;
    selectPage(nextPage);
  };

  const handleNextPage = () => {
    const nextPage = page + 1;
    selectPage(nextPage);
  };

  const handleLastPage = () => {
    selectPage(totalPages);
  };

  return (
    <S.Pagination>
      <S.RowsSelectContainer>
        <span>Rows per page</span>
        <SelectInput
          variant="pagination"
          placeholder={placeholder}
          options={pageLimitToChoose}
          onChange={(e) => {
            handleSetRowsPerPage(parseInt(e.target.value));
          }}
        />
      </S.RowsSelectContainer>
      <S.PaginationContent>
        <div>
          {/* <span>1-5 of 50</span> */}
          <span>{`${page} of ${totalPages}`}</span>
        </div>
        <button disabled={page <= 1} onClick={handleFirstPage}>
          <FiSkipBack size={24} />
        </button>

        <button disabled={page <= 1} onClick={handlePreviousPage}>
          <IoChevronBack size={24} />
        </button>

        <button disabled={totalPages === page} onClick={handleNextPage}>
          <IoChevronForward size={24} />
        </button>

        <button disabled={totalPages === page} onClick={handleLastPage}>
          <FiSkipForward size={24} />
        </button>
      </S.PaginationContent>
    </S.Pagination>
  );
}
