import styled from "styled-components";

export const OrdersDetailsContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 3rem;
`;

export const LoadingContainer = styled.div`
  height: calc(100vh - 21rem);

  display: flex;
  align-items: center;
  justify-content: center;
`;
