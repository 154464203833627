import styled from "styled-components";

export const ActionsButtonContainer = styled.button`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: 8px;

  padding: 0.5rem;

  & + button {
    margin-left: 8px;
  }

  svg {
    color: ${(props) => props.theme.colors["gray-800"]};
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors["gray-900"]};
    transition: border 0.2s;

    svg {
      color: ${(props) => props.theme.colors["gray-900"]};
      transition: color 0.2s;
    }
  }
`;
