import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useContextSelector } from "use-context-selector";
import { CreateUserData } from "../../../@types/User";
import { UsersPermissionsContext } from "../../../contexts/UsersPermissions";
import { wrapperRequests } from "../../../services/api";
import { routesURL } from "../../../services/routesUrl";

interface UpdateUserProps {
  data: CreateUserData;
  isEditUser: string;
}

export function useRegisterUsers() {
  const { updateUserPermissions, updateUserRoles } = useContextSelector(
    UsersPermissionsContext,
    (context) => {
      return context;
    },
  );

  const [registerUserLoading, setRegisterUserLoading] = useState(false);
  const [registerUserError, setRegisterUserError] = useState("");

  const navigate = useNavigate();

  async function createUser(data: CreateUserData) {
    setRegisterUserLoading(true);
    setRegisterUserError("");
    try {
      const response = await wrapperRequests(
        routesURL.settings.users.createUser,
        "POST",
        { data: data },
      );

      toast.success("🚴‍♂️ User created successfully! Add the permissions.", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate(
        response?.data?.id
          ? `/settings/users/${response.data.id}`
          : "/settings/users",
      );
    } catch (error: any) {
      setRegisterUserLoading(false);
      setRegisterUserError(error.response?.data?.message);
      throw Error(String(error));
    }
  }

  async function updateUser({ data, isEditUser }: UpdateUserProps) {
    setRegisterUserLoading(true);
    setRegisterUserError("");

    try {
      await wrapperRequests(
        routesURL.settings.users.editUser(isEditUser),
        "PUT",
        { data: data },
      );

      const response = await updateUserPermissions(isEditUser, true);

      if (response != null && response !== 400) {
        await updateUserRoles(isEditUser);

        toast.success("🚴‍♂️ User edited successfully!", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        navigate("/settings/users");
      } else {
        toast.error("Check the user's permissions", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      setRegisterUserError(error.response?.data?.message || "Duplicated PIN");
      throw Error(String(error));
    } finally {
      setRegisterUserLoading(false);
    }
  }

  return {
    createUser,
    registerUserLoading,
    registerUserError,
    updateUser,
  };
}
